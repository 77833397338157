<template>
    <v-container fluid class="pr-0 pl-0">
        <logo @cambiaAnio="listar" v-sticky="{ zIndex: 4, stickyTop: 65}" />
        <navParameters :navParams="navParams"/>
        <v-row>
            <v-col cols="12">
                <tituloPrincipal titulo="Evaluación de la calidad de agua y cumplimiento según su uso"/>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="6" md="3" align-self="center">
                
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="6" md="2">
                <v-text-field
                    prepend-inner-icon="mdi-magnify"
                    label="Buscar"
                    v-model="search"
                ></v-text-field>
            </v-col>
        </v-row>
        <v-card>
            <tituloSimple titulo="Evaluación de la calidad de agua y cumplimiento según su uso"/>
            <v-divider></v-divider>
            <v-card-text>
                <v-data-table
                    :headers="headers"
                    :items="data"
                    item-key="id"
                    :search="search">
                    <template v-slot:item.action="{ item }">
                        <UsoFuenteAgua :idMedidor="item.id" @reload="listar"/>
                        <EvidenciasFuenteAgua 
                            v-if="showFiles(item.uso)"
                            tooltipText="Agregar Evidencias"
                            titulo="Evidencias"
                            request="/indicadorAgua/agregarDocFuenteAgua"
                            :id="item.id"
                        />
                    </template>
                    <template v-slot:item.evidencia="{ item }">
                        <ListadosDocs :id="item.id" origen="fuentesAgua" titulo="Evidencias"></ListadosDocs>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
    </v-container>
</template>
<script>

import EvidenciasFuenteAgua from "@/components/parametros/Agua/Agropecuario/EvidenciasFuenteAgua.vue"
import UsoFuenteAgua from "@/components/parametros/Agua/Agropecuario/UsoFuenteAgua.vue"
import ListadosDocs from "@/components/documentos/ListadoDocumentosComponent"
import TituloPrincipal from '@/components/utils/TituloPrincipalComponent'
import NavParameters from '@/components/utils/NavParameterComponent'
import TituloSimple from '@/components/utils/TituloSimpleComponent'
import Logo from '@/components/utils/CompanyComponent'
import VueSticky from 'vue-sticky'
import axios from "@/axios.js"

export default {
    created() {
        axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;
    },  
    computed: {
        sucursal() {
            return this.$store.getters.sucursal;
        },
        indicadorActual() {
            return this.$store.getters.indicadorActual;
        },
        instalacion() {
            return this.$store.getters.sucursal;
        },
        rolUsuario(){
            return this.$store.getters.usuario.rol;
        },
        anioConsulta() {
            return this. $store.getters.anioConsulta;
        },
        tipoActividad() {
            let constantes = this.$store.getters.constantes;
            return constantes.TIPO_ACTIVIDAD.PROGRAMA_REPRODUCTIVO
        },
        usuario(){
            return this.$store.getters.usuario;
        },
    },
    directives: {
        'sticky': VueSticky,
    },
    props: [
        'navParams'
    ],
    components: {
        UsoFuenteAgua,
        ListadosDocs,
        EvidenciasFuenteAgua,
        TituloSimple,
        NavParameters,
        TituloPrincipal,
        Logo
    },
    data: () => ({
        search: null,
        data: [],
        headers: [
            {
                text: "Acciones",
                value: "action",
                align: "center",
                width: '10%',
            },
            {
                text: "Fuente de Agua",
                value: "medidor",
                align: "start",
                width: '30%',
            },
            {
                text: "Uso",
                value: "uso",
                align: "start",
                width: '50%',
            },
            {
                text: "Evidencia",
                value: "evidencia",
                align: "center",
                width: '10%',
            },
        ]
    }),
    mounted () {
        this.listar();
    },
    methods: {
        listar(){
            axios.get(`/indicadorAgua/getFuentesAgua/${this.sucursal.id}/${this.anioConsulta}`).then(response => {
                this.data = response.data;
                this.paginaCargada(false);
            }).catch(error => {
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                console.log(error);
                this.paginaCargada(false);
              }
            })
        },
        showFiles(uso) {
            return uso != null
        },
        paginaCargada() {
            this.$store.dispatch('loadingPage', false);
        },
    },
}
</script>