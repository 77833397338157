<template>
    <v-dialog
        v-model="dialog"  
        persistent 
        scrollable
        max-width="550px"
        transition="dialog-transition">
        <template v-slot:activator="{ on }">
            <v-tooltip bottom>
                <template v-slot:activator="{ on: tooltip }">
                    <v-icon
                        small
                        exact-active-class="indigo"
                        class="ma-2"
                        v-on="{ ...tooltip, ...on }"
                        @click="openModal()"
                    >mdi-pencil</v-icon>
                </template>
                <span>Modificar</span>
            </v-tooltip>
        </template>
        <v-card :loading="loading">
            <v-card-title class="pa-0">
              <ModalTitle titulo="Agregar" @cancelar="cancelar"/>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="12" class="pt-4 pb-0">
                        <v-textarea
                            rows="3"
                            label="Descripción Análisis"
                            counter="1000"
                            v-model="analisis.descripcion"
                            :error-messages="descErrors"             
                            @change="$v.analisis.descripcion.$touch()"
                            @blur="$v.analisis.descripcion.$touch()"
                            required
                        ></v-textarea>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" :disabled="loading" text @click.native="cancelar">Cancelar</v-btn>
                <v-btn color="#0B3F67" :loading="loading" text @click="modificarAnalisis">Modificar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>

import ModalTitle from '@/components/utils/ModalTitleComponent'
import { required, maxLength, requiredIf } from "vuelidate/lib/validators"
import axios from "@/axios.js";

export default {
    components: {
      ModalTitle
    },
    props: [
        'id'
    ],
    computed: {
        descErrors () {
            const errors = []
            if (!this.$v.analisis.descripcion.$dirty) return errors
            !this.$v.analisis.descripcion.required && errors.push('Campo requerido')
            !this.$v.analisis.descripcion.maxLength && errors.push('No debe exceder el tamaño permitido.')
            return errors
        },
        indicadorActual() {
            return this.$store.getters.indicadorActual;
        },
        sucursal() {
            return this.$store.getters.sucursal;
        },
        usuario(){
            return this.$store.getters.usuario;
        },
        anioConsulta() {
            return this. $store.getters.anioConsulta;
        },
    },
    data: () => ({
        loading: false,
        dialog: false,
        analisis: {
            anio: null,
            descripcion: '',
        }
    }),
    validations: {
        analisis: {
            descripcion: {
                maxLength: maxLength(1000),
                required
            },
        }
    },
    methods: {
        openModal() {
            this.getData()
        },
        getData() {
            axios.get(`/manejoSuelo/getDataCaractQuimicas/${this.id}`).then(r => {
                this.analisis = r.data
            }).catch(error => {
                if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                }      
            })
        },
        modificarAnalisis() {
            this.$v.$touch();
            if(!this.$v.$invalid) {
                this.loadingModal(true);
                var formData = new FormData();
                formData.append("nuevo", this.analisis.descripcion);
                formData.append("anterior", this.id);
                formData.append("idUsuario", this.usuario.id);
                axios.post("/manejoSuelo/modificarCaractQuimica", formData).then(response => {
                    this.loadingModal(false);
                    this.$store.dispatch('noti',{mensaje: 'Analisis de características quimicas modificado', color:'success'});   
                    this.$emit('reload');
                    this.cancelar();
                }).catch(error => {
                  if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                  } else {
                    if (error.request && error.request.status === 415) {
                      this.$store.dispatch('noti', {mensaje: error.response.data, color: 'error'});
                      this.loadingModal(false);
                    } else {
                      this.$store.dispatch('noti', {mensaje: 'Ocurrió un error no se modificó el Analisis de características quimicas', color:'error'});   
                      this.loadingModal(false);  
                    } 
                  }        
                })
            }
        },
        cancelar() {
            this.actividad = {};
            this.$v.$reset();  
            this.dialog = false;
            this.loading = false;
        },
        loadingModal(modal) {                        
            this.$store.dispatch('loadingPage', modal);
        },
    },
}
</script>