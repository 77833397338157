var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('logo',{directives:[{name:"sticky",rawName:"v-sticky",value:({ zIndex: 4, stickyTop: 65}),expression:"{ zIndex: 4, stickyTop: 65}"}],on:{"cambiaAnio":_vm.getEventoClimaticos}}),_c('navParameters',{attrs:{"navParams":_vm.navParams}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('tituloPrincipal',{attrs:{"titulo":_vm.tituloPrincipal}})],1)],1),_c('v-row',[(_vm.cambioClimatico && _vm.anioConsulta > 2023)?_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"12","sm":"12","md":"3"}},[_c('AccionesAdaptacion',{attrs:{"instalacion":_vm.instalacion,"anioConsulta":_vm.anioConsulta,"usuario":_vm.usuario},on:{"reload":_vm.getEventoClimaticos}})],1):_vm._e(),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"3"}},[_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-magnify","label":"Buscar"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('tituloSimple',{attrs:{"titulo":_vm.titulo}}),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"item-key":"id","search":_vm.search,"no-data-text":"No hay Eventos Climáticos agregados","footer-props":{
                            showFirstLastPage: true,
                            firstIcon:'mdi-page-first',
                            lastIcon: 'mdi-page-last',
                            prevIcon: 'mdi-chevron-left',
                            nextIcon: 'mdi-chevron-right',
                            itemsPerPageText: 'Registros por página',
                            itemsPerPageAllText: 'Todos',                            
                            showCurrentPage: true,
                            showFirstLastPage: true
                        }},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
                        var item = ref.item;
return [(_vm.cambioClimatico && _vm.anioConsulta > 2023)?_c('div',[(_vm.rolUsuario != 'CEO' && _vm.rolUsuario != 'GERENTE' && _vm.rolUsuario != 'SUPERVISOR')?_c('AgregarEvidencia',{attrs:{"idActividad":item.id,"origen":"accionAdaptacion","tooltipText":"Agregar Evidencias","titulo":"Acción Adaptación"},on:{"reload":_vm.getEventoClimaticos}}):_vm._e(),(_vm.rolUsuario != 'CEO' && _vm.rolUsuario != 'GERENTE' && _vm.rolUsuario != 'SUPERVISOR')?_c('ModAccionesAdaptacion',{attrs:{"idEvento":item.id,"instalacion":_vm.instalacion,"usuario":_vm.usuario,"anioConsulta":_vm.anioConsulta},on:{"reload":_vm.getEventoClimaticos}}):_vm._e(),(_vm.rolUsuario != 'CEO' && _vm.rolUsuario != 'GERENTE' && _vm.rolUsuario != 'SUPERVISOR')?_c('EliminarAccionAdaptacion',{attrs:{"evento":item,"instalacion":_vm.instalacion,"anioConsulta":_vm.anioConsulta},on:{"reload":_vm.getEventoClimaticos}}):_vm._e()],1):_c('div',[(_vm.rolUsuario != 'CEO' && _vm.rolUsuario != 'GERENTE' && _vm.rolUsuario != 'SUPERVISOR')?_c('AgreModfAccion',{attrs:{"tipoAccion":"Modificar","eventoProp":item,"instalacion":_vm.instalacion,"usuario":_vm.usuario,"cambioClimatico":_vm.cambioClimatico},on:{"reload":_vm.getEventoClimaticos}}):_vm._e(),(_vm.rolUsuario != 'CEO' && _vm.rolUsuario != 'GERENTE' && _vm.rolUsuario != 'SUPERVISOR')?_c('AgregarEvidencia',{attrs:{"idActividad":item.id,"origen":"accionAdaptacion","tooltipText":"Agregar Evidencias","titulo":"Acción Adaptación"},on:{"reload":_vm.getEventoClimaticos}}):_vm._e()],1)]}},{key:"item.evidencia",fn:function(ref){
                        var item = ref.item;
return [_c('ListadosDocs',{attrs:{"id":item.id,"origen":"accionAdaptacion","titulo":"Evidencias Acción Adaptación"}})]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }