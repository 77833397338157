<template>
    <v-container fluid class="pr-0 pl-0">
        <logo @cambiaAnio="getData" v-sticky="{ zIndex: 4, stickyTop: 65}" />
        <navParameters :navParams="navParams"/>
        <v-row>
            <v-col cols="12">
                <TituloPrincipal :titulo="tituloIndicador"/>
            </v-col>
        </v-row>
        <TablaEducacion @reload="getData" ref="educacion" :headers="headersList" :indicadorActual="indicadorActual" :tipoActividad="buenasPractcas"/>
    </v-container>
</template>
<script>

import TablaEducacion from "@/components/parametros/EducacionAmbiental/EducacionAmbTablaComponent"
import TituloPrincipal from '@/components/utils/TituloPrincipalComponent'
import NavParameters from '@/components/utils/NavParameterComponent'
import Logo from '@/components/utils/CompanyComponent'
import VueSticky from 'vue-sticky'

export default {
    components: {
        TablaEducacion,
        TituloPrincipal,
        NavParameters,
        Logo
    },
    mounted(){
       setTimeout(function () {this.loadingModal(false)}.bind(this), 1000);
    },
    directives: {
        'sticky': VueSticky,
    },
    props: [
        'navParams'
    ],
    computed:{
        anioConsulta() {
            return this. $store.getters.anioConsulta;
        },
        indicadorActual() {
            return parseInt(this.$store.getters.indicadorActual);
        },
        tituloIndicador(){
            let constantes = this.$store.getters.constantes;
            switch(this.indicadorActual){
                case constantes.indicadorEnergia:
                    return "Acciones que promueven el ahorro de electricidad";
                case constantes.indicadorAgua:
                    return "Control del consumo de agua";
                case constantes.indicadorCombustibles:
                    return "Acciones que promueven el ahorro de combustible fósiles";
                case constantes.indicadorManejoSuelos:
                    return "Aplicación de Buenas Prácticas de manejo del suelo";
                case constantes.indicadorInsumosAgropecuarios:
                    return "Buenas prácticas para el manejo de insumos agropecuarios";
                case constantes.indicadorAdaptacion:
                    return "Actividades Educación Ambiental de Adaptación";
                case constantes.indicadorCompensacion:
                    return "Actividades Educación Ambiental de Compensación";
                case constantes.indicadorResiduos:
                    return "Plan para el manejo de los residuos sólidos y líquidos";
            }
        },
        buenasPractcas() {
            let constantes = this.$store.getters.constantes;
            return constantes.TIPO_ACTIVIDAD.BUENAS_PRACTICAS
        },
        headersList() {
            let constantes = this.$store.getters.constantes;
            switch (this.indicadorActual) {
                case constantes.indicadorResiduos:
                    return this.headersRes
                default:
                    return this.headers
            }
        },
    },
    data: () => ({
        headers: [
            {
                text: 'Acciones',
                value: 'action',
                align: 'center',
                width: '10%',
                class: "educTable",
            },
            {
                text: "Tipo Practica",
                value: "tipoActividad",
                align: "start",
                width: '20%',
                class: "educTable",
            },
            {
                text: "Descripción",
                value: "fullDescription",
                align: "start",
                width: '50%',
                class: "educTable",
            },
            {
                text: "Fecha de la Actividad",
                value: "fechaActividad",
                align: "center",
                width: '10%',
                class: "educTable",
            },
            {
                text:"Evidencia",
                value: "evidencia",
                align: "center",
                width: '10%',
                class: "educTabla",
            }

        ],
        headersRes: [
            {
                text: 'Acciones',
                value: 'action',
                align: 'center',
                width: '10%',
                class: "educTable",
            },
            {
                text: "Tipo Residuo",
                value: "opcionalTres",
                align: "start",
                width: '10%',
                class: "educTable",
            },
            {
                text: "Acciones a Realizar",
                value: "descripcion",
                align: "start",
                width: '20%',
                class: "educTable",
            },
            {
                text: "Meta",
                value: "temasTratados",
                align: "start",
                width: '20%',
                class: "educTable",
            },
            {
                text: "Periodo de ejecución",
                value: "opcionalUno",
                align: "center",
                width: '15%',
                class: "educTable",
            },
            {
                text: "Responsable",
                value: "opcionalDos",
                align: "center",
                width: '15%',
                class: "educTable",
            },
            {
                text:"Evidencia",
                value: "evidencia",
                align: "center",
                width: '10%',
                class: "educTabla",
            }

        ],
    }),
    methods: {
        getData() {
            this.$refs.educacion.getDataPorAnio();
        },
        loadingModal(modal) {                        
            this.$store.dispatch('loadingPage', modal);
        },
    }
}
</script>