<template>
  <v-container fluid>
    <v-row>
      <v-col cols="6" sm="6" md="3" align-self="center" >
        <agregarActividad v-if="showEducacion && (rolUsuario != 'CEO' && rolUsuario != 'GERENTE' && rolUsuario != 'SUPERVISOR')" @reload="getDataPorAnio" />
        <AgregarBuenasPrc v-if="!showEducacion" @reload="getDataPorAnio" :tipoActividad="tipoActividad" />
      </v-col>
      <v-col cols="6" sm="6" md="2" offset-md="7">
        <v-text-field
          prepend-inner-icon="mdi-magnify"
          label="Buscar"
          v-model="search"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
        <v-col cols="12">
        <v-card>
            <v-card-text>
                <v-data-table
                    :headers="headers"
                    :items="actividades"
                    calculate-widths
                    item-key="id"
                    :loading="loading"
                    :search="search"
                    no-data-text="No hay Actividades para este periodo"
                    :footer-props="{
                            showFirstLastPage: true,
                            firstIcon:'mdi-page-first',
                            lastIcon: 'mdi-page-last',
                            prevIcon: 'mdi-chevron-left',
                            nextIcon: 'mdi-chevron-right',
                            itemsPerPageText: 'Registros por página',
                            itemsPerPageAllText: 'Todos',
                            showCurrentPage: true,
                            showFirstLastPage: true
                        }"
                >
                    <template v-slot:item.fechaActividad="{ item }">{{ dateFormat(item.fechaActividad) }}</template>
                    <template v-slot:item.action="{ item }">
                    <ModificarEducAmbiental v-if="showEducacion && rolUsuario != 'CEO' && rolUsuario != 'GERENTE' && rolUsuario != 'SUPERVISOR'" @reload="getDataPorAnio" v-bind:id="item.id" />
                    <Modificar v-if="!showEducacion && rolUsuario != 'CEO' && rolUsuario != 'GERENTE' && rolUsuario != 'SUPERVISOR'" @reload="getDataPorAnio" v-bind:id="item.id" />
                    <AgregarEvidencia
                        v-if="rolUsuario != 'CEO' && rolUsuario != 'GERENTE' && rolUsuario != 'SUPERVISOR'"
                        @reload="getDataPorAnio"
                        :idActividad="item.id"
                        :origen="eudcOrigen"
                        tooltipText="Agregar Evidencias"
                        :titulo="titulo"
                    ></AgregarEvidencia>
                    <Eliminar 
                      v-bind:requestData="requestDataFormat(item)" 
                      requestUrl="/educacionAmbiental/borrarActividad"
                      @reload="reloadData"
                    /> 
                    </template>
                    <template v-slot:item.evidencia="{ item }">
                    <ListadosDocs :idActividad="item.id" origen="educacionAmbiental" :titulo="titulo"></ListadosDocs>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
      </v-col>
    </v-row>
   </v-container>
</template>
<script>

import ModificarEducAmbiental from "@/components/parametros/EducacionAmbiental/ModificarEducAmbientalComponent";
import AgregarActividad from "@/components/parametros/EducacionAmbiental/AgregarEducAmbientalComponent";
import AgregarBuenasPrc from '@/components/parametros/buenasPracticas/Agregar';
import Modificar from '@/components/parametros/buenasPracticas/Modificar.vue';
import ListadosDocs from "@/components/documentos/ListadoDocumentosComponent";
import AgregarEvidencia from "@/components/utils/AgregarEvidenciaComponent";
import Eliminar from '@/components/utils/EliminarComponent';
import moment from "moment";
import axios from "@/axios.js";

export default {
  components: {
    Modificar,
    ModificarEducAmbiental,
    AgregarActividad,
    ListadosDocs,
    AgregarEvidencia,
    Eliminar,
    AgregarBuenasPrc
  },
  created() {
      axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;
      axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
  },  
  mounted() {
    this.getDataPorAnio();
  },
  props: ["headers", 'tipoActividad'],
  computed: {
    anioConsulta() {
      return this. $store.getters.anioConsulta;
    },
    instalacion() {
      return this.$store.getters.sucursal;
    },
    rolUsuario(){
      return this.$store.getters.usuario.rol;
    },
    indicadorActual() {
      return this.$store.getters.indicadorActual;
    },
    idUsuario(){
      return this.$store.getters.usuario.id;
    },
    parametroActual() {
      return this.$store.getters.parametroActual;
    },
    showEducacion() {
      return this.instalacion.tipoCategoria === 1
    },
    eudcOrigen() {
      return this.showEducacion ? 'educacionAmbiental' : 'buenasPracticas'
    }
  },
  data: () => ({
    loading: false,
    todasLasActividades: [],
    actividadesPorAnio: [],
    actividades: [],
    items: [],
    anio: "",
    origen: "educacionAmbiental",
    titulo: "Evidencia de Documento",
    search: null
  }),
  methods: {
    dateFormat(fecha) {
      return fecha ? moment(fecha).format("DD/MM/YYYY") : "";
    },
    getDataPorAnio() {
      this.cargando = true;
      axios
        .get(`/educacionAmbiental/listarEducacion/${this.instalacion.id}`, {
          params: {
            indicador: this.indicadorActual,
            periodo: this.anioConsulta,
            tipoActividad: this.tipoActividad
          }
        })
        .then(response => {
          this.actividades = response.data.charlas;
          this.cargando = false;
          this.loadingModal(false);
        })
        .catch(error => {
          console.log(error);
          this.cargando = false;
          this.loadingModal(false);
        });
    },
    borrarActividad(id) {
        this.$store.dispatch('confirma', {mensaje: 'Desea eliminar la educación ambiental?', show: true})
        .then((confirm) => {
            if (confirm) {
                this.$store.dispatch('loadingPage', true);
                this.cargando = true;
                axios.delete(`/educacionAmbiental/borrarActividad/${id}/${this.idUsuario}`,
                    { params: { idParametro: this.parametroActual } })
                .then(response => {
                    this.$store.dispatch('loadingPage', false);
                    this.getDataPorAnio();
                    this.$store.dispatch("noti", {
                        mensaje: "Actividad de educación eliminada",
                        color: "success"
                    });
                    this.cargando = false;
                }).catch(error => {
                  if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                  } else {
                    this.$store.dispatch('loadingPage', false);
                    this.$store.dispatch("noti", {
                        mensaje: "Actividad no se pudo borrar",
                        color: "success"
                    });
                    console.log(error);
                    this.cargando = false;
                  }
                });
            }
        });
    },
    loadingModal(modal) {                        
      this.$store.dispatch('loadingPage', modal);
    },
    
    reloadData() {
      this.$emit('reload')
    },

    requestDataFormat(data) {
      let datos = new FormData()
      datos.append('idActividad', data.id);
      datos.append('idUsuario', this.idUsuario);
      datos.append('parametroActual', this.parametroActual);
      return datos;
    }
  }
};
</script>