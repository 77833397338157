var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pr-0 pl-0",attrs:{"fluid":""}},[_c('logo',{directives:[{name:"sticky",rawName:"v-sticky",value:({ zIndex: 4, stickyTop: 65}),expression:"{ zIndex: 4, stickyTop: 65}"}],on:{"cambiaAnio":_vm.listarAccionReduccion}}),_c('navParameters',{attrs:{"navParams":_vm.navParams}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('tituloPrincipal',{attrs:{"titulo":" Programa de manejo sanitario, alimenticio y reproductivo de los animales"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6","md":"3","align-self":"center"}},[_c('Agregar',{on:{"reload":_vm.listarAccionReduccion}})],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"6","md":"2"}},[_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-magnify","label":"Buscar"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-card',[_c('tituloSimple',{attrs:{"titulo":" Programa de manejo sanitario, alimenticio y reproductivo de los animales"}}),_c('v-divider'),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.data,"item-key":"id","search":_vm.search},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [(_vm.rolUsuario != 'CEO' && _vm.rolUsuario != 'GERENTE' && _vm.rolUsuario != 'SUPERVISOR')?_c('Modificar',{attrs:{"programa":item.id,"accion1":item},on:{"reload":_vm.listarAccionReduccion}}):_vm._e(),(_vm.rolUsuario != 'CEO' && _vm.rolUsuario != 'GERENTE' && _vm.rolUsuario != 'SUPERVISOR')?_c('AgregarEvidencia',{attrs:{"idActividad":item.id,"origen":"programaManejo","tooltipText":"Agregar Evidencias","titulo":"Evidencias"},on:{"reload":_vm.listarAccionReduccion}}):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([(_vm.rolUsuario != 'CEO' && _vm.rolUsuario != 'GERENTE' && _vm.rolUsuario != 'SUPERVISOR')?{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"#0B3F67"},on:{"click":function($event){return _vm.borrarAccion(item.id)}}},on),[_vm._v("mdi-trash-can-outline")])]}}:null],null,true)},[_vm._v(" Eliminar ")])]}},{key:"item.fechaActividad",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dateFormat(item.fechaActividad))+" ")]}},{key:"item.evidencia",fn:function(ref){
var item = ref.item;
return [_c('ListadosDocs',{attrs:{"idActividad":item.id,"idAccion":item.id,"origen":"accionReduccion","titulo":"Evidencias"}})]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }