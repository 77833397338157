<template>
     <v-card>
        <v-card-text>
          <v-select
                v-if="isAdmin"
                :items="orgs"
                v-model="idOrganizacion"
                item-text="nombre"
                item-value="id"
                label="Seleccione una Organización"
                :error-messages="orgErrors"             
                @change="instalacionesPorUsuario"
                @blur="$v.idOrganizacion.$touch()"
            ></v-select>
            <v-select
                :items="instalaciones"
                v-model="idInstalacion"
                item-text="nombre"
                item-value="id"
                label="Seleccione una Instalación"
                :error-messages="instalErrors"             
                @change="getDataAnios"
                @blur="$v.idInstalacion.$touch()"
            ></v-select>
            <v-select
                :items="anios"
                v-model="anio"
                no-data-text="Seleccione primero la instalación"
                label="Año de Participación"
                :error-messages="anioErrors"             
                @change="$v.anio.$touch()"
                @blur="$v.anio.$touch()"
            ></v-select>            
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
            <v-btn @click="getDataInformeFinal" class="white--text" block color="#0B3F67">Generar Informe</v-btn>
        </v-card-actions>
        <v-alert type="error" :value="alert" clearable>
            {{ mensaje }}
        </v-alert>
    </v-card>
</template>
<script>
//categoria VI Cambio climatico
import { informe2024 } from '@/informes/Categoria_IV/informe-2024'
import { informe2023 } from '@/informes/Categoria_IV/informe-2023'
import { informe2022 } from '@/informes/Categoria_IV/informe-2022'
import { informe2021 } from '@/informes/Categoria_IV/informe-2021'
import { informe2020 } from '@/informes/Categoria_IV/informe-2020'
//Categoria XII Agropecuario
import { informeXII2024 } from '@/informes/Categoria_XII/informe-2024'
import { required, requiredIf } from "vuelidate/lib/validators";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import diccionario from '@/imageDictionary.js';
import axios from '@/axios.js';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default {
    mounted() {        
      if(this.isAdmin) {
        this.getDataAdmin();
      } else {
        this.instalacionesPorUsuario();
      }
    },
    computed: {
        organizacion() {
            return this.$store.getters.organizacion;
        },
        usuario() {
            return this.$store.getters.usuario;
        },
        constantes() {
            return this.$store.getters.constantes;
        },
        anioAnterior() {
            let anterior = this.anio;
            return anterior - 1;
        },
        anioErrors () {
            const errors = []
            if (!this.$v.anio.$dirty) return errors            
            !this.$v.anio.required && errors.push('Dato requerido.')            
            return errors
        },
        instalErrors () {
            const errors = []
            if (!this.$v.idInstalacion.$dirty) return errors            
            !this.$v.idInstalacion.required && errors.push('Dato requerido.')            
            return errors
        },
        orgErrors () {
            const errors = []
            if (!this.$v.idOrganizacion.$dirty) return errors            
            !this.$v.idOrganizacion.required && errors.push('Dato requerido.')            
            return errors
        },
        isAdmin() {
            return this.$store.getters.usuario.rol === 'ADMIN'
        },
    },
    data: () => ({
        categoria: null,
        anio: null,
        alert: false,
        mensaje: null,
        anios: [],
        instalaciones: [],
        instalacion: null,
        idInstalacion: null,
        orgs: [],
        idOrganizacion: null,
        datos: {},
        //definicion del documento PBAE-CCC VI
        docDefinition: {
            footer: function(currentPage, pageCount) { 
                if(currentPage !== 1)
                    return {columns: [
                        { width: '10%', image: diccionario.logoBanderaAzulUno, fit: [50, 50], alignment:'center' },
                        { width: '90%', text: 'Programa Bandera Azul Ecológica', alignment: 'left', margin: 12 }
                    ]}; 
            },   
            pageSize: 'B4',
            content: [],
            styles: {
                table: {
                  margin: [0, 0, 0, 15]
                },
            },
        },
        //definicion del documento PBAE-CA XII
        docAgropDefinition: {
            header: function(currentPage, pageCount) { 
                if(currentPage !== 1)
                  return {
                    table: {
                      widths: ['10%','70%','10%','10%'],
                      body: [
                        [
                          { image: diccionario.PBAE_CCC, fit: [50, 50], alignment:'center', border: [false, false, false, false] },
                          { text: 'Programa Bandera Azul Ecológica\nXII Categoría: Agropecuaria\nInforme Anual', alignment: 'center', margin: [0, 10, 0, 0], border: [false, false, false, true] },
                          { image: diccionario.PBAE_CA, fit: [50, 50], alignment:'center', margin: [0,5,0,0], border: [false, false, false, true] },
                          { image: diccionario.PBAE_CA_2, fit: [50, 50], alignment:'left', border: [false, false, false, false] }
                        ]
                      ]
                  }
                } 
            },   
            pageSize: 'B4',
            pageMargins: [20, 60, 20, 20],
            content: [],
            styles: {
                table: {
                  margin: [0, 0, 0, 15]
                },
            },
        },
    }),
    validations: {
        anio: { required },
        idInstalacion: { required },
        idOrganizacion: { 
          required: requiredIf(function(){
            return this.isAdmin
          })  
        }
    },
    methods: {
        generarReporte() {
          let orga  = this.isAdmin ? this.orgs.find( organizacion => organizacion.id === this.idOrganizacion ) : this.organizacion;
          if(this.anio >= 2024) {
            this.docDefinition.content = informe2024(this.constantes,  this.anio, this.anioAnterior,  this.instalacion, orga, this.datos);}
          else if(this.anio == 2023) {
            this.docDefinition.content = informe2023(this.constantes,  this.anio, this.anioAnterior,  this.instalacion, orga, this.datos);}
          else if(this.anio == 2022) {
            this.docDefinition.content = informe2022(this.constantes,  this.anio, this.anioAnterior,  this.instalacion, orga, this.datos);}
          else if(this.anio == 2021) {
            this.docDefinition.content = informe2021(this.constantes,  this.anio, this.anioAnterior,  this.instalacion, orga, this.datos);}
          else if(this.anio <= 2020) {
            this.docDefinition.content = informe2020(this.constantes,  this.anio, this.anioAnterior,  this.instalacion, orga, this.datos);}
          
            pdfMake.createPdf(this.docDefinition).download(`Informe final PBAE-CCC ${this.anio} ${this.instalacion.codigoPbae} ${this.instalacion.nombreOrganizacion} ${this.instalacion.nombre}.pdf`);
            this.loadingModal(false);
        },
        generarReporteAgro() {
          let orga  = this.isAdmin ? this.orgs.find( organizacion => organizacion.id === this.idOrganizacion ) : this.organizacion;
          this.docAgropDefinition.content = informeXII2024(this.constantes,  this.anio, this.anioAnterior,  this.instalacion, orga, this.datos);
          pdfMake.createPdf(this.docAgropDefinition).download(`Informe final PBAE-CA ${this.anio} ${this.instalacion.nombreOrganizacion} ${this.instalacion.nombre}.pdf`);
          this.loadingModal(false);
        },
        aniosParticipacion() {
            this.loadingModal(true);
            axios.get("/instalaciones/getAniosParticipacionInforme/" + this.idInstalacion).then(response => {  
                    this.anios = response.data.anios;   
                    this.categoria = response.data.categoria;
                    this.loadingModal(false);           
            }).catch(error => {
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                console.log("Error al obtener los años de participacion: " + error);
                this.loadingModal(false);   
              }
            })
        },
        getDataAnios() {
            this.aniosParticipacion();
            this.$v.idInstalacion.$touch();
        },
        instalacionesPorUsuario() {
            let orga = this.isAdmin ? this.idOrganizacion : this.organizacion.id;
            axios.get("/organizacion//listarInstalacionesInforme/"+ orga).then(response => {  
                    this.instalaciones = response.data.instalaciones;              
            }).catch(error => {
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                console.log( error);
              }
            })
        },
        getDataAdmin() {
          this.loadingModal(true)
          axios.get("/organizacion//listarOrganizaciones/").then(response => {  
                  this.orgs = response.data.organizaciones;
                  this.loadingModal(false)              
          }).catch(error => {
            if (error.request && error.request.status === 401) {
              this.$store.commit('showSessionDialog', true);
              this.loadingModal(false)
            } else {
              console.log( error);
              this.loadingModal(false)
            }
          })
        },
        getDataInformeFinal() {
            this.$v.$touch();
            if(!this.$v.$invalid) {
                this.instalacion  = this.instalaciones.find( instalacion => instalacion.id === this.idInstalacion );
                let requestUrl = this.categoria === 1 ? '/informe/getDataInformeFinal' : '/informe/getDataAgropepInformeFinal'
                this.loadingModal(true);
                axios.get(`${requestUrl}/${this.instalacion.id}/${this.anio}`)
                .then(r => {
                    this.datos = r.data
                    if(this.categoria == 1) {
                      this.generarReporte();
                    } else {
                      this.generarReporteAgro();
                    }
                }).catch(error => {
                  if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                  } else {
                    console.log(error);
                    this.mensaje = 'Error al generar el informe final. Favor antes de contactar al administrador asegurarse que la inscripción legal está registrada y medidores en estado activo.)';
                    this.alert = true;
                    this.loadingModal(false);
                  }
                })
            }
        },
        loadingModal(modal) {                        
            this.$store.dispatch('loadingPage', modal);
        },
    },
}
</script>