<template>
    <v-dialog
        v-model="dialog"  
        persistent 
        scrollable
        max-width="550px"
        transition="dialog-transition">
        <template v-slot:activator="{ on:dialog }">
            <v-tooltip top>
            <template v-slot:activator="{ on:tooltip }">
              <v-btn @click="getDataAgregar" icon v v-on="{ ...dialog, ...tooltip }" color="#0B3F67">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
            Modificar Uso Fuente
          </v-tooltip>
        </template>
        <v-card :loading="loading">
            <v-card-title class="pa-0">
              <ModalTitle titulo="Agregar Uso Fuente Agua" @cancelar="cancelar"/>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="12" class="pt-6 pb-0">
                        <v-text-field
                            label="Fuente de Agua"
                            counter="100"
                            v-model="medidor"
                            disabled
                        >
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" md="12" class="pt-0 pb-0">
                        <v-textarea
                            rows="3"
                            label="Uso"
                            counter="200"
                            v-model="uso"
                            :error-messages="usoErrors"             
                            @change="$v.uso.$touch()"
                            @blur="$v.uso.$touch()"
                            required
                        ></v-textarea>
                    </v-col>
                    <v-col cols="12" class="pt-0 pb-0">
                        <v-file-input 
                            accept=".png, .jpg, .jpeg"
                            show-size 
                            counter  
                            multiple
                            v-model="archivosConc" 
                            label="Permiso de concesión"
                            :error-messages="evidConcErrors"
                            @change="$v.archivosConc.$touch()"
                            @blur="$v.archivosConc.$touch()">
                        </v-file-input>
                    </v-col>
                    <v-col cols="12" class="pt-0 pb-0">
                        <v-file-input 
                            accept=".png, .jpg, .jpeg"
                            show-size 
                            counter  
                            multiple
                            v-model="archivosCert" 
                            label="Certificación de análisis microbiológico"
                            :error-messages="evidCertErrors"
                            @change="$v.archivosCert.$touch()"
                            @blur="$v.archivosCert.$touch()">
                        </v-file-input>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" :disabled="loading" text @click.native="cancelar">Cancelar</v-btn>
                <v-btn color="#0B3F67" :loading="loading" text @click="agregarActividad">Agregar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>

import ModalTitle from '@/components/utils/ModalTitleComponent'
import { required, maxLength } from "vuelidate/lib/validators"
import axios from "@/axios.js";
//Custom validations
const maxFileSize = (files) => {
    if(files.length) {
        let maximo = files.length * 1000000;
        let total = 0;
        files.forEach(file => {
            total += file.size
        });
        console.error(total);
        
        return total < maximo;
    }
    return true;
}

export default {
    components: {
      ModalTitle
    },
    props: [
        'idMedidor'
    ],
    computed: {
        fileSize() {
            return this.archivosConc != null ? this.archivosConc.length : 0;
        },
        fileSizeCert() {
            return this.archivosCert != null ? this.archivosCert.length : 0;
        },
        usoErrors(){
            const errors = []
            if(!this.$v.uso.$dirty) return errors
            !this.$v.uso.required && errors.push('Dato requerido')
            return errors
        },
        evidConcErrors() {
            const errors = []
            if (!this.$v.archivosConc.$dirty) return errors            
            !this.$v.archivosConc.maxFileSize && errors.push('El tamaño total debe ser menor a ' + this.fileSize + 'MB.')
            return errors
        },
        evidCertErrors() {
            const errors = []
            if (!this.$v.archivosCert.$dirty) return errors            
            !this.$v.archivosCert.maxFileSize && errors.push('El tamaño total debe ser menor a ' + this.fileSizeCert + 'MB.')
            return errors
        },
        indicadorActual() {
            return this.$store.getters.indicadorActual;
        },
        sucursal() {
            return this.$store.getters.sucursal;
        },
        usuario(){
            return this.$store.getters.usuario;
        },
        anioConsulta() {
            return this. $store.getters.anioConsulta;
        },
    },
    data: () => ({
        medidor: '',
        loading: false,
        dialog: false,
        uso: '',
        archivosConc:[],
        archivosCert:[],
    }),
    validations: {
        uso: {
            maxLength: maxLength(200),
            required
        },
        archivosConc: {
            maxFileSize
        },
        archivosCert: {
            maxFileSize
        }
    },
    methods: {
        getDataAgregar() {
            this.loading = true;
            axios.get(`/indicadorAgua/getDataFuente/${this.idMedidor}`)
            .then(response => {
                this.uso = response.data.uso;
                this.medidor = response.data.medidor;
            }).catch(err => {
                console.error(err);
            }).finally(() => {
                this.loading = false;
            })
        },
        agregarActividad() {
            this.$v.$touch();
            if(!this.$v.$invalid) {
                this.loading = true;
                var formData = new FormData();
                this.archivosConc.forEach(file => {
                    formData.append('archivosConc', file);
                });
                this.archivosCert.forEach(file => {
                    formData.append('archivosCert', file);
                });
                formData.append("uso", this.uso);
                formData.append("idMedidor", this.idMedidor);
                formData.append("anio", this.anioConsulta);
                axios.post("/indicadorAgua/agregarUsoFuente", formData).then(() => {
                    this.$store.dispatch('noti',{mensaje: 'Uso de fuente de agua agregado', color:'success'});   
                    this.$emit('reload');
                    this.cancelar();
                }).catch(error => {
                  if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                  } else {
                    if (error.request && error.request.status === 415) {
                      this.$store.dispatch('noti', {mensaje: error.response.data, color: 'error'});
                      this.loading = false;
                    } else {
                      this.$store.dispatch('noti', {mensaje: 'Ocurrió un error no se agregó el uso de la fuente', color:'error'});   
                      this.loading = false;  
                    } 
                  }        
                })
            }
        },
        cancelar() {
            this.archivosConc=[];
            this.archivosCert=[];
            this.otro = "";
            this.$v.$reset();
            this.dialog = false;
            this.loading = false;
        }
    },
}
</script>