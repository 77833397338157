<template>
    <v-dialog
        v-model="dialog" 
        persistent
        scrollable
        max-width="550px"
        transition="dialog-transition">
        <template v-slot:activator="{ on }">
            <v-tooltip top>
                <template v-slot:activator="{ on: tooltip }">                    
                    <v-icon color="#0B3F67" v-on="{...tooltip, ...on}" @click="getDataAccionEvento()">mdi-playlist-edit</v-icon>
                </template>
                <span>{{ tooltip }}</span>
            </v-tooltip>
        </template>
        <v-card>
            <v-card-title class="pa-0">
              <ModalTitle :titulo="modalTitle" @cancelar="cancelar"/>
            </v-card-title> 
            <v-divider></v-divider>
            <v-card-text>
                <v-row>
                    <v-col cols="12" class="pt-6"> 
                        <v-textarea
                            outlined
                            label="Evento Climático"
                            v-model="evento.evento"
                            disabled
                        ></v-textarea>
                    </v-col>
                    <v-col cols="12">
                        <v-textarea
                            outlined
                            :label="inputLabel"
                            v-model="evento.acciones"
                            counter="200"
                            :error-messages="eventAccErrors"             
                            @change="$v.evento.acciones.$touch()"
                            @blur="$v.evento.acciones.$touch()"
                            required
                        ></v-textarea>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" @click="cancelar" text>Cancelar</v-btn>
                <v-btn color="#0B3F67" @click="agregarModificar" text>Aceptar</v-btn>                
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import ModalTitle from '@/components/utils/ModalTitleComponent'
import { required, maxLength } from "vuelidate/lib/validators";
import axios from '@/axios.js'

export default {
    props: [
        'tipoAccion',
        'eventoProp',
        'instalacion',
        'usuario',
        'cambioClimatico'
    ],
    components: {
      ModalTitle
    },
    computed: {
        eventAccErrors() {
            const errors = []
            if (!this.$v.evento.acciones.$dirty) return errors            
            !this.$v.evento.acciones.required && errors.push('Las acciones son requeridas.')
            !this.$v.evento.acciones.maxLength && errors.push('Este dato excede el tamaño permitido.')
            return errors
        },
        modalTitle() {
            return this.cambioClimatico ? 'Acción de Evento Climático' : 'Práctica de Adaptacion a Evento Climático'
        }, 
        inputLabel() {
            return this.cambioClimatico ? 'Acciones Realizadas' : 'Práctica de Adaptacion a Evento Climático'
        },
        tooltip() {
            return this.cambioClimatico ? 'Agregar o Modificar Acción' : 'Práctica de Adaptacion a Evento Climático'
        }
    },
    data: () => ({
        dialog: false,
        evento: {
            evento: null,            
            acciones: null
        }
    }),
    validations: {
        evento: {
            acciones: {
                required,
                maxLength: maxLength(200)     
            }
        }        
    },
    methods: {
        getDataAccionEvento() {
            this.evento.evento = this.eventoProp.evento;
            this.evento.acciones = this.eventoProp.acciones;
        },
        agregarModificar() {
            this.$v.$touch();
            if(!this.$v.$invalid) {
                this.loadingModal(true);
                axios.post('/adaptacion/agregarModificarAccion', null, {params: {
                    evento: this.eventoProp.id,
                    accion: this.evento.acciones,
                    inst: this.instalacion.id,
                    usuario: this.usuario.id
                }}).then(response => {
                    this.$store.dispatch('noti', {mensaje: 'Acción de evento climático agregado', color: 'success'});
                    this.loadingModal(false);
                    this.$emit('reload');
                    this.cancelar();
                }).catch(error => {
                  if (error.request && error.request.status === 401) {
                   this.$store.commit('showSessionDialog', true);
                  } else {
                    this.$store.dispatch('noti', {mensaje: 'Ocurrió un error no se pudo agregar la acción del evento', color: 'error'});
                    this.loadingModal(false);
                  }
                })
            }
        },
        cancelar() {
            this.evento = {};
            this.$v.evento.$reset();
            this.dialog = false;
        },
        loadingModal(modal) {                        
            this.$store.dispatch('loadingPage', modal);
        },
    },
}
</script>