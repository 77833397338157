var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('logo',{directives:[{name:"sticky",rawName:"v-sticky",value:({ zIndex: 4, stickyTop: 65}),expression:"{ zIndex: 4, stickyTop: 65}"}],on:{"cambiaAnio":_vm.getData}}),_c('navParameters',{attrs:{"navParams":_vm.navParams}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('tituloPrincipal',{attrs:{"titulo":"Caracterización del uso del Suelo"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"3"}},[_c('Agregar')],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"3"}})],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('tituloSimple',{attrs:{"titulo":"Caracterización del uso del Suelo"}}),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.actividades,"calculate-widths":"","item-key":"id","search":_vm.search,"no-data-text":"No hay Actividades para este periodo","footer-props":{
                            showFirstLastPage: true,
                            firstIcon:'mdi-page-first',
                            lastIcon: 'mdi-page-last',
                            prevIcon: 'mdi-chevron-left',
                            nextIcon: 'mdi-chevron-right',
                            itemsPerPageText: 'Registros por página',
                            itemsPerPageAllText: 'Todos',
                            showCurrentPage: true,
                            showFirstLastPage: true
                        }},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
                        var item = ref.item;
return [(_vm.rolUsuario != 'CEO' && _vm.rolUsuario != 'GERENTE' && _vm.rolUsuario != 'SUPERVISOR')?_c('ModCaractUsoSuelo',{attrs:{"id":item.id},on:{"reload":_vm.getData}}):_vm._e(),(_vm.rolUsuario != 'CEO' && _vm.rolUsuario != 'GERENTE' && _vm.rolUsuario != 'SUPERVISOR')?_c('AgregarEvidencia',{attrs:{"id":item.id,"origen":"usoSuelo","tooltipText":"Agregar Evidencias","titulo":"Agregar Evidencias"},on:{"reload":_vm.getData}}):_vm._e(),_c('Eliminar',{attrs:{"requestData":_vm.requestDataFormat(item),"requestUrl":"/manejoSuelo/elimnarEvidCaractUsoSuelo"},on:{"reload":_vm.getData}})]}},{key:"item.evidencia",fn:function(ref){
                        var item = ref.item;
return [_c('ListadosDocs',{attrs:{"id":item.id,"origen":"usoSuelo","titulo":"Evidencias"}})]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }