<template>
    <v-container fluid>
        <logo @cambiaAnio="getFuentesAgua" v-sticky="{ zIndex: 4, stickyTop: 65}" />
        <navParameters :navParams="navParams"/>
        <v-row>
            <v-col cols="12">
                <tituloPrincipal titulo="Identificar Fuentes de Agua"/>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" sm="12" md="3">
                <AgregarFuente  @agregarMedidor="getFuentesAgua" :anioConsulta="anioConsulta" :unidadMedida="unidadesAgua"/>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="12" md="3">

            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-card>
                    <tituloSimple titulo="Fuentes de Agua"/>
                    <v-card-text>
                        <v-data-table
                            :headers="headers"
                            :items="items"
                            item-key="id"
                            :search="search">
                            <template v-slot:item.action="{ item }">
                                <ModificarFuente  @modificarMedidor="getFuentesAgua" :idFuente="item.id" :anioConsulta="anioConsulta" :unidadMedida="unidadesAgua"/>
                            </template>
                            <template v-slot:item.estado="{ item }">
                                <v-layout justify-center>
                                    <activarMedidor @reload="getFuentesAgua" :estado="item.estado" :id="item.id"/>
                                </v-layout>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>                
            </v-col>
        </v-row>
    </v-container>
</template>
<script>

import axios from '@/axios.js'
import VueSticky from 'vue-sticky'
import Logo from '@/components/utils/CompanyComponent'
import TituloSimple from '@/components/utils/TituloSimpleComponent'
import NavParameters from '@/components/utils/NavParameterComponent'
import TituloPrincipal from '@/components/utils/TituloPrincipalComponent'
import ActivarMedidor from '@/components/indicadores/ActivarMedidorComponent'
import AgregarFuente from "@/components/parametros/Agua/Agropecuario/AgregarFuenteAgua"
import ModificarFuente from '@/components/parametros/Agua/Agropecuario/ModificarFuenteAgua.vue'

export default {
    components: {
        ModificarFuente,
        ActivarMedidor,
        TituloPrincipal,
        AgregarFuente,
        TituloSimple,
        NavParameters,
        Logo
    },
    props: [
        'navParams'
    ],
    created() {
        axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;        
    },
    mounted() {
        this.getFuentesAgua();
    },
    directives: {
        'sticky': VueSticky,
    },
    computed: {
        usuario() {
            return this.$store.getters.usuario;
        },
        instalacion() {
            return this.$store.getters.sucursal;
        },
        rolUsuario(){
            return this.$store.getters.usuario.rol;
        },
        anioConsulta() {
            return this. $store.getters.anioConsulta;
        },
        parametroActual() {
            return this.$store.getters.parametroActual;
        },
        indicador() {
            return parseInt(this.$store.getters.indicadorActual);
        },
    },
    data: () => ({
        headers: [
            {
                text: 'Acciones',
                value: 'action',
                align: 'center',
                width: '10%',
                sortable: false
            },
            {
                text: 'Estado',
                value: 'estado',
                align: 'center',
                width: '10%',
                sortable: false
            },
            {
                text: 'Nombre de la Fuente',
                value: 'medidor',
                align: 'left',
                width: '30%',
                sortable: false
            },
            {
                text: 'Tipo de Fuente',
                value: 'tipoFuenteStr',
                align: 'center',
                width: '20%',
                sortable: false
            },
            {
                text: 'Ubicación',
                value: 'localizacion',
                align: 'left',
                width: '30%',
                sortable: false
            },
        ],
        items: [],
        search: null,
        unidadesAgua: [
            {nombre:'LITROS', valor: 2},              
            {nombre:'GALONES', valor: 3},
            {nombre:'METROS CÚBICOS', valor: 4}, 
        ],
    }),
    methods: {
        getFuentesAgua() {
            axios.get(`/indicadorAgua/getFuentesAgua/${this.instalacion.id}/${this.anioConsulta}`)
            .then(response => {
                this.items = response.data;
                this.loadingModal(false);
            }).catch(error => {
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                this.loadingModal(false);
              }
            })
        },
        loadingModal(modal) {                        
            this.$store.dispatch('loadingPage', modal);
        }
    },
}
</script>