<template>
    <v-container fluid class="pr-0 pl-0">
        <logo @cambiaAnio="getData" v-sticky="{ zIndex: 4, stickyTop: 65}" />
        <navParameters :navParams="navParams"/>
        <v-row>
            <v-col cols="12">
                <TituloPrincipal titulo="Inventario de Consumidores"/>
            </v-col>
            <InventariosConsumidores ref="inventario" :headers="headers" :indicadorActual="indicador"/>
        </v-row>
    </v-container>
</template>

<script>

import VueSticky from 'vue-sticky'
import Logo from '@/components/utils/CompanyComponent'
import NavParameters from '@/components/utils/NavParameterComponent'
import TituloPrincipal from '@/components/utils/TituloPrincipalComponent'
import InventariosConsumidores from '@/components/parametros/InventarioConsumidores/InventariosConsumidoresComponent'

export default {
    components:{
        TituloPrincipal,
        InventariosConsumidores,
        NavParameters,
        Logo
    },
    directives: {
        'sticky': VueSticky,
    },
    props:[
        'indicador',
        'navParams'
    ],
    mounted(){
        setTimeout(function () {this.loadingModal(false)}.bind(this), 1000);
    },
    data: ()=>({
        headers:[
            {
                text: 'Acciones',
                value: 'action',
                align: 'center',
                class: "educTable",
            },
            {
                text: 'Estado',
                value: 'estado',
                align: 'center',
                class: "educTable",
            },
            {
                text: 'Tipo de Consumidor',
                value: 'tipoConsumidor',
                align: 'center',
                class: "educTable",
            },
            {
                text: 'Num Activo',
                value: 'numActivo',
                align: 'center',
                class: "educTable",
                
            },
            {
                text: 'Marca',
                value: 'marca',
                align: 'center',
                class: "educTable",

            },
            {
                text: 'Tipo de Combustible',
                value: 'tipoCombustible',
                align: 'center',
                class: "educTable",
            },
            {
                text: 'Responsable de su Uso',
                value: 'responsableUso',
                align: 'center',
                class: "educTable",
            },
        ]
    }), 
    methods:{
        loadingModal(modal) {                        
            this.$store.dispatch('loadingPage', modal);
        },
        getData() {
            this.$refs.inventario.getEquipos();
        }      
    }
}
</script>