<template>
    <v-dialog
        v-model="dialog"  
        persistent 
        scrollable
        max-width="550px"
        transition="dialog-transition">
        <template v-slot:activator="{ on }">
            <v-tooltip bottom>
                <template v-slot:activator="{ on: tooltip }">
                <v-icon
                    small
                    exact-active-class="indigo"
                    class="ma-2"
                    v-on="{ ...tooltip, ...on }"
                    @click="openModal()"
                >mdi-pencil</v-icon>
                </template>
                <span>Modificar</span>
            </v-tooltip>
        </template>
        <v-card :loading="loading">
            <v-card-title class="pa-0">
              <ModalTitle titulo="Modificar Insumo Agropecuario" @cancelar="cancelar"/>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="12" class="pt-3 pb-0">
                        <v-text-field
                            rows="3"
                            label="Nombre"
                            counter="500"
                            v-model="inventario.nombre"
                            :error-messages="nombErrors"             
                            @change="$v.inventario.nombre.$touch()"
                            @blur="$v.inventario.nombre.$touch()"
                            required
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="12" class="pt-0 pb-0">
                        <v-text-field
                            rows="3"
                            label="Toxicidad"
                            counter="500"
                            v-model="inventario.toxicidad"
                            :error-messages="toxicErrors"             
                            @change="$v.inventario.toxicidad.$touch()"
                            @blur="$v.inventario.toxicidad.$touch()"
                            required
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="12" class="pt-0 pb-0">
                        <v-textarea
                            rows="3"
                            label="Uso"
                            counter="500"
                            v-model="inventario.uso"
                            :error-messages="usoErrors"             
                            @change="$v.inventario.uso.$touch()"
                            @blur="$v.inventario.uso.$touch()"
                            required
                        ></v-textarea>
                    </v-col>
                    <v-col cols="12" md="12" class="pt-0 pb-0">
                        <v-text-field
                            rows="3"
                            label="Ingrediente Activo"
                            counter="500"
                            v-model="inventario.ingredienteActivo"
                            :error-messages="ingErrors"             
                            @change="$v.inventario.ingredienteActivo.$touch()"
                            @blur="$v.inventario.ingredienteActivo.$touch()"
                            required
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="12" class="pt-0 pb-0">
                        <v-text-field
                            rows="3"
                            label="Cantidad Utilizada"
                            counter="500"
                            v-model="inventario.cantidad"
                            :error-messages="cantErrors"             
                            @change="$v.inventario.cantidad.$touch()"
                            @blur="$v.inventario.cantidad.$touch()"
                            required
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" :disabled="loading" text @click.native="cancelar">Cancelar</v-btn>
                <v-btn color="#0B3F67" :loading="loading" text @click="modificar">Modificar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>

import { mask } from "vue-the-mask"
import ModalTitle from '@/components/utils/ModalTitleComponent'
import { required, maxLength, decimal } from "vuelidate/lib/validators"
import axios from "@/axios.js";

export default {
    directives: {
        mask,
    },
    props: [
        'idInsumo'
    ],
    components: {
      ModalTitle
    },
    computed: {
        nombErrors () {
            const errors = []
            if (!this.$v.inventario.nombre.$dirty) return errors
            !this.$v.inventario.nombre.required && errors.push('Campo requerido')
            !this.$v.inventario.nombre.maxLength && errors.push('No debe exceder el tamaño permitido.')
            return errors
        },
        toxicErrors () {
            const errors = []
            if (!this.$v.inventario.toxicidad.$dirty) return errors
            !this.$v.inventario.toxicidad.required && errors.push('Campo requerido')
            !this.$v.inventario.toxicidad.maxLength && errors.push('No debe exceder el tamaño permitido.')
            return errors
        },
        usoErrors () {
            const errors = []
            if (!this.$v.inventario.uso.$dirty) return errors
            !this.$v.inventario.uso.required && errors.push('Campo requerido')
            !this.$v.inventario.uso.maxLength && errors.push('No debe exceder el tamaño permitido.')
            return errors
        },
        ingErrors () {
            const errors = []
            if (!this.$v.inventario.ingredienteActivo.$dirty) return errors
            !this.$v.inventario.ingredienteActivo.required && errors.push('Campo requerido')
            !this.$v.inventario.ingredienteActivo.maxLength && errors.push('No debe exceder el tamaño permitido.')
            return errors
        },
        cantErrors () {
            const errors = []
            if (!this.$v.inventario.cantidad.$dirty) return errors
            !this.$v.inventario.cantidad.required && errors.push('Campo requerido')
            !this.$v.inventario.cantidad.decimal && errors.push('Solo debe digitar números.')
            return errors
        },
        indicadorActual() {
            return this.$store.getters.indicadorActual;
        },
        sucursal() {
            return this.$store.getters.sucursal;
        },
        usuario(){
            return this.$store.getters.usuario;
        },
    },
    data: () => ({
        loading: false,
        dialog: false,
        inventario: {
            nombre: '',
            toxicidad: '',
            uso: '',
            ingredienteActivo: '',
            cantidad: null
        }, 
    }),
    validations: {
        inventario: {
            nombre: {
                maxLength: maxLength(500),
                required
            },
            toxicidad: {
                maxLength: maxLength(500),
                required
            },
            uso: {
                maxLength: maxLength(500),
                required
            },
            ingredienteActivo: {
                maxLength: maxLength(500),
                required
            },
            cantidad: {
                required,
                decimal
            }
        }
    },
    methods: {
        openModal() {
            axios.get(`/insumoAgropecuario/getInsumo/${this.idInsumo}`).then(r => {
                    this.inventario = r.data
                }).catch(error => {
                  if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                  } else {
                    if (error.request && error.request.status === 415) {
                      this.$store.dispatch('noti', {mensaje: error.response.data, color: 'error'});
                      this.loading = false;
                    } else {
                      this.$store.dispatch('noti', {mensaje: 'Ocurrió un error no se agregó el insumo', color:'error'});   
                      this.loading = false;  
                    } 
                  }        
                })
        },
        modificar() {
            this.$v.$touch();
            if(!this.$v.$invalid) {
                this.loading = true;
                axios.post("/insumoAgropecuario/modificarInsumo", this.inventario, { params: {
                    anterior: this.idInsumo,
                    usuario: this.usuario.id
                }}).then(response => {
                    this.$store.dispatch('noti',{mensaje: 'Insumo modificado', color:'success'});   
                    this.$emit('reload');
                    this.cancelar();
                }).catch(error => {
                  if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                  } else {
                    if (error.request && error.request.status === 415) {
                      this.$store.dispatch('noti', {mensaje: error.response.data, color: 'error'});
                      this.loading = false;
                    } else {
                      this.$store.dispatch('noti', {mensaje: 'Ocurrió un error no se modificó el insumo', color:'error'});   
                      this.loading = false;  
                    } 
                  }        
                })
            }
        },
        cancelar() {
            this.inventario = {};
            this.$v.$reset();  
            this.dialog = false;
            this.loading = false;
        }
    },
}
</script>