<template>
    <v-container fluid>
        <logo @cambiaAnio="getData" v-sticky="{ zIndex: 4, stickyTop: 65}" />
        <navParameters :navParams="navParams"/>
        <v-row>
            <v-col cols="12">
                <tituloPrincipal titulo="Caracterización del uso del Suelo"/>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" sm="12" md="3">
                <Agregar />
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="12" md="3">

            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-card>
                    <tituloSimple titulo="Caracterización del uso del Suelo"/>
                    <v-card-text>
                        <v-data-table
                            :headers="headers"
                            :items="actividades"
                            calculate-widths
                            item-key="id"
                            :search="search"
                            no-data-text="No hay Actividades para este periodo"
                            :footer-props="{
                                showFirstLastPage: true,
                                firstIcon:'mdi-page-first',
                                lastIcon: 'mdi-page-last',
                                prevIcon: 'mdi-chevron-left',
                                nextIcon: 'mdi-chevron-right',
                                itemsPerPageText: 'Registros por página',
                                itemsPerPageAllText: 'Todos',
                                showCurrentPage: true,
                                showFirstLastPage: true
                            }"
                        >
                        <template v-slot:item.action="{ item }">
                                <ModCaractUsoSuelo 
                                    @reload="getData"
                                    v-if="rolUsuario != 'CEO' && rolUsuario != 'GERENTE' && rolUsuario != 'SUPERVISOR'"
                                    :id="item.id"/>
                                <AgregarEvidencia
                                    v-if="rolUsuario != 'CEO' && rolUsuario != 'GERENTE' && rolUsuario != 'SUPERVISOR'"
                                    @reload="getData"
                                    :id="item.id"
                                    origen="usoSuelo"
                                    tooltipText="Agregar Evidencias"
                                    titulo="Agregar Evidencias"
                                ></AgregarEvidencia>
                                <Eliminar 
                                    v-bind:requestData="requestDataFormat(item)" 
                                    requestUrl="/manejoSuelo/elimnarEvidCaractUsoSuelo"
                                    @reload="getData"
                                /> 
                        </template>
                        <template v-slot:item.evidencia="{ item }">
                            <ListadosDocs :id="item.id" origen="usoSuelo" titulo="Evidencias"></ListadosDocs>
                        </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>                
            </v-col>
        </v-row>
    </v-container>
</template>
<script>

import axios from '@/axios.js'
import VueSticky from 'vue-sticky'
import Logo from '@/components/utils/CompanyComponent'
import TituloSimple from '@/components/utils/TituloSimpleComponent'
import NavParameters from '@/components/utils/NavParameterComponent'
import TituloPrincipal from '@/components/utils/TituloPrincipalComponent'
import Eliminar from '@/components/utils/EliminarComponent'
import AgregarEvidencia from "@/components/parametros/ManejoSuelo/AgregarEvidencias"
import Agregar from '@/components/parametros/ManejoSuelo/AgregarCaractUsoSuelo'
import ListadosDocs from "@/components/documentos/ListadoDocumentosComponent"
import ModCaractUsoSuelo from '@/components/parametros/ManejoSuelo/ModCaractUsoSuelo.vue'

export default {
    components: {
        ListadosDocs,
        ModCaractUsoSuelo,
        AgregarEvidencia,
        TituloPrincipal,
        TituloSimple,
        NavParameters,
        Eliminar,
        Agregar,
        Logo
    },
    props: [
        'navParams'
    ],
    created() {
        axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;        
    },
    mounted() {
        this.getData();
    },
    directives: {
        'sticky': VueSticky,
    },
    computed: {
        usuario() {
            return this.$store.getters.usuario;
        },
        instalacion() {
            return this.$store.getters.sucursal;
        },
        rolUsuario(){
            return this.$store.getters.usuario.rol;
        },
        anioConsulta() {
            return this. $store.getters.anioConsulta;
        },
        parametroActual() {
            return this.$store.getters.parametroActual;
        },
        indicador() {
            return parseInt(this.$store.getters.indicadorActual);
        },
    },
    data: () => ({
        actividades: [],
        headers: [
            {
                text: 'Acciones',
                value: 'action',
                align: 'center',
                width: '10%',
                sortable: false
            },
            {
                text: 'Descripción Croquis',
                value: 'descripCroquis',
                align: 'left',
                width: '30%',
                sortable: false
            },
            {
                text: 'Descripción Uso Suelo',
                value: 'descripUsoSuelo',
                align: 'left',
                width: '30%',
                sortable: false
            },
            {
                text: 'Descripción Topografia',
                value: 'descripTopografia',
                align: 'left',
                width: '20%',
                sortable: false
            },
            {
                text:"Evidencia",
                value: "evidencia",
                align: "center",
                width: '10%',
                class: "educTabla",
            }
        ],
        items: [],
        search: null,
    }),
    methods: {
        getData() {
            this.loadingModal(false)
            axios.get("/manejoSuelo/getCaractUsoSuelo", { params: {
                instalacion: this.instalacion.id,
                anio: this.anioConsulta
            }}).then(r => {
                this.actividades = r.data
            }).catch(error => {
                if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
                } else {
                if (error.request && error.request.status === 415) {
                    this.$store.dispatch('noti', {mensaje: error.response.data, color: 'error'});
                    this.loading = false;
                } else {
                    this.$store.dispatch('noti', {mensaje: 'Ocurrió un error no se agregó la actividad de educación', color:'error'});   
                    this.loading = false;  
                } 
                }        
            })
        },
        loadingModal(modal) {                        
            this.$store.dispatch('loadingPage', modal);
        },
        requestDataFormat(data) {
            let datos = new FormData()
            datos.append('caractUsoSuelo', data.id);
            datos.append('idUsuario', this.usuario.id);
            return datos;
        }
    },
}
</script>