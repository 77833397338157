<template>
    <v-dialog
        v-model="dialog" 
        persistent
        max-width="550px"
        transition="dialog-transition">
        <template v-slot:activator="{ on }">
            <v-tooltip bottom>
                <template v-slot:activator="{ on: tooltip }">
                <v-icon
                    small
                    exact-active-class="indigo"
                    class="ma-2"
                    v-on="{ ...tooltip, ...on }"
                    @click="getDataModificar()"
                >mdi-pencil</v-icon>
                </template>
                <span>Modificar</span>
            </v-tooltip>
        </template>
        <v-card :loading="loading">
            <v-card-title class="pa-0">
              <ModalTitle titulo="Modificar Programa Manejo Animal" @cancelar="cancelar"/>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <v-row class="pt-3">
                    <v-col cols="12" md="12" class="pt-0 pb-0">
                        <v-select
                            :items="actividades"
                            v-model="accion.tipoActividad"
                            :error-messages="tipoErrors"             
                            @change="checkOtro(), $v.accion.tipoActividad.$touch()"
                            @blur="$v.accion.tipoActividad.$touch()"
                            counter="200"
                            required                            
                            label="Programa"
                        ></v-select>
                    </v-col>
                    <v-col v-if="isOtro" cols="12" md="12" class="pt-0 pb-0">
                        <v-textarea
                            rows="3"
                            label="Nombre Programa"
                            counter="100"
                            v-model="otro"
                            :error-messages="otroErrors"             
                            @change="$v.otro.$touch()"
                            @blur="$v.otro.$touch()"
                            required
                        >
                        </v-textarea>
                    </v-col>
                    <v-col cols="12" md="12" class="pt-0 pb-0">
                        <v-textarea
                            rows="3"
                            label="Descripción Edad del animal"
                            counter="500"
                            v-model="accion.descripcionOptUno"
                            :error-messages="descEdadErrors"             
                            @change="$v.accion.descripcionOptUno.$touch()"
                            @blur="$v.accion.descripcionOptUno.$touch()"
                            required
                        ></v-textarea>
                    </v-col>
                    <v-col cols="12" md="12" class="pt-0 pb-0">
                        <v-textarea
                            rows="3"
                            label="Descripción Etapa reproductiva"
                            counter="500"
                            v-model="accion.descripcionOptDos"
                            :error-messages="descEtapaErrors"             
                            @change="$v.accion.descripcionOptDos.$touch()"
                            @blur="$v.accion.descripcionOptDos.$touch()"
                            required
                        ></v-textarea>
                    </v-col>
                    <v-col cols="12" md="12" class="pt-0 pb-0">
                        <v-textarea
                            rows="3"
                            label="Descripción del programa"
                            counter="500"
                            v-model="accion.descripcion"
                            :error-messages="descErrors"             
                            @change="$v.accion.descripcion.$touch()"
                            @blur="$v.accion.descripcion.$touch()"
                            required
                        ></v-textarea>
                    </v-col>
                    <v-col cols="12" class="pt-0 pb-0">
                        <v-menu
                            v-model="menu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="290px">
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="fechaMoment"
                                    label="Fecha de la Actividad"
                                    prepend-inner-icon="mdi-calendar-month"
                                    readonly
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker 
                                v-model="fechaInscripcion" 
                                locale="es-CR"
                                @input="menu = false"></v-date-picker>
                        </v-menu>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" :disabled="loading" text @click.native="cancelar">Cancelar</v-btn>
                <v-btn color="#0B3F67" :loading="loading" text @click="modificarAccion">Modificar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>

import moment from "moment"
import { mask } from "vue-the-mask"
import { required, maxLength, requiredIf } from "vuelidate/lib/validators"
import ModalTitle from '@/components/utils/ModalTitleComponent'
import axios from "@/axios.js";

export default {
    created() {
        axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;
    }, 
    components: {
      ModalTitle
    },
    props: [
        'programa'
    ],
    directives: {
        mask,
    },
    computed: {
        anioConsulta() {
            return this. $store.getters.anioConsulta;
        },
        fechaMaxima() {
            return `${this.anioConsulta}-12-31`;
        },
        fechaMinima() {
            return `${this.anioConsulta}-01-01`;
        },
        fechaMoment () {
            return this.fechaInscripcion ? moment(this.fechaInscripcion).format('DD/MM/YYYY') : '';
        },
        tipoErrors () {
            const errors = []
            if (!this.$v.accion.tipoActividad.$dirty) return errors
            !this.$v.accion.tipoActividad.required && errors.push('Campo requerido')
            !this.$v.accion.tipoActividad.maxLength && errors.push('No debe exceder el tamaño permitido.')
            return errors
        },
        descErrors () {
            const errors = []
            if (!this.$v.accion.descripcion.$dirty) return errors
            !this.$v.accion.descripcion.required && errors.push('Campo requerido')
            !this.$v.accion.descripcion.maxLength && errors.push('No debe exceder el tamaño permitido.')
            return errors
        },
        descEdadErrors () {
            const errors = []
            if (!this.$v.accion.descripcionOptUno.$dirty) return errors
            !this.$v.accion.descripcionOptUno.required && errors.push('Campo requerido')
            !this.$v.accion.descripcionOptUno.maxLength && errors.push('No debe exceder el tamaño permitido.')
            return errors
        },
        descEtapaErrors () {
            const errors = []
            if (!this.$v.accion.descripcionOptDos.$dirty) return errors
            !this.$v.accion.descripcionOptDos.required && errors.push('Campo requerido')
            !this.$v.accion.descripcionOptDos.maxLength && errors.push('No debe exceder el tamaño permitido.')
            return errors
        },
        otroErrors(){
            const errors = []
            if(this.accion.tipo === "Otro" && !this.$v.otro.$dirty) return errors
            !this.$v.otro.required && errors.push('Dato requerido')
            return errors
        }, 
        fechaErrors () {
            const errors = []
            if (!this.$v.fechaInscripcion.$dirty) return errors
            !this.$v.fechaInscripcion.required && errors.push('Campo requerido')
            return errors
        },
        indicadorActual() {
            return this.$store.getters.indicadorActual;
        },
        usuario(){
            return this.$store.getters.usuario;
        },
        instalacion() {
            return this. $store.getters.sucursal;
        }
    },
    data: () => ({
        isOtro: false,
        tipoAccion: '',
        dialog: false,
        loading: false,
        menu: false,
        mask: '####',
        fechaInscripcion: '',
        accion: {
            tipoActividad: '',
            descripcion: '',
            descripcionOptUno: '',
            descripcionOptDos: '',
            fechaActividad: null,
        },
        actividades:[
            "Programa sanitario",
            "Programa alimenticio",
            "Programa reproductivo",
            "Otro"
        ],
        otro : "",
    }),
    validations: {
        accion: {
            tipoActividad: {
                maxLength: maxLength(200),
                required
            },
            descripcion: {
                maxLength: maxLength(500),
                required
            },
            descripcionOptUno: {
                maxLength: maxLength(500),
                required
            },
            descripcionOptDos: {
                maxLength: maxLength(500),
                required
            },
        },
        fechaInscripcion: {
            required
        },
        otro: {
            required: requiredIf(function(){
                return this.accion.tipo === "Otro" ? true : false;
            })
        }
    },
    methods: {
        getDataModificar() {
            this.loading = true
            axios.get(`/bienestarAnimal/getDataProgramaManejo/${this.programa}`).then(r => {
                if(this.actividades.includes(r.data.tipoActividad)){
                    this.accion.tipoActividad = r.data.tipoActividad;
                } else {
                    this.accion.tipoActividad = "Otro";
                    this.otro = r.data.tipoActividad;
                    this.isOtro = true
                }
                this.accion.descripcion = r.data.descripcion
                this.accion.descripcionOptUno = r.data.descripcionOptUno
                this.accion.descripcionOptDos = r.data.descripcionOptDos
                this.accion.fechaActividad = r.data.fechaActividad
                this.fechaInscripcion = new Date(this.accion.fechaActividad).toISOString().substr(0, 10);
                this.loading = false
            }).catch(error => {
                console.error(error);
                this.loading = false
            })
        },
        modificarAccion() {
            this.$v.$touch();
            if(!this.$v.$invalid) {
                this.loading = true;
                axios.post("/bienestarAnimal/modificarProgramaManejo", null, { params: {
                    tipo: this.accion.tipoActividad,
                    descripcion: this.accion.descripcion,
                    descripcionOptUno: this.accion.descripcionOptUno,
                    descripcionOptDos: this.accion.descripcionOptDos,
                    anterior: this.programa,
                    usuario: this.usuario.id,
                    fechaActividad: this.fechaInscripcion,
                    otro: this.otro
                }})
                .then(() => {
                    this.$store.dispatch('noti',{mensaje: 'Programa Manejo Animal modificado', color:'success'});   
                    this.$emit('reload');
                    this.cancelar();
                }).catch(error => {
                  if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                  } else {
                    if (error.request && error.request.status === 415) {
                      this.$store.dispatch('noti', {mensaje: error.response.data, color: 'error'});
                      this.loading = false;
                    } else {
                      this.$store.dispatch('noti', {mensaje: 'Ocurrió un error no se modificó Programa Manejo Animal', color:'error'});  
                      this.cancelar();   
                    }
                  }         
                })
            }
        },
        cancelar() {
            this.accion = {
                tipoActividad: '',
                descripcion: '',
                descripcionOptUno: '',
                descripcionOptDos: '',
                fechaActividad: null,
            };
            this.otro = "";
            this.$v.accion.$reset();    
            this.$v.otro.$reset();   
            this.dialog = false;
            this.loading = false;
        },
        loadingModal(modal) {                        
            this.$store.dispatch('loadingPage', modal);
        },
        checkOtro() {
            this.isOtro = this.accion.tipoActividad === 'Otro' ? true : false
        }
    },
}
</script>