<template>
    <td class="text-center progressBar"><progreso :avance="avanceParam"/></td>
</template>
<script>
    import Progreso from '@/components/utils/BarraProgresoComponent'
    export default {
        components: {
            Progreso
        },
        props: [
            'anioConsulta',
            'parametro'
        ],
        computed: {
            avanceParam() {
                if(this.anioConsulta >= 2024) {
                    if(this.parametro.id == 20 || this.parametro.id == 48) {
                        let puntosPorRegistro = Math.round(this.parametro.puntosParametroCategoriaAux / this.parametro.cantidadMinima);
                        let puntosGanados = (puntosPorRegistro * this.parametro.cantidadIngresada)
                        puntosGanados = puntosGanados > this.parametro.puntosParametroCategoriaAux   ? this.parametro.puntosParametroCategoriaAux : puntosGanados;
                        let porcentaje = ((puntosGanados  / this.parametro.puntosParametroCategoriaAux) * 100)
                        return porcentaje
                    } else { 
                        let puntosPorRegistro = this.parametro.puntosParametroCategoria / this.parametro.cantidadMinima;
                        let puntosGanados = (puntosPorRegistro * this.parametro.cantidadIngresada)
                        puntosGanados = puntosGanados > this.parametro.puntosParametroCategoria ? this.parametro.puntosParametroCategoria : puntosGanados;
                        let porcentaje = ((puntosGanados  / this.parametro.puntosParametroCategoria) * 100)
                        return porcentaje
                    }
                } else {
                    return this.parametro.avance
                }
            },
        },
        methods: {
            
        },
    }
</script>