<template>
    <v-container fluid class="pr-0 pl-0">
        <logo @cambiaAnio="getData" v-sticky="{ zIndex: 4, stickyTop: 65}" />
        <navParameters :navParams="navParams"/>
        <v-row>
            <v-col cols="12">
                <TituloPrincipal :titulo="tituloIndicador"/>
            </v-col>
        </v-row>
        <TablaEducacion @reload="getData" ref="educacion" :headers="headers" :indicadorActual="indicadorActual" :tipoActividad="actividad"/>
    </v-container>
</template>
<script>

import TablaEducacion from "@/components/parametros/EducacionAmbiental/EducacionAmbTablaComponent"
import TituloPrincipal from '@/components/utils/TituloPrincipalComponent'
import NavParameters from '@/components/utils/NavParameterComponent'
import Logo from '@/components/utils/CompanyComponent'
import VueSticky from 'vue-sticky'

export default {
    components: {
        TablaEducacion,
        TituloPrincipal,
        NavParameters,
        Logo
    },
    directives: {
        'sticky': VueSticky,
    },
    props: [
        'navParams'
    ],
    computed:{
        anioConsulta() {
            return this. $store.getters.anioConsulta;
        },
        indicadorActual() {
            return parseInt(this.$store.getters.indicadorActual);
        },
        tituloIndicador(){
            return 'Promoción de la gestión de la empresa hacia la comunidad'
        },
        actividad() {
            let constantes = this.$store.getters.constantes;
            return constantes.TIPO_ACTIVIDAD.GESTIONA_COMUNIDAD
        },
    },
    data: () => ({
        headers: [
            {
                text: 'Acciones',
                value: 'action',
                align: 'center',
                width: '10%',
                class: "educTable",
            },
            {
                text: "Tema",
                value: "temasTratados",
                align: "start",
                width: '20%',
                class: "educTable",
            },
            {
                text: "Tipo de actividad",
                value: "tipoActividad",
                align: "start",
                width: '20%',
                class: "educTable",
            },
            {
                text: "Descripción de la actividad",
                value: "descripcion",
                align: "start",
                width: '20%',
                class: "educTable",
            },
            {
                text: "Cantidad de participantes",
                value: "cantidadPersonas",
                align: "center",
                width: '10%',
                class: "educTable",
            },
            {
                text: "Fecha de la Actividad",
                value: "fechaActividad",
                align: "center",
                width: '10%',
                class: "educTable",
            },
            {
                text:"Evidencia",
                value: "evidencia",
                align: "center",
                width: '10%',
                class: "educTabla",
            }
        ],
    }),
    methods: {
        getData() {
            this.$refs.educacion.getDataPorAnio();
        },
        loadingModal(modal) {                        
            this.$store.dispatch('loadingPage', modal);
        },
    }
}
</script>