var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.data,"items-per-page":5,"no-data-text":_vm.sinDatos,"footer-props":_vm.footerProps},scopedSlots:_vm._u([{key:"item.fechaRegistro",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dateFormat(item.fechaRegistro))+" ")]}},{key:"item.documentoId",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"#0B3F67"},on:{"click":function($event){return _vm.descargarDocumento(item.documentoId)}}},on),[_vm._v("mdi-cloud-download-outline")])]}}],null,true)},[_vm._v(" Descargar ")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-row"},[_c('ImageViewer',{attrs:{"documentoId":item.documentoId}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.origen == 'educacionAmbiental' ||
                          _vm.origen == 'accionesPoS' || 
                          _vm.origen == 'tratamientoParametro' ||
                          _vm.origen == 'accionReduccion' ||
                          _vm.origen == 'estrellas' ||
                          _vm.origen == 'residuosEspeciales' ||
                          _vm.origen == 'accionAdaptacion' ||
                          _vm.origen == 'usoSuelo' ||
                          _vm.origen == 'caractQuimicas' ||
                          _vm.origen == 'fuentesAgua')?_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"color":"#0B3F67"},on:{"click":function($event){return _vm.borrarDocumento(item.documentoId)}}},on),[_vm._v(" mdi-trash-can-outline ")]):_vm._e()]}}],null,true)},[_vm._v(" Eliminar Evidencia ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
return [(_vm.origen == 'consumo')?_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"color":"#0B3F67"},on:{"click":function($event){return _vm.borrarDocumentoConsumo(item.documentoId, item.id)}}},on),[_vm._v("mdi-trash-can-outline")]):_vm._e()]}}],null,true)},[_vm._v(" Eliminar Evidencia ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
return [(_vm.origen == 'residuos' || _vm.origen == 'disposicionResiduos')?_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"color":"#0B3F67"},on:{"click":function($event){return _vm.borrarDocResiduo(item.documentoId, item.id)}}},on),[_vm._v("mdi-trash-can-outline")]):_vm._e()]}}],null,true)},[_vm._v(" Eliminar Evidencia ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
return [( _vm.origen == 'planAccion')?_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"color":"#0B3F67"},on:{"click":function($event){return _vm.borrarDocPlanAcc(item.documentoId, item.id)}}},on),[_vm._v("mdi-trash-can-outline")]):_vm._e()]}}],null,true)},[_vm._v(" Eliminar Evidencia ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
return [( _vm.origen == 'programaCompras')?_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"color":"#0B3F67"},on:{"click":function($event){return _vm.borrarEvidenciaPrograma(item.documentoId, item.id)}}},on),[_vm._v("mdi-trash-can-outline")]):_vm._e()]}}],null,true)},[_vm._v(" Eliminar Evidencia ")]),_c('ModicafDoc',{staticClass:"mr-2",attrs:{"origen":_vm.origen,"documento":item.id,"idDocumento":item.documentoId,"inst":item.instalacion},on:{"reload":function($event){return _vm.refreshTable()}}}),(_vm.origen === 'potable')?_c('eliminar-evidencia-potable',{attrs:{"evidencia":item},on:{"reload":_vm.refreshTable}}):_vm._e()],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }