<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12" sm="12" md="3">
                <AgregarGestionAguaRes @reload="getData()"/>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-card>
                    <tituloSimple titulo="Gestión de las aguas residuales"/>
                    <v-card-text>
                        <v-data-table
                            :headers="headers"
                            :items="items"
                            item-key="id"
                            :search="search">
                            <template v-slot:item.action="{ item }">

                            </template>
                            <template v-slot:item.propia="{ item }">
                                {{ formatPropia(item.propia) }}
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>                
            </v-col>
        </v-row>
    </v-container>
</template>
<script>

import axios from '@/axios.js'
import VueSticky from 'vue-sticky'
import Logo from '@/components/utils/CompanyComponent'
import TituloSimple from '@/components/utils/TituloSimpleComponent'
import NavParameters from '@/components/utils/NavParameterComponent'
import TituloPrincipal from '@/components/utils/TituloPrincipalComponent'
import AgregarGestionAguaRes from '@/components/parametros/AguasResiduales/agropecuario/AgregarGestionAguaRes.vue'

export default {
    components: {
        TituloPrincipal,
        AgregarGestionAguaRes,
        TituloSimple,
        NavParameters,
        Logo
    },
    props: [
        'navParams'
    ],
    created() {
        axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;        
    },
    mounted() {
        this.getData();
    },
    directives: {
        'sticky': VueSticky,
    },
    computed: {
        usuario() {
            return this.$store.getters.usuario;
        },
        instalacion() {
            return this.$store.getters.sucursal;
        },
        rolUsuario(){
            return this.$store.getters.usuario.rol;
        },
        anioConsulta() {
            return this. $store.getters.anioConsulta;
        },
        parametroActual() {
            return this.$store.getters.parametroActual;
        },
        indicador() {
            return parseInt(this.$store.getters.indicadorActual);
        },
    },
    data: () => ({
        headers: [
            {
                text: 'Acciones',
                value: 'action',
                align: 'center',
                width: '15%',
                sortable: false
            },
            {
                text: 'Tipo Tratamiento',
                value: 'tipo',
                align: 'left',
                width: '15%',
                sortable: false
            },
            {
                text: 'Aplica',
                value: 'propia',
                align: 'center',
                width: '20%',
                sortable: false
            },
            {
                text: 'Medidas de operación y mantenimiento implementadas',
                value: 'descripcion',
                align: 'left',
                width: '50%',
                sortable: false
            },
        ],
        items: [],
        search: null,
    }),
    methods: {
        getData() {
            axios.get(`/indicadorResiduos/getGestionAguasResiduales/${this.instalacion.id}/${this.anioConsulta}`)
            .then(response => {
                this.items = response.data;
                this.loadingModal(false);
            }).catch(error => {
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                this.loadingModal(false);
              }
            })
        },
        loadingModal(modal) {                        
            this.$store.dispatch('loadingPage', modal);
        },
        formatPropia(propia) {
            return propia ? 'SI' : 'NO'
        }
    },
}
</script>