<template>
    <v-dialog
        v-model="dialog"
        scrollable
        persistent
        max-width="500px"
        transition="dialog-transition"
    >
        <template v-slot:activator="{on:dialog}">
            <v-tooltip top>
                <template v-slot:activator="{on:tooltip}">
                    <v-btn class="mx-2" color="#0B3F67" dark v-on="{ ...dialog, ...tooltip }">Agregar<v-icon right>mdi-plus-box-outline</v-icon></v-btn>
                </template>
                Agregar Manual
            </v-tooltip>
        </template>
        <v-card :loading="loading">
            <v-card-title class="pa-0">
                <ModalTitle titulo="Agregar manual de usuario" @cancelar="cancelar"/>
            </v-card-title> 
            <v-divider></v-divider>
            <v-card-text>
                <v-select
                    :items="tipos"
                    v-model="tipoDoc"
                    item-value="id"
                    item-text="label"
                    label="Tipo de Manual"
                    :error-messages="tipoDocErrors"             
                    @change="$v.tipoDoc.$touch()"
                    @blur="$v.tipoDoc.$touch()"
                    required
                ></v-select>
                <v-file-input 
                    accept=".pdf"
                    show-size 
                    counter
                    v-model="archivos"
                    :error-messages="docErrors"
                    @change="$v.archivos.$touch()"
                    @blur="$v.archivos.$touch()"
                    :label="'Agregar Manual de usuario'">
                </v-file-input> 
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" :disabled="loading" @click="cancelar" text>Cancelar</v-btn>
                <v-btn color="#0B3F67" :loading="loading" @click="argregarEvidencias" text>Agregar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import axios from "@/axios";
import { required } from "vuelidate/lib/validators";
import ModalTitle from '@/components/utils/ModalTitleComponent'
export default {
    components: {
        ModalTitle
    },
    computed: {
      usuario(){
          return this.$store.getters.usuario;
      },
      tipoDocErrors () {
          const errors = []
          if (!this.$v.tipoDoc.$dirty) return errors            
          !this.$v.tipoDoc.required && errors.push('El Dato es requerido.')      
          return errors
      },
      docErrors () {
          const errors = []
          if (!this.$v.archivos.$dirty) return errors            
          !this.$v.archivos.required && errors.push('El archivo es requerido.')      
          return errors
      },
    },
    data: () => ({
        dialog : false,
        loading: false,
        archivos: null,
        tipoDoc: null,
        tipos: [
            {
                id: 'MANUAL_CAMBIO_CIMATICO',
                label: 'Manual de Usuario Categoria Cambio Climatico'
            },
            {
                id: 'MANUAL_CATEG_AGROPECUARIA',
                label: 'Manual de Usuario Categoria Agropecuaria'
            },
            {
                id: 'MANUAL_ADMINISTRADOR',
                label: 'Manual de Usuario del Administrador'
            },
        ]
    }),
    validations: {
        archivos: {
            required
        },
        tipoDoc: {
            required
        },
    },
    methods: {
        argregarEvidencias() {
            this.$v.$touch();
            if(!this.$v.$invalid) {
                this.loading = true;
                let formData = new FormData();
                formData.append('archivo', this.archivos);
                formData.append('usuario', this.usuario.id)
                formData.append('tipoManual', this.tipoDoc)
                axios.post('/documento/agregarManualDeUsuario', formData)
                .then(response => {
                    this.$store.dispatch('noti',{mensaje: 'Manual agregado ', color:'success'});
                    this.$emit('reload');
                    this.cancelar();
                }).catch(error => {
                  if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                  } else {
                    if (error.request && error.request.status === 415) {
                      this.$store.dispatch('noti', {mensaje: error.response.data, color: 'error'});
                      this.loading = false;
                    } else {
                      this.$store.dispatch('noti', {mensaje: 'Error en la carga del manual', color:'error'}); 
                      this.cancelar();
                    }
                  }
                });
            }
        },
        cancelar() {
            this.archivos = null;
            this.tipoDoc = null;
            this.$v.$reset();
            this.dialog = false;
            this.loading = false;
        }
    },
}
</script>