<template>
    <v-container fluid>
        <logo @cambiaAnio="getDisposicionResiduos" v-sticky="{ zIndex: 4, stickyTop: 65}" />
        <navParameters :navParams="navParams"/>
        <v-row>
            <v-col cols="12">
                <tituloPrincipal titulo="Disposición de residuos sólidos y líquidos"/>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-card>
                    <tituloSimple titulo="Residuos sólidos y líquidos"/>
                    <v-card-text>
                        <v-data-table
                            :headers="headers"
                            :items="items"
                            item-key="id"
                            :search="search">
                            <template v-slot:item.action="{ item }">
                                <v-layout justify-center>
                                    <ManejoDisposicion @reloadTable="getDisposicionResiduos" :idResiduo="item.idResiduo" :residuo="item.residuo" :anioConsulta="anioConsulta"/>
                                    <AgregarEvidencia
                                        v-if="rolUsuario != 'CEO' && rolUsuario != 'GERENTE' && rolUsuario != 'SUPERVISOR'"
                                        @reload="getDisposicionResiduos"
                                        :idActividad="item.idResiduo"
                                        :anioActual="anioConsulta"
                                        origen="disposicionResiduos"
                                        tooltipText="Agregar Evidencias"
                                        titulo="Evidencias"
                                    ></AgregarEvidencia>
                                </v-layout>

                            </template>
                            <template v-slot:item.evidencia="{ item }">
                                <ListadosDocs :id="item.idResiduo" @reload="getDisposicionResiduos"  origen="disposicionResiduos" titulo="Evidencias"></ListadosDocs>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>                
            </v-col>
        </v-row>
    </v-container>
</template>
<script>

import axios from '@/axios.js'
import VueSticky from 'vue-sticky'
import Logo from '@/components/utils/CompanyComponent'
import TituloSimple from '@/components/utils/TituloSimpleComponent'
import NavParameters from '@/components/utils/NavParameterComponent'
import TituloPrincipal from '@/components/utils/TituloPrincipalComponent'
import AgregarEvidencia from "@/components/utils/AgregarEvidenciaComponent"
import ListadosDocs from "@/components/documentos/ListadoDocumentosComponent"
import ManejoDisposicion from '@/components/parametros/ResiduosValorizables/ManejoDisposicion.vue'

export default {
    components: {
        ListadosDocs,
        AgregarEvidencia,
        TituloPrincipal,
        ManejoDisposicion,
        TituloSimple,
        NavParameters,
        Logo
    },
    props: [
        'navParams'
    ],
    created() {
        axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;        
    },
    mounted() {
        this.getDisposicionResiduos();
    },
    directives: {
        'sticky': VueSticky,
    },
    computed: {
        usuario() {
            return this.$store.getters.usuario;
        },
        instalacion() {
            return this.$store.getters.sucursal;
        },
        rolUsuario(){
            return this.$store.getters.usuario.rol;
        },
        anioConsulta() {
            return this. $store.getters.anioConsulta;
        },
        parametroActual() {
            return this.$store.getters.parametroActual;
        },
        indicador() {
            return parseInt(this.$store.getters.indicadorActual);
        },
    },
    data: () => ({
        headers: [
            {
                text: 'Acciones',
                value: 'action',
                align: 'center',
                width: '10%',
                sortable: false
            },
            {
                text: 'Residuo',
                value: 'residuo',
                align: 'center',
                width: '20%',
                sortable: false
            },
            {
                text: 'Fase Proceso',
                value: 'faseProceso',
                align: 'center',
                width: '20%',
                sortable: false
            },
            {
                text: 'Descripcion disposicion',
                value: 'disposicion',
                align: 'left',
                width: '40%',
                sortable: false
            },
            {
                text:"Evidencia",
                value: "evidencia",
                align: "center",
                width: '10%',
                class: "educTabla",
            }
        ],
        items: [],
        search: null,
    }),
    methods: {
        getDisposicionResiduos() {
            axios.get(`/indicadorResiduos/getDisposicionResiduos/${this.instalacion.id}/${this.anioConsulta}`)
            .then(response => {
                this.items = response.data;
                this.loadingModal(false);
            }).catch(error => {
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                this.loadingModal(false);
              }
            })
        },
        loadingModal(modal) {                        
            this.$store.dispatch('loadingPage', modal);
        }
    },
}
</script>