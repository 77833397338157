<template>
    <v-container fluid>
        <logo @cambiaAnio="getCaractQuimica" v-sticky="{ zIndex: 4, stickyTop: 65}" />
        <navParameters :navParams="navParams"/>
        <v-row>
            <v-col cols="12">
                <tituloPrincipal titulo="Evaluación de las características químicas y de materia orgánica del suelo"/>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" sm="12" md="3">
                <AgrgCaracQuimicas @reload="getCaractQuimica"/>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="12" md="3">

            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-card>
                    <tituloSimple titulo="Características químicas y de materia orgánica del suelo"/>
                    <v-card-text>
                        <v-data-table
                            :headers="headers"
                            :items="items"
                            item-key="id"
                            :search="search">
                            <template v-slot:item.action="{ item }">
                                <ModCaracQuimicas 
                                    :id="item.id" 
                                    @reload="getCaractQuimica"
                                    v-if="rolUsuario != 'CEO' && rolUsuario != 'GERENTE' && rolUsuario != 'SUPERVISOR'"/>
                                <AgregarEvidencia
                                    v-if="rolUsuario != 'CEO' && rolUsuario != 'GERENTE' && rolUsuario != 'SUPERVISOR'"
                                    @reload="getCaractQuimica"
                                    :id="item.id"
                                    origen="caractQuimicas"
                                    tooltipText="Agregar Evidencias"
                                    titulo="Agregar Evidencias"
                                ></AgregarEvidencia>
                                <Eliminar 
                                    v-bind:requestData="requestDataFormat(item)" 
                                    requestUrl="/manejoSuelo/elimnarEvidCaractUsoSuelo"
                                    @reload="getCaractQuimica"
                                /> 
                            </template>
                            <template v-slot:item.evidencia="{ item }">
                                <ListadosDocs :id="item.id" origen="caractQuimicas" titulo="Evidencias"></ListadosDocs>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>                
            </v-col>
        </v-row>
    </v-container>
</template>
<script>

import axios from '@/axios.js'
import VueSticky from 'vue-sticky'
import Logo from '@/components/utils/CompanyComponent'
import Eliminar from '@/components/utils/EliminarComponent'
import TituloSimple from '@/components/utils/TituloSimpleComponent'
import NavParameters from '@/components/utils/NavParameterComponent'
import TituloPrincipal from '@/components/utils/TituloPrincipalComponent'
import ListadosDocs from "@/components/documentos/ListadoDocumentosComponent"
import AgregarEvidencia from "@/components/parametros/ManejoSuelo/AgregarEvidencias"
import AgrgCaracQuimicas from '@/components/parametros/ManejoSuelo/AgrgCaracQuimicas'
import ModCaracQuimicas from '@/components/parametros/ManejoSuelo/ModCaracQuimicas'

export default {
    components: {
        Eliminar,
        ModCaracQuimicas,
        AgrgCaracQuimicas,
        AgregarEvidencia,
        TituloPrincipal,
        ListadosDocs,
        TituloSimple,
        NavParameters,
        Logo
    },
    props: [
        'navParams'
    ],
    created() {
        axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;        
    },
    mounted() {
        this.getCaractQuimica()
    },
    directives: {
        'sticky': VueSticky,
    },
    computed: {
        usuario() {
            return this.$store.getters.usuario;
        },
        instalacion() {
            return this.$store.getters.sucursal;
        },
        rolUsuario(){
            return this.$store.getters.usuario.rol;
        },
        anioConsulta() {
            return this. $store.getters.anioConsulta;
        },
        parametroActual() {
            return this.$store.getters.parametroActual;
        },
        indicador() {
            return parseInt(this.$store.getters.indicadorActual);
        },
    },
    data: () => ({
        headers: [
            {
                text: 'Acciones',
                value: 'action',
                align: 'center',
                width: '10%',
                sortable: false
            },
            {
                text: 'Resultado del análisis de suelo',
                value: 'descripcion',
                align: 'left',
                width: '80%',
                sortable: false
            },
            {
                text:"Evidencia",
                value: "evidencia",
                align: "center",
                width: '10%',
                class: "educTabla",
            }
        ],
        items: [],
        search: null,
    }),
    methods: {
        getCaractQuimica() {
            this.loadingModal(true)
            axios.get("/manejoSuelo/listarCaractQuimicas", { params: {
                instalacion: this.instalacion.id,
                anio: this.anioConsulta
            }}).then(r => {
                this.items = r.data
                this.loadingModal(false)
            }).catch(error => {
                if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
                } else {
                if (error.request && error.request.status === 415) {
                    this.$store.dispatch('noti', {mensaje: error.response.data, color: 'error'});
                    this.loadingModal(false)
                } else {
                    this.$store.dispatch('noti', {mensaje: 'Ocurrió un error no se agregó la actividad de educación', color:'error'});   
                    this.loadingModal(false)
                } 
                }        
            })
        },
        loadingModal(modal) {                        
            this.$store.dispatch('loadingPage', modal);
        },
        requestDataFormat(data) {
            let datos = new FormData()
            datos.append('caractQuimica', data.id);
            datos.append('idUsuario', this.usuario.id);
            return datos;
        }
    },
}
</script>