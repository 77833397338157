import { InformeService } from '../informe-api'
import diccionario from '@/imageDictionary.js'

export function informeXII2024(constantes, anio, anioAnterior, instalacion, organizacion, datos) {
    const informe = new InformeService(constantes)
    let primary = '#4884BF'
    let secundary = '#A1C887'
    let content = [
        {
            table: {
                widths: ['25%','50%','25%'],
                heights: [117,117,117,117,117,220],
                dontBreakRows: true,
                body: [
                    [
                        '',
                        '',
                        {
                            rowSpan: 3,
                            text: ``,
                            fontSize: 22,
                            bold: false,
                            alignment: 'center',
                            fillColor: primary,
                            color: '#ffffff',
                        },
                    ],
                    [
                        {
                            image: diccionario.PBAE_CCC,
                            alignment: 'center',
                            fit: [100, 100]
                        },
                        {
                            text: 'Programa \n Bandera Azul Ecológica',
                            fontSize: 22,
                            bold: false,
                            alignment: 'center'
                        },
                    ],
                    [
                        '',''
                    ],
                    [
                        {
                            image: diccionario.PBAE_CA,
                            alignment: 'center',
                            fit: [100, 100]
                        },
                        {
                            text: 'Categoría \n Agropecuaria',
                            fontSize: 22,
                            bold: false,
                            alignment: 'center'
                        },
                        {
                            rowSpan: 4,
                            text: `AÑO \n ${anio}`,
                            fontSize: 22,
                            bold: true,
                            alignment: 'center',
                            fillColor: primary,
                            color: '#000000',
                        }
                    ],
                    [
                        '',''
                    ],
                    [
                        '',''
                    ],
                    [
                        '',                        
                        {
                            text: 'INFORME',
                            alignment: 'center',
                            fontSize: 22,
                            bold: true,
                        }
                    ],
                ]
            },
            layout: {
                defaultBorder: false,
            },
        },
        //Fin de la Portada del informe
        {
            text: 'i. Resumen ejecutivo de la finca o la empresa.',
            alignment: 'left',
            bold: true,
            margin: [0,20,0,20],
            pageBreak: 'before'
        },
        {
            table: {
                widths: ['20%', '30%', '50%'],
                body: [
                    [
                        informe.informeLogo(datos.orgaLogo),
                        {
                            text: 'Nombre de la Organización',
                            alignment: 'center',
                        },
                        { text: datos.organizacionNombre }
                    ],
                    [
                        '',
                        { text: 'Nombre de la Sede', alignment: 'center' },
                        { text: instalacion.nombre }
                    ],
                    [
                        '',
                        { text: 'Ubicación de la Sede', alignment: 'center' },
                        { text: `${instalacion.ubicacion} ${instalacion.direccion}` }
                    ],
                    [
                        '',
                        { text: 'Cantidad de colaboradores/as', alignment: 'center' },
                        { text: instalacion.cantidadPersonal }
                    ],
                    [
                        '',
                        { text: 'Años en que ha participado', alignment: 'center' },
                        { text: instalacion.tiempoEnPrograma }
                    ],
                    [
                        '',
                        { text: 'Cantidad de estrellas ganadas', alignment: 'center' },
                        { text: datos.estrellasInforme }
                    ]
                ]
            }
        },
        {
            text: 'ii. Integrantes del Comité Interno del PBAE.',
            alignment: 'left',
            bold: true,
            margin: [0,20,0,20]
        },
        informe.tablaComiteAgrop(datos.comiteAmbiental, secundary),
        ///////////////////////////////////////////Recurso hídrico/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        {
            text: '1. Recurso hídrico.', margin: [0, 20, 0, 0],
        },
        {
            text: '1.1. Identificación de los tipos de fuentes de agua.', margin: [0, 20, 0, 20],
        },
        informe.tablaTipoFuente(datos.fuentesAgua, secundary),
        {
            text: '1.2. Evaluación de la calidad de agua y cumplimiento según su uso.', margin: [0, 20, 0, 20],
        },
        informe.tablaCalidadFuente(datos.fuentesAgua, secundary),
        {
            text: '1.3. Control del consumo de agua.', margin: [0, 20, 0, 20],
        },
        informe.tablaConsumo2022(datos.consumoAguaActual, constantes.indicadorAgua, secundary),
        {
            text: '', margin: [0, 20, 0, 0],
        },
        informe.reduccionFuenteAgua(datos.reduccionAgua, secundary),
        {
            text: '1.4. Buenas prácticas implementadas en la unidad productiva para el uso eficiente y la protección del recurso hídrico.', margin: [0, 20, 0, 20],
        },
        informe.buenasPracticas(datos.educacionAgua, secundary),
        ///////////////////////////////////////////Manejo y conservación de suelos/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        {
            text: '2. Manejo y conservación de suelos.', margin: [0, 20, 0, 0],
        },
        {
            text: '2.1. Caracterización del uso del suelo.', margin: [0, 20, 0, 20],
        },
        informe.caractUsoSuelo(datos.caracterUsoSuelo, secundary),
        {
            text: '2.2. Evaluación de las características químicas y contenido de materia orgánica del suelo.', margin: [0, 20, 0, 20],
        },
        informe.listDescripcEvidencias(datos.caracQuimicas, 'Análisis Químico', secundary),
        {
            text: '2.3. Plan para la aplicación de buenas prácticas de manejo del suelo conducentes a su uso conforme, mejoramiento y protección.', margin: [0, 20, 0, 20],
        },
        informe.buenasPracticas(datos.buenasPracticasSuelo, secundary),
        ///////////////////////////////////////////Insumos Agropecuarios/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        {
            text: '3. Insumos agropecuarios.', margin: [0, 20, 0, 0],
        },
        {
            text: '3.1. Inventario de insumos agropecuarios.', margin: [0, 20, 0, 20],
        },
        informe.inventarioInsumosAgrop(datos.insumosAgrop, secundary),
        {
            text: '3.2. Buenas prácticas para el uso y manejo de insumos agropecuarios.', margin: [0, 20, 0, 20],
        },
        informe.buenasPracticas(datos.buenasPracticasSuelo, secundary),
        {
            text: '3.3. Incorporación del uso de insumos y prácticas ambientalmente positivas en los procesos de producción agropecuaria más limpia.', margin: [0, 20, 0, 20],
        },
        informe.buenasPracticas(datos.practicasAmbbientales, secundary),
        ///////////////////////////////////////////Gestión de residuos/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        {
            text: '4. Gestión de residuos.', margin: [0, 20, 0, 0],
        },
        {
            text: '4.1. Inventario de los residuos sólidos y líquidos generados en el proceso productivo.', margin: [0, 20, 0, 20],
        },
        informe.tablaResidSoli(datos.consumoNoValolActual, secundary),
        {
            text: '', margin: [0, 20, 0, 0],
        },
        informe.gestionaAguasRes(datos.tipoSistemaTrat, secundary),
        {
            text: '4.2. Descripción de los mecanismos de disposición de los residuos sólidos y líquidos.', margin: [0, 20, 0, 20],
        },
        informe.disposicionResiduos(datos.disposicion, secundary),
        {
            text: '4.3. Plan para el manejo de residuos sólidos y líquidos.', margin: [0, 20, 0, 20],
        },
        informe.planResiduos(datos.educacionResiduos, secundary),
        ///////////////////////////////////////////Combustibles fósiles/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        {
            text: '5. Combustibles fósiles.', margin: [0, 20, 0, 0],
        },
        {
            text: '5.1. Inventario del equipo y maquinaria que consume combustible fósil.', margin: [0, 20, 0, 20],
        },
        informe.inventarioCombustiblesAgrop(datos.flotaCasoUno, secundary),
        {
            text: '5.2. Consumo de combustible fósil por tipo de maquinaria y equipo y su uso según la actividad.', margin: [0, 20, 0, 20],
        },
        informe.tablaConsumo2022(datos.consumoCombutibles, constantes.indicadorCombustibles, secundary),
        {
            text: '5.3. Acciones que promuevan el ahorro de combustibles.', margin: [0, 20, 0, 20],
        },
        informe.buenasPracticas(datos.educaCombustibles, secundary),
        ///////////////////////////////////////////Energía eléctrica/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        {
            text: '6. Energía eléctrica.', margin: [0, 20, 0, 0],
        },
        {
            text: '6.1. Registro y reporte del consumo de electricidad.', margin: [0, 20, 0, 20],
        },
        informe.tablaConsumo2022(datos.consumoEnergiaActual, constantes.indicadorEnergia, secundary),
        {
            text: '6.2. Acciones que promuevan el ahorro de electricidad.', margin: [0, 20, 0, 20],
        },
        informe.buenasPracticas(datos.educaCombustibles, secundary),
        ///////////////////////////////////////////Proyección socio-empresarial/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        {
            text: '7. Proyección socio-empresarial.', margin: [0, 20, 0, 0],
        },
        {
            text: '7.1. Promoción de la gestión de la empresa hacia la comunidad, sus proveedores de bienes y servicios y los consumidores de sus productos y servicios.', margin: [0, 20, 0, 20],
        },
        informe.accionesProyeccionEmpEvid(datos.proyComunidad, secundary),
        {
            text: '7.2. Acciones para la motivación de la participación de los colaboradores y el personal sobre el uso de las buenas prácticas agrícolas.', margin: [0, 20, 0, 20],
        },
        informe.accionesProyeccionEmpEvid(datos.proyColaborad, secundary),
        ///////////////////////////////////////////Bienestar animal/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        {
            text: '8. Bienestar animal.', margin: [0, 20, 0, 0],
        },
        {
            text: '8.1. Prácticas para dotar de condiciones para el bienestar animal.', margin: [0, 20, 0, 20],
        },
        informe.buenasPracticas(datos.educaBienestar, secundary),
        {
            text: '8.2. Programa de manejo sanitario, alimenticio y reproductivo de los animales.', margin: [0, 20, 0, 20],
        },
        informe.buenasPracticas(datos.educaReprodAnimal, secundary),
        ///////////////////////////////////////////Bienestar animal/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        {
            text: '9. Adaptación al cambio climático.', margin: [0, 20, 0, 0],
        },
        {
            text: '9.1. Descripción de eventos climáticos que afectan la finca y sus impactos en el proceso productivo.', margin: [0, 20, 0, 20],
        },
        informe.adaptacionAgrope(datos.eventosClimaticos, secundary),
        {
            text: '9.2. Prácticas para la adaptación de la finca al cambio climático.', margin: [0, 20, 0, 20],
        },
        informe.practicasAdaptacion(datos.eventosClimaticos, secundary),
    ]//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    return content;
}