<template>
    <v-dialog
        v-model="dialog"  
        persistent 
        scrollable
        max-width="550px"
        transition="dialog-transition">
        <template v-slot:activator="{ on }">
            <v-btn class="white--text" color="#0B3F67" v-on="on">Agregar<v-icon right>mdi-plus-box-outline</v-icon></v-btn>
        </template>
        <v-card :loading="loading">
            <v-card-title class="pa-0">
              <ModalTitle titulo="Agregar" @cancelar="cancelar"/>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="12" class="pt-0 pb-0">
                        <v-textarea
                            rows="3"
                            label="Descripción Croquis"
                            counter="500"
                            v-model="actividad.descripCroquis"
                            :error-messages="descErrors"             
                            @change="$v.actividad.descripCroquis.$touch()"
                            @blur="$v.actividad.descripCroquis.$touch()"
                            required
                        ></v-textarea>
                    </v-col>
                    <v-col cols="12" md="12" class="pt-0 pb-0">
                        <v-textarea
                            rows="3"
                            label="Descripción Uso de Suelo"
                            counter="500"
                            v-model="actividad.descripUsoSuelo"
                            :error-messages="descUsoErrors"             
                            @change="$v.actividad.descripUsoSuelo.$touch()"
                            @blur="$v.actividad.descripUsoSuelo.$touch()"
                            required
                        ></v-textarea>
                    </v-col>
                    <v-col cols="12" md="12" class="pt-0 pb-0">
                        <v-textarea
                            rows="3"
                            label="Descripción Topografia"
                            counter="500"
                            v-model="actividad.descripTopografia"
                            :error-messages="descTopErrors"             
                            @change="$v.actividad.descripTopografia.$touch()"
                            @blur="$v.actividad.descripTopografia.$touch()"
                            required
                        ></v-textarea>
                    </v-col>
                    <v-col cols="12" class="pt-0 pb-0">
                        <v-file-input 
                            accept=".png, .jpg, .jpeg"
                            show-size 
                            counter 
                            multiple 
                            v-model="archivos" 
                            label="Evidencias Croquis"
                            :error-messages="evidErrors"
                            @change="$v.archivos.$touch()"
                            @blur="$v.archivos.$touch()">
                        </v-file-input>
                    </v-col>
                    <v-col cols="12" class="pt-0 pb-0">
                        <v-file-input 
                            accept=".png, .jpg, .jpeg"
                            show-size 
                            counter 
                            multiple 
                            v-model="archivosUso" 
                            label="Evidencias Uso Suelo"
                            :error-messages="evidErrors"
                            @change="$v.archivosUso.$touch()"
                            @blur="$v.archivosUso.$touch()">
                        </v-file-input>
                    </v-col>
                    <v-col cols="12" class="pt-0 pb-0">
                        <v-file-input 
                            accept=".png, .jpg, .jpeg"
                            show-size 
                            counter 
                            multiple 
                            v-model="archivosTopo" 
                            label="Evidencias Topografia"
                            :error-messages="evidErrors"
                            @change="$v.archivosTopo.$touch()"
                            @blur="$v.archivosTopo.$touch()">
                        </v-file-input>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" :disabled="loading" text @click.native="cancelar">Cancelar</v-btn>
                <v-btn color="#0B3F67" :loading="loading" text @click="agregarActividad">Agregar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>

import moment from "moment"
import { mask } from "vue-the-mask"
import ModalTitle from '@/components/utils/ModalTitleComponent'
import { required, maxLength, requiredIf } from "vuelidate/lib/validators"
import axios from "@/axios.js";
//Custom validations
const maxFileSize = (files) => {
    if(files != null) {
        let maximo = files.length * 1000000;
        let total = 0;
        files.forEach(file => {
            total += file.size
        });        
        return total < maximo;
    }
    return false;
}
export default {
    directives: {
        mask,
    },
    components: {
      ModalTitle
    },
    computed: {
        fileSize() {
            return this.archivos != null ? this.archivos.length : 0;
        },
        descErrors () {
            const errors = []
            if (!this.$v.actividad.descripCroquis.$dirty) return errors
            !this.$v.actividad.descripCroquis.required && errors.push('Campo requerido')
            !this.$v.actividad.descripCroquis.maxLength && errors.push('No debe exceder el tamaño permitido.')
            return errors
        },
        descUsoErrors () {
            const errors = []
            if (!this.$v.actividad.descripUsoSuelo.$dirty) return errors
            !this.$v.actividad.descripUsoSuelo.required && errors.push('Campo requerido')
            !this.$v.actividad.descripUsoSuelo.maxLength && errors.push('No debe exceder el tamaño permitido.')
            return errors
        },
        descTopErrors () {
            const errors = []
            if (!this.$v.actividad.descripTopografia.$dirty) return errors
            !this.$v.actividad.descripTopografia.required && errors.push('Campo requerido')
            !this.$v.actividad.descripTopografia.maxLength && errors.push('No debe exceder el tamaño permitido.')
            return errors
        },
        evidErrors() {
            const errors = []
            if (!this.$v.archivos.$dirty) return errors            
            !this.$v.archivos.required && errors.push('Por favor adjuntar evidencias.')
            !this.$v.archivos.maxFileSize && errors.push('El tamaño total debe ser menor a ' + this.fileSize + 'MB.')
            return errors
        },
        evidUsoErrors() {
            const errors = []
            if (!this.$v.archivosUso.$dirty) return errors            
            !this.$v.archivosUso.required && errors.push('Por favor adjuntar evidencias.')
            !this.$v.archivosUso.maxFileSize && errors.push('El tamaño total debe ser menor a ' + this.fileSize + 'MB.')
            return errors
        },
        evidTopErrors() {
            const errors = []
            if (!this.$v.archivosTopo.$dirty) return errors            
            !this.$v.archivosTopo.required && errors.push('Por favor adjuntar evidencias.')
            !this.$v.archivosTopo.maxFileSize && errors.push('El tamaño total debe ser menor a ' + this.fileSize + 'MB.')
            return errors
        },
        indicadorActual() {
            return this.$store.getters.indicadorActual;
        },
        sucursal() {
            return this.$store.getters.sucursal;
        },
        usuario(){
            return this.$store.getters.usuario;
        },
        anioConsulta() {
            return this. $store.getters.anioConsulta;
        },
    },
    data: () => ({
        loading: false,
        dialog: false,
        actividad: {
            anio: null,
            descripCroquis: '',
            descripUsoSuelo: '',
            descripTopografia: '',
        }, 
        archivos:[],
        archivosUso:[],
        archivosTopo:[],
    }),
    validations: {
        actividad: {
            descripCroquis: {
                maxLength: maxLength(500),
                required
            },
            descripUsoSuelo:{
                maxLength: maxLength(500),
                required
            },
            descripTopografia:{
                maxLength: maxLength(500),
                required
            }
        },
        archivos: {
            required,
            maxFileSize
        },
        archivosUso: {
            required,
            maxFileSize
        },
        archivosTopo: {
            required,
            maxFileSize
        },
    },
    methods: {
        agregarActividad() {
            this.$v.$touch();
            if(!this.$v.$invalid) {
                this.loading = true;
                this.actividad.anio = this.anioConsulta
                var formData = new FormData();
                this.archivos.forEach(file => {
                    formData.append('archivos', file);
                });
                this.archivosUso.forEach(file => {
                    formData.append('archivosUso', file);
                });
                this.archivosTopo.forEach(file => {
                    formData.append('archivosTopo', file);
                });
                formData.append("caractUsoSuelo", JSON.stringify(this.actividad));
                formData.append('idInstalacion',this.sucursal.id);
                formData.append("idUsuario", this.usuario.id);
                axios.post("/manejoSuelo/agregarCaractUsoSuelo", formData).then(response => {
                    this.cancelar();
                    this.$store.dispatch('noti',{mensaje: 'Actividad de Buenas Prácticas agregada', color:'success'});   
                    this.$emit('reload');
                }).catch(error => {
                  if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                  } else {
                    if (error.request && error.request.status === 415) {
                      this.$store.dispatch('noti', {mensaje: error.response.data, color: 'error'});
                      this.loading = false;
                    } else {
                      this.$store.dispatch('noti', {mensaje: 'Ocurrió un error no se agregó la actividad de Buenas Prácticas', color:'error'});   
                      this.loading = false;  
                    } 
                  }        
                })
            }
        },
        cancelar() {
            this.actividad = {};
            this.archivos=[];
            this.archivosUso=[];
            this.archivosTopo=[];
            this.$v.$reset();  
            this.dialog = false;
            this.loading = false;
        }
    },
}
</script>