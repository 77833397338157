<template>
    <v-container fluid>     
        <logo @cambiaAnio="getConsumoPorIndicador" v-sticky="{ zIndex: 4, stickyTop: 65}" />
        <navParameters :navParams="navParams"/>
        <v-row>
            <v-col cols="12">
                <v-card color="#0B3F67">
                    <v-card-text>
                        <v-card class="pa-2">
                            <v-list-item>                            
                                <v-list-item-content>
                                    <v-list-item-title class="headline text-center">{{ titulo }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>                                                            
                        </v-card>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row >
            <v-col cols="3" md="3" lg="2" v-if="(!aguaAgropecuario) && (rolUsuario != 'CEO' && rolUsuario != 'GERENTE' && rolUsuario != 'SUPERVISOR')">
                <agregarMedidor @agregarMedidor="reloadData" :indicador="indicador" :consumoHum="consHumano" :unidadMedida="unidades"/>
            </v-col>
            <v-col v-if="!fosilAgropecuario" cols="12" sm="6" md="3" align-self="center">
                <Justificacion :botonIcono="true" tooltipText="Justificación" :reqEvidencias="true" :tipoDocumento="tipoDocumento"/>
            </v-col>
        </v-row>
        <br>
        <v-row>            
            <v-col cols="12" sm="12" md="12" lg="12" xl="12" v-for="(medidor, i) in medidores" :key="i">
                <tablaIndicador v-bind:estado="medidor.estado" :data="medidor" v-bind:anio="anioConsulta" v-bind:valorizable="valorizable"/>
                <v-divider></v-divider>
                <v-card>
                    <v-container>
                        <v-row class="pl-3" align="center">
                            <agregarConsumo v-if="rolUsuario != 'CEO' && rolUsuario != 'GERENTE' && rolUsuario != 'SUPERVISOR' && medidor.estado" @agregarHistorial="reloadData" :medidor="medidor" :consumos="mesesConConsumo(medidor.historial)" :anioConsulta="anioConsulta" :consumoAnterior="consumoAnterior"/>    
                            <modificarConsumo v-if="rolUsuario != 'CEO' && rolUsuario != 'GERENTE' && rolUsuario != 'SUPERVISOR' && medidor.estado" @modificarHistorial="reloadData" :medidor="medidor" :consumos="mesesSinConsumo(medidor.historial)" :anioConsulta="anioConsulta"/>
                            <agregarEvidencia v-if="(combustibleFosil || consumoPapel) && rolUsuario != 'CEO' && rolUsuario != 'GERENTE' && rolUsuario != 'SUPERVISOR' && medidor.estado" titulo="Adjuntar Evidencias" tooltipText="Agregar Evidencias" origen="consumo" :data="medidor" :anioActual="anioConsulta"/>
                            <ListadoDocs v-if="medidor.estado" :medidorId="medidor.id" :anioConsulta="anioConsulta" origen="consumo" :titulo="tituloDocs"/>
                            <sin-consumo @consumoCero="reloadData" v-if="combustibleFosil && medidor.estado" :indcador="indicador" :medidor="medidor"/>
                            <modificar-medidor @reload="reloadData" v-if="medidor.estado" :medidor="modMedidor(medidor)" :unidadMedida="unidades"/>
                            <activarMedidor @reload="reloadData" :estado="medidor.estado" :id="medidor.id"/>
                        </v-row>
                    </v-container>
                </v-card>
                <br>
            </v-col>
        </v-row>
        <v-row justify-center>
            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <lineChart v-if="loaded" :chart-data="chartDataMedidor" :options="options"/>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <lineChart v-if="loadedChart" :chart-data="chartDataTotal" :options="optionsTot"/>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>

import ModConsumoEquipo from '@/components/parametros/combustiblesFosiles/ModConsumoEquipo'
import ConsumoEquipo from '@/components/parametros/combustiblesFosiles/ConsumoEquipo.vue'
import SinConsumo from '@/components/parametros/combustiblesFosiles/SinConsumoComponent'
import ModificarMedidor from '@/components/indicadores/ModificarMedidorComponent'
import TablaIndicador from '@/components/indicadores/TablaIndicadorComponent'
import AgregarMedidor from "@/components/indicadores/AgregarMedidorComponent"
import LineChart from '@/components/graficos/LineChartComponent'
import AgregarConsumo from '@/components/indicadores/AgregarHistorialComponent'
import ModificarConsumo from '@/components/indicadores/ModificarConsumoComponent'
import ListadoDocs from '@/components/documentos/ListadoDocumentosComponent'
import AgregarEvidencia from'@/components/utils/AgregarEvidenciaComponent'
import NavParameters from '@/components/utils/NavParameterComponent'
import Logo from '@/components/utils/CompanyComponent'
import ActivarMedidor from '@/components/indicadores/ActivarMedidorComponent'
import Justificacion from '@/components/utils/JustificacionComponent'
import VueSticky from 'vue-sticky'
import axios from "@/axios.js"

export default {
    components: {
        ModConsumoEquipo,
        ConsumoEquipo,
        TablaIndicador,
        LineChart,
        AgregarMedidor,
        AgregarConsumo,
        ModificarConsumo,
        ListadoDocs,
        AgregarEvidencia,
        NavParameters,
        Logo,
        ActivarMedidor,
        SinConsumo,
        ModificarMedidor,
        Justificacion
    },
    directives: {
        'sticky': VueSticky,
    },
    props: [
        'navParams'
    ],
    computed: {      
        anioConsulta() {
            return this. $store.getters.anioConsulta;
        },
        indicador() {
            return this.$store.getters.indicadorActual;
        },
        combustibleFosil() {
            return this.indicador === 3 ? true : false;
        },
        consumoPapel() {
            return this.indicador === 6 ? true : false;
        },
        rolUsuario(){
            return this.$store.getters.usuario.rol;
        },
        evidencias() {
            let indicador = parseInt(this.$store.getters.indicadorActual);
            let constantes = this.$store.getters.constantes;
            let data = {};
            switch(indicador) {
                case constantes.indicadorEnergia:
                    data.tooltip = "Agregar Recibos Electricidad";
                    data.titulo = "Evidencias Consumo Electricidad";
                    break;
                case constantes.indicadorAgua:      
                    data.tooltip = "Agregar Recibos Agua";
                    data.titulo = "Evidencias Consumo Agua";
                    break;
                case constantes.indicadorCombustibles:
                    data.tooltip = "Agregar Consumo Combuistibles";
                    data.titulo = "Evidencias Consumo Combustibles";
                    break;
            }
            return data;
        },
        unidades() {
            let indicador = parseInt(this.$store.getters.indicadorActual);
            let constantes = this.$store.getters.constantes;
            let unidades = null;
            switch(indicador) {
                case constantes.indicadorEnergia:
                    unidades = this.unidadesEnergia;
                    break;
                case constantes.indicadorAgua:                                    
                    unidades = this.unidadesAgua;
                    break;
                case constantes.indicadorCombustibles:
                    unidades = this.unidadesCombustible;
                    break;
                case constantes.indicadorResiduos:
                    unidades = this.unidadesPapel;
                    break;
            }
            return unidades;
        },
        sucursal() {
            return this.$store.getters.sucursal;
        },
        titulo() {
            let titulo = null;
            switch(this.$store.getters.indicadorActual) {
                case 1:
                    titulo = "Energía Eléctrica";
                    break;
                case 2:
                    titulo = "Agua";
                    break;
                case 3:
                    titulo = "Detalle Consumo Combustibles Fósiles";
                    break;
                case 6:
                    titulo = "Detalle Consumo Papel"
                    break;
            }
            return titulo;
        },
        tituloDocs(){
            let tituloDocs = "Recibos de Medidor";
            if(this.$store.getters.indicadorActual == 6){
                tituloDocs = "Recibos de Consumo";
            }
            return tituloDocs;
        },
        tipoDocumento() {
            let indicador = parseInt(this.$store.getters.indicadorActual);
            let constantes = this.$store.getters.constantes;
            let tipoDocumento = null;
            switch(indicador) {
                case constantes.indicadorEnergia:
                    tipoDocumento = constantes.JUSTIF_CONSUMO_ELECT;
                    break;
                case constantes.indicadorAgua:                                    
                    tipoDocumento = constantes.JUSTIF_CONSUMO_AGUA;
                    break;
                case constantes.indicadorCombustibles:
                    tipoDocumento = constantes.JUSTIF_CONSUMO_COMB;
                    break;
                case constantes.indicadorResiduos:
                    tipoDocumento = constantes.JUSTIF_CONSUMO_PAPEL;
                    break;
            }
            return tipoDocumento;
        },
        fosilAgropecuario() {
            let indicador = parseInt(this.$store.getters.indicadorActual);
            let constantes = this.$store.getters.constantes;
            return this.sucursal.tipoCategoria === 2 && indicador === constantes.indicadorCombustibles
        },
        aguaAgropecuario() {
            let indicador = parseInt(this.$store.getters.indicadorActual);
            let constantes = this.$store.getters.constantes;
            return this.sucursal.tipoCategoria === 2 && indicador === constantes.indicadorAgua
        }
    },
    mounted() {      
        this.getConsumoPorIndicador();
    },
    data: () => ({
        valorizable: false,
        medidores: null,
        consHumano: null,
        loaded: false,
        loadedChart: false,           
        graficoConsumo: null,
        graficoConsumoTotal: null,
        consumoAnterior: null,
        unidadesEnergia: [
            {nombre:'KWH', valor: 1}, 
        ],
        unidadesAgua: [
            {nombre:'LITROS', valor: 2},              
            {nombre:'GALONES', valor: 3},
            {nombre:'METROS CÚBICOS', valor: 4}, 
        ],
        unidadesCombustible: [
            {nombre:'LITROS', valor: 2},              
            {nombre:'GALONES', valor: 3},            
        ],
        unidadesPapel:[
            {nombre:"RESMAS", valor: 6 }
        ],
        etiquetas: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
        chartDataMedidor: {
            labels: [],
            datasets: []
        },
        chartDataTotal: {
            labels: [],
            datasets: []
        },
        options: {
            legend: {
                position: 'top'
            },
            title: {
                display: true, 
                fontSize: 20,
                fontStyle: 'bold',
                position: 'top',
                text: 'Detalle del Consumo',
            }, 
            responsive: true,
            aspectRatio: 1,
            animation: {
                easing: 'linear',
                duration: 2000
            },
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero: true
                    }
                }],
                xAxes: [{
                    ticks: {
                        beginAtZero: true
                    }
                }]
            },
        },
        optionsTot: {
            legend: {
                position: 'top'
            },
            title: {
                display: true, 
                fontSize: 20,
                fontStyle: 'bold',
                position: 'top',
                text: 'Consumo Total',
            }, 
            responsive: true,
            aspectRatio: 1,
            animation: {
                easing: 'linear',
                duration: 2000
            },
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero: true
                    }
                }],
                xAxes: [{
                    ticks: {
                        beginAtZero: true
                    }
                }]
            },
        },
        styles: {
            height: `500px`,
        },
        showJustificacion: false
    }),
    methods: {
        getConsumoPorIndicador() {
            this.loaded = false;
            this.loadedChart = false;
            this.historialEstandar()
        },
        chartMedidorData() {
            let medidoresLocal = this.graficoConsumo;
            let dataSets = [];
            medidoresLocal.forEach(function(medidor){
                let color = this.randomColor();
                let datos = Object.values(medidor);
                datos.shift();//extrae el id del array
                let labelData = datos.shift(); //extrae el nombre del medidor
                datos.shift();//extrae el nis del array
                datos.shift();//extrae el detalle del array
                datos.shift();//Elimina la posicion 0 del array
                let dataSet = {
                    label: labelData,
                    data: datos,                    
                    borderColor: color,
                    borderWidth: 1
                };
                dataSets.push(dataSet);             
            }.bind(this))            
            this.chartDataMedidor.labels = this.etiquetas;
            this.chartDataMedidor.datasets = dataSets;
            this.loaded = true;
        },
        chartTotalData() {
            let total =  [];
            let color = this.randomColor();
            let datos = Object.values(this.graficoConsumoTotal);
            datos.shift();//extrae el id del array
            datos.shift();//extrae el detalle del array
            datos.shift();//extrae el medidor del array
            datos.shift();//extrae el nis del array
            datos.shift();//Elimina la posicion 0 del array
            let dataSet = {
                label: this.anioConsulta,
                data: datos,                
                borderColor: color,
                borderWidth: 1
            };
            total.push(dataSet);
            this.chartDataTotal.labels = this.etiquetas;
            this.chartDataTotal.datasets = total;
            this.loadedChart = true;
        },
        randomColor() {
            let r = Math.floor(Math.random() * 255);
            let g = Math.floor(Math.random() * 255);
            let b = Math.floor(Math.random() * 255);
            return "rgb(" + r + "," + g + "," + b + ")";
        },
        reloadData() {
            this.getConsumoPorIndicador();
        },
        mesesConConsumo(datos) {
          let dat = new Map(Object.entries(datos[0]));
          let mesesSinConsumo = dat.get('mesesSinConsumo');
          return mesesSinConsumo != null ? mesesSinConsumo : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
        },
        mesesSinConsumo(datos) {
          let dat = new Map(Object.entries(datos[0]));
          let mesesConConsumo = dat.get('mesesConConsumo');
          return mesesConConsumo != null ? mesesConConsumo : [];
        },
        modMedidor(medidor) {
          return Object.assign({}, medidor);
        },

        historialEstandar() {
            axios.post("/instalaciones/getHistorialConsumo", null, {params: {
                indicador: this.indicador,
                idInstalacion: this.sucursal.id,
                anio: this.anioConsulta
            }}).then(response => {
                this.medidores = response.data.medidores;
                this.graficoConsumo = response.data.graficoConsumo;
                this.graficoConsumoTotal = response.data.graficoTotal;
                this.consHumano = response.data.consHumano;
                this.consumoAnterior = response.data.consumoAnterior;
                this.showJustificacion = response.data.showJustificacion;
                this.chartMedidorData();
                this.chartTotalData();
                this.$store.dispatch('loadingPage', false);
            }).catch(error => {
                if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                } else {
                    this.$store.dispatch('loadingPage', false);
                    console.log("Error al obtener los datos de indicador: " + error);
                }
            })
        },

        historialAgropCombustibles() {
            axios.get("/indicadorCombustibles/getConsumoInventarioActivo", { params: {
                instalacion: this.sucursal.id,
                anio: this.anioConsulta
            }}).then(response => {
                this.medidores = response.data.medidores;
                this.graficoConsumo = response.data.graficoConsumo;
                this.graficoConsumoTotal = response.data.graficoTotal;
                this.consHumano = response.data.consHumano;
                this.consumoAnterior = response.data.consumoAnterior;
                this.showJustificacion = response.data.showJustificacion;
                this.chartMedidorData();
                this.chartTotalData();
                this.$store.dispatch('loadingPage', false);
            }).catch(error => {
                if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                } else {
                    this.$store.dispatch('loadingPage', false);
                    console.log("Error al obtener los datos de indicador: " + error);
                }
            })
        }
    }
}
</script>
<style scoped>
    .chartLine{
        height: '500px';
    }
</style>