<template>
    <v-dialog
        v-model="dialog"        
        persistent
        scrollable
        max-width="500px"
        transition="dialog-transition">
        <template v-slot:activator="{ on:dialog }">        
            <v-tooltip top>
                <template v-slot:activator="{ on:tooltip }">                                        
                    <v-btn large dark icon v-on="{ ...tooltip, ...dialog }"><v-icon color="#ffb605">mdi-pencil</v-icon></v-btn>
                </template>
                Modificar Consumo
            </v-tooltip>  
        </template>
        <v-card :loading="cargando">
            <v-card-title class="pa-0">
              <ModalTitle titulo="Modificar Consumo" @cancelar="cancelar"/>
            </v-card-title>          
            <v-divider></v-divider>            
            <v-card-text>
                <v-row>
                    <v-col cols="12">                      
                        <v-menu
                            v-model="menu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="290px">
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="date"
                                    label="Mes y Año del consumo"
                                    prepend-inner-icon="mdi-calendar-month"
                                    hint="Seleccione el año y mes del registro"
                                    :error-messages="dateErrors"             
                                    @change="$v.date.$touch()"
                                    @blur="$v.date.$touch()"
                                    required
                                    readonly
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="date" @change="getHistorial" :min="minDate" :max="maxDate" locale="es-CR" type="month" @input="menu = false"></v-date-picker>                            
                        </v-menu>
                        <v-text-field
                            label="Consumo"
                            v-model="historial.consumo"
                            :error-messages="consumoErrors"             
                            @change="$v.historial.consumo.$touch()"
                            @blur="$v.historial.consumo.$touch()"
                            required
                        ></v-text-field>
                        <v-text-field
                            label="Costo"
                            v-model="historial.costo"
                            :error-messages="costoErrors"             
                            @change="$v.historial.costo.$touch()"
                            @blur="$v.historial.costo.$touch()"
                            required
                        ></v-text-field>
                        <v-text-field
                            label="Eficiencia"
                            v-model="historial.indiceEficiencia"
                            hint="Ingresar dato de eficiencia relacionado e este indicador"
                            :error-messages="eficErrors"             
                            @change="$v.historial.indiceEficiencia.$touch()"
                            @blur="$v.historial.indiceEficiencia.$touch()"
                            required
                        ></v-text-field>
                        <v-file-input
                            accept=".png, .jpeg, .jpg"
                            v-if="!indicCombustible && !indicResiduos"
                            label="Adjuntar Recibo"
                            v-model="recibo"
                            show-size
                            :error-messages="recibErrors"
                            @change="$v.recibo.$touch()"
                            @blur="$v.recibo.$touch()"
                            counter>
                        </v-file-input>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text :disabled="cargando" @click="cancelar()" color="error">Cancelar</v-btn>
                <v-btn text :loading="cargando" @click="modificarHistorial()" color="#0B3F67">Modificar</v-btn>
            </v-card-actions>
        </v-card>            
    </v-dialog>
</template>
<script>
import ModalTitle from '@/components/utils/ModalTitleComponent'
import { required, decimal } from "vuelidate/lib/validators";
import axios from "@/axios.js";
const maxFileSize = (file) => {
    return file != null ? file.size < 1000000 : true
}

export default {
    props: [
        'medidor',
        'anioConsulta',
        'consumos'
    ],
    components: {
      ModalTitle
    },
    created() {
        axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;
    },     
    computed: {
        indicadorActual() {
            return parseInt(this.$store.getters.indicadorActual);
        },
        indicCombustible() {
            return this.indicadorActual === this.constantes.indicadorCombustibles ? true : false;
        },
        indicResiduos() {
            return this.indicadorActual === this.constantes.indicadorResiduos ? true : false;
        }, 
        constantes() {
            return this.$store.getters.constantes;
        },
        minDate() {
            let date = this.anioConsulta + '-' + '01';                        
            return date;
        },
        maxDate() {
            let date = this.anioConsulta + '-' + '12';                        
            return date;
        },
        consumoErrors () {
            const errors = []
            if (!this.$v.historial.consumo.$dirty) return errors
            !this.$v.historial.consumo.required && errors.push('Dato requerido')
            !this.$v.historial.consumo.decimal && errors.push('Solo ingrese númeross')
            return errors
        },
        costoErrors () {
            const errors = []
            if (!this.$v.historial.costo.$dirty) return errors
            !this.$v.historial.costo.required && errors.push('Dato requerido')
            !this.$v.historial.costo.decimal && errors.push('Solo ingrese números')
            return errors
        },
        dateErrors () {
            const errors = []
            if (!this.$v.date.$dirty) return errors
            !this.$v.date.required && errors.push('Dato requerido')
            return errors
        },
        eficErrors () {
            const errors = []
            if (!this.$v.historial.indiceEficiencia.$dirty) return errors
            !this.$v.historial.indiceEficiencia.required && errors.push('Dato requerido')
            !this.$v.historial.indiceEficiencia.decimal && errors.push('Solo ingrese números')
            return errors
        },
        recibErrors () {
            const errors = []
            if (!this.$v.recibo.$dirty) return errors
            !this.$v.recibo.maxFileSize && errors.push('El tamaño no debe exceder 1MB.')
            return errors
        },
    },
    data: () => ({
        dialog: false,
        idHistorial: null,
        historial: {
            mes: '',
            anio: '',
            consumo: '',
            costo: '',
            indiceEficiencia: '',
        },
        recibo: null,
        date: '',
        modal: false,
        menu: false,
        cargando: false
    }),
    validations: {
        historial: {
            indiceEficiencia: { required, decimal },
            consumo: { required, decimal },
            costo: { required, decimal }
        },
        recibo: { maxFileSize },
        date: { required }
    },
    methods: {
        modificarHistorial() {
            this.$v.$touch();
            if(!this.$v.$invalid) {
                this.cargando = true;
                let fecha = this.date.split("-");
                this.historial.mes = fecha[1];
                this.historial.anio = fecha[0];
                axios.put("/indicadorCombustibles/modificarConsumo", this.historial, { params: {
                    idConsumo: this.idHistorial,
                    equipo: this.medidor.id
                }})
                .then(() => {
                    this.$emit('modificar');
                    this.$store.dispatch('noti',{ mensaje: 'Consumo modificado', color:'success' }); 
                    this.cancelar();
                }).catch(error => {
                  if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                  } else {
                    if (error.request && error.request.status === 415) {
                      this.$store.dispatch('noti', {mensaje: error.response.data, color: 'error'});
                      this.loading = false;
                    } else {
                      this.$store.dispatch('noti',{mensaje: 'Ocurrió un error no se modificó el consumo', color:'error'}); 
                      this.cargando = false;
                    }
                  }
                })
            }
        },
        getHistorial() {
            this.cargando = true;
            axios.get(`/indicadorCombustibles/getConsumo`, { params: {
                equipo: this.medidor.id,
                mes: this.date.split('-')[1],
                anio: this.date.split('-')[0]
            }}
            ).then(response => {
                this.idHistorial = response.data.id
                this.historial.consumo = response.data.consumo;
                this.historial.costo = response.data.costo;
                this.historial.indiceEficiencia = response.data.indiceEficiencia;
                this.cargando = false;
            }).catch(error => {
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                console.log("Error al buscar el consumo " + error);
                this.$store.dispatch('noti',{mensaje: 'Error buscando el consumo', color:'error'}); 
                this.cargando = false;
              }
            })            
        },  
        cancelar() {
            this.historial = {};
            this.date = '';
            this.$v.historial.$reset();
            this.$v.date.$reset();
            this.cargando = false;
            this.dialog = false;
            this.$v.recibo.$reset();
        }
    },
}
</script>