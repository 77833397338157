<template>
    <v-dialog
        v-model="dialog" 
        persistent
        max-width="550px"
        transition="dialog-transition">
        <template v-slot:activator="{ on }">
            <v-btn color="#0B3F67" class="white--text" v-on="on">Agregar Pr&aacute;ctica<v-icon right>mdi-plus-box-outline</v-icon></v-btn>
        </template>
        <v-card :loading="loading">
            <v-card-title class="pa-0">
              <ModalTitle titulo="Agregar Práctica Agropecuaria" @cancelar="cancelar"/>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <v-row class="pt-3">
                    <v-col cols="12" md="12" class="pt-0 pb-0">
                        <v-select
                            :items="actividades"
                            v-model="practica.tipo"
                            :error-messages="tipoErrors"             
                            @change="$v.practica.tipo.$touch()"
                            @blur="$v.practica.tipo.$touch()"
                            counter="200"
                            required                            
                            label="Tipo de Actividad"
                        ></v-select>
                    </v-col>
                    <v-col v-if="isOtro" cols="12" md="12" class="pt-0 pb-0">
                        <v-textarea
                            rows="3"
                            label="Nombre Práctica"
                            counter="100"
                            v-model="otro"
                            :error-messages="otroErrors"             
                            @change="$v.otro.$touch()"
                            @blur="$v.otro.$touch()"
                            required
                        >
                        </v-textarea>
                    </v-col>
                    <v-col cols="12" md="12" class="pt-0 pb-0">
                        <v-textarea
                            rows="3"
                            label="Descripción"
                            counter="500"
                            v-model="practica.descripcion"
                            :error-messages="descErrors"             
                            @change="$v.practica.descripcion.$touch()"
                            @blur="$v.practica.descripcion.$touch()"
                            required
                        ></v-textarea>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" class="pt-0 pb-0">
                        <v-menu
                            v-model="menu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="290px">
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="fechaMoment"
                                    label="Fecha de la Práctica"
                                    prepend-inner-icon="mdi-calendar-month"
                                    readonly
                                    v-on="on"
                                    :error-messages="fechaErrors"
                                    @change="$v.practica.fecha.$touch()"
                                    @blur="$v.practica.fecha.$touch()"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="practica.fecha" locale="es-CR" @input="menu = false" :min="fechaMinima" :max="fechaMaxima"></v-date-picker>
                        </v-menu>
                    </v-col>
                </v-row>
                <v-file-input 
                    accept=".png, .jpeg, .jpg" 
                    show-size counter 
                    multiple 
                    v-model="archivos"
                    :error-messages="docErrors"
                    @input="$v.archivos.$touch()"
                    @blur="$v.archivos.$touch()" 
                    label="Agregar Evidencias">
                </v-file-input>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" :disabled="loading" text @click.native="cancelar">Cancelar</v-btn>
                <v-btn color="#0B3F67" :loading="loading" text @click="agregarAccion">Agregar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>

import moment from "moment"
import { required, maxLength, requiredIf } from "vuelidate/lib/validators"
import ModalTitle from '@/components/utils/ModalTitleComponent'
import axios from "@/axios.js"
//Custom validations
const maxFileSize = (files) => {
    if(files != null) {
        let maximo = files.length * 1000000;
        let total = 0;
        files.forEach(file => {
            total += file.size
        });        
        return total < maximo;
    }
    return false;
}

export default {
    created() {
        axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;
    }, 
    components: {
      ModalTitle
    },
    computed: {
        fileSize() {
            return this.archivos != null ? this.archivos.length : 0;
        },
        anioConsulta() {
            return this. $store.getters.anioConsulta;
        },
        fechaMaxima() {
            return `${this.anioConsulta}-12-31`;
        },
        fechaMinima() {
            return `${this.anioConsulta}-01-01`;
        },
        fechaMoment () {
            return this.practica.fecha ? moment(this.practica.fecha).format('DD/MM/YYYY') : '';
        },
        tipoErrors () {
            const errors = []
            if (!this.$v.practica.tipo.$dirty) return errors
            !this.$v.practica.tipo.required && errors.push('Campo requerido')
            !this.$v.practica.tipo.maxLength && errors.push('No debe exceder el tamaño permitido.')
            return errors
        },
        descErrors () {
            const errors = []
            if (!this.$v.practica.descripcion.$dirty) return errors
            !this.$v.practica.descripcion.required && errors.push('Campo requerido')
            !this.$v.practica.descripcion.maxLength && errors.push('No debe exceder el tamaño permitido.')
            return errors
        },
        docErrors () {
            const errors = []
            if (!this.$v.archivos.$dirty) return errors            
            !this.$v.archivos.required && errors.push('La evidencia es requerida.')
            !this.$v.archivos.maxFileSize && errors.push('El tamaño total debe ser menor a ' + this.fileSize + 'MB.')        
            return errors
        },
        otroErrors(){
            const errors = []
            if(this.practica.tipo === "Otro" && !this.$v.otro.$dirty) return errors
            !this.$v.otro.required && errors.push('Dato requerido')
            return errors
        }, 
        fechaErrors () {
            const errors = []
            if (!this.$v.practica.fecha.$dirty) return errors
            !this.$v.practica.fecha.required && errors.push('Campo requerido')
            return errors
        },
        indicadorActual() {
            return this.$store.getters.indicadorActual;
        },
        usuario(){
            return this.$store.getters.usuario;
        },
        instalacion() {
            return this. $store.getters.sucursal;
        },
        isOtro(){
            return this.practica.tipo === "Otro" ? true : false;
        },
    },
    data: () => ({
        dialog: false,
        loading: false,
        menu: false,
        practica: {
            tipo: '',
            descripcion: '',
            fecha: null,
        },
        archivos:[],
        actividades:[
            "Incorporación de abonos orgánicos",
            "Implementación de manejo integrado de plagas",
            "Uso de biofermentos",
            "Uso de camas biológicas para excedentes de caldos de plaguicidas",
            "Otro"
        ],
        otro : "",
    }),
    validations: {
        practica: {
            tipo: {
                maxLength: maxLength(200),
                required
            },
            descripcion: {
                maxLength: maxLength(500),
                required
            },
            fecha: {
                required
            },
        },
        archivos: {
            required,
            maxFileSize
        },
        otro: {
            required: requiredIf(function(){
                return this.practica.tipo === "Otro" ? true : false;
            })
        }
    },
    methods: {
        agregarAccion() {
            this.$v.$touch();
            if(!this.$v.$invalid) {
                this.loading = true;
                let formData = new FormData();
                this.archivos.forEach(file => {
                    formData.append('archivos', file);
                });
                formData.append("otro", this.otro);    
                formData.append('practica', this.practica.tipo);
                formData.append('descripcion', this.practica.descripcion);
                formData.append('fechaActividad', this.practica.fecha);
                formData.append('idInstalacion', this.instalacion.id);
                formData.append('idUsuario', this.usuario.id);
                axios.post("/insumoAgropecuario/agregarPracticaAgrop", formData)
                .then(response => {
                    this.$store.dispatch('noti',{mensaje: 'Práctica agropecuaria agregada', color:'success'});   
                    this.$emit('reload');
                    this.cancelar();
                }).catch(error => {
                  if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                  } else {
                    if (error.request && error.request.status === 415) {
                      this.$store.dispatch('noti', {mensaje: error.response.data, color: 'error'});
                      this.loading = false;
                    } else {
                      this.$store.dispatch('noti', {mensaje: 'Ocurrió un error no se agregó la práctica agropecuaria', color:'error'});  
                      this.cancelar();   
                    }
                  }         
                })
            }
        },
        cancelar() {
            this.practica = {
                tipo: '',
                descripcion: '',
                cantidad: '',
                fecha: null,
            };
            this.archivos=[];
            this.otro = "";
            this.$v.practica.$reset();    
            this.$v.archivos.$reset();
            this.$v.otro.$reset();   
            this.dialog = false;
            this.loading = false;
        }
    },
}
</script>