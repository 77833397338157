<template>
    <v-container fluid>
        <v-card>           
            <v-card-text>
                <v-list-item two-line>
                    <v-list-item-content>
                        <v-list-item-title class="headline mb-1 text-lg-left">Parámetros de Cumplimiento</v-list-item-title>                        
                    </v-list-item-content>
                    <v-list-item-content>
                        <v-list-item-title class="headline mb-1 text-end">Porcentaje de Avance</v-list-item-title>                        
                    </v-list-item-content>
                    <v-list-item-content> 
                        <v-list-item-title class="headline mb-1 text-md-right">Puntaje</v-list-item-title>                        
                    </v-list-item-content> 
                </v-list-item>                  
                <v-expansion-panels popout multiple v-if="!alert">
                    <v-expansion-panel :disabled="totalItems(indicador.nombre)" v-for="indicador in indicadores" :key="indicador.nombre" expand focusable>
                        <v-expansion-panel-header>
                            <v-row>
                                <v-col cols="8" md="6">
                                    <span class="font-weight-bold subtitle-1">{{ indicador.nombre }}</span>
                                </v-col>
                                <v-col cols="6" md="3">
                                    <progreso :avance="indicador.avance"/>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-divider></v-divider>
                        <v-expansion-panel-content>
                            <v-simple-table>
                                <tbody>
                                    <tr v-for="item in indicador.items" :key="item.nombreParametroCategoria" v-on:click="cargPagina(item, indicador.items)">
                                        <td class="detail">{{ item.nombreParametroCategoria }}</td>
                                        <ProgresoParametro v-if="item.puntosParametroCategoria > 0" :parametro="item" :anioConsulta="anioConsulta" />
                                        <td class="text-md-right"> {{ item.puntosParametroCategoria }} </td>
                                    </tr>
                                </tbody>
                            </v-simple-table>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
                <v-col cols="12" md="12" align-self="center" v-if="alert">
                    <v-alert type="error"  align-center justify-center>Debe de realizar la inscripción del periodo</v-alert>
                </v-col>
            </v-card-text>         
        </v-card>
    </v-container>
</template>
<script>

import Progreso from '@/components/utils/BarraProgresoComponent'
import ProgresoParametro from '@/components/utils/ProgresoParametroComponent.vue';

export default {
    components: {
        Progreso,
        ProgresoParametro
    },
    computed: {
        sucursal() {
            return this.$store.getters.sucursal;
        },
        anioConsulta() {
            return this.$store.getters.anioConsulta;
        }
    },
    props: [
        'indicadores',
        'alert',,
        'navParams'
    ],
    methods: {
        totalItems(nombre) {
            return nombre === 'Total' ? true : false;
        },
        cargPagina(parametro, navParams) {
            this.setNavParams(parametro.indicador.id);
            this.$store.commit('indicadorActual', parametro.indicador.id);
            this.$store.dispatch('loadingPage', true);
            this.$store.commit('parametroActual', parametro.id)
            this.$store.commit('navParams', navParams)
            this.$router.push({name: parametro.path, params: {indicador: parametro.indicador, navParams: navParams}});
        },
        setNavParams(indicador) {
            let parametros = this.navParams.filter(param => param.indicador.id === indicador);
            this.$store.commit('navParams', []);
            this.$store.commit('navParams', parametros);
        },
    },
}
</script>
<style scoped>
    .detail{
        width: 50%;
    }
    .progressBar{
        width: 30%;
    }
    td{
        cursor: pointer;
    }
</style>