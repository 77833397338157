<template>
    <v-container fluid>
        <logo @cambiaAnio="getData" v-sticky="{ zIndex: 4, stickyTop: 65}" />
        <navParameters :navParams="navParams"/>
        <v-row>
            <v-col cols="12">
                <tituloPrincipal titulo="Inventario de insumos agropecuarios"/>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <AgregarInsumo :anioConsulta="anioConsulta" @reload="getData"/>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-card>
                    <tituloSimple titulo="Inventario de insumos agropecuarios"/>
                    <v-card-text>
                        <v-data-table
                            :headers="headers"
                            :items="items"
                            item-key="id"
                            :search="search">
                            <template v-slot:item.action="{ item }">
                                <ModificarInsumo :idInsumo="item.id" @reload="getData"/>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>   
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

import axios from "@/axios"
import VueSticky from 'vue-sticky'
import Logo from '@/components/utils/CompanyComponent'
import TituloSimple from '@/components/utils/TituloSimpleComponent'
import NavParameters from "@/components/utils/NavParameterComponent"
import TituloPrincipal from "@/components/utils/TituloPrincipalComponent"
import AgregarInsumo from "@/components/parametros/InsumosAgropecuarios/AgregarInsumo"
import ModificarInsumo from '@/components/parametros/InsumosAgropecuarios/ModificarInsumo'

export default {
    components: {
        ModificarInsumo,
        AgregarInsumo,
        TituloPrincipal,
        TituloSimple,
        NavParameters,
        Logo
    },
    directives: {
        'sticky': VueSticky,
    },
    props: [
        'navParams'
    ],
    mounted() {
        this.getData()
    },
    created() {
        axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;
    },
    data: () => ({
        loading: false,
        search: null,        
        items: [],                     
        headers: [
            {
                text: 'Acciones',
                value: 'action',
                align: 'center',
                width: '10%',
                sortable: false
            },
            {
                text: 'Nombre',
                value: 'nombre',
                align: 'left',
                width: '20%',
            },
            {
                text: 'Toxicidad',
                value: 'toxicidad',
                align: 'left',
                width: '20%',
            },
            {
                text: 'Uso',
                value: 'uso',
                align: 'left',
                width: '20%',
            },
            {
                text:"Ingrediente Activo",
                value: "ingredienteActivo",
                align: "center",
                width: '20%',
                class: "educTabla",
            },
            {
                text:"Cantidad Utiliaza",
                value: "cantidad",
                align: "center",
                width: '20%',
                class: "educTabla",
            }
        ],
    }),
    computed: {
        sucursal() {
            return this.$store.getters.sucursal;
        },
        anioConsulta() {
            return this.$store.getters.anioConsulta;
        },
        usuario(){
            return this.$store.getters.usuario;
        },
    },
    methods: {
        getData() {
            this.loadingModal(true)
            axios.get("/insumoAgropecuario/listarInsumos", { params: {
                instal: this.sucursal.id,
                anio: this.anioConsulta
            }}).then(r => {
                this.items = r.data
                this.loadingModal(false)
            }).catch(error => {
                this.loadingModal(false)
                if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                    } else {
                    if (error.request && error.request.status === 415) {
                        this.$store.dispatch('noti', {mensaje: error.response.data, color: 'error'});
                        this.loading = false;
                    } else {
                        this.$store.dispatch('noti', {mensaje: 'Ocurrió un error no se agregó el insumo', color:'error'});   
                        this.loading = false;  
                    } 
                }        
            })
        },
        activar(id) {
            this.loading = true;
            axios.put("/insumoAgropecuario/activarInsumo", null, { params: {
                insumo: id,
                usuario: this.usuario.id,
                anio: this.anioConsulta
            }}).then(response => {
                this.loading = false;
                this.getData()
                this.$store.dispatch('noti',{mensaje: `Insumo agregado al año ${this.anioConsulta}` , color:'success'});
            }).catch(error => {
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                console.log(error.response);
                this.$store.dispatch('noti', {mensaje: 'Ocurrió un error no se modificó el insumo', color:'error'});
                this.loading = false;
              }
            })
        },
        loadingModal(modal) {                        
            this.$store.dispatch('loadingPage', modal);
        },
    }
}
</script>