<template>
    <v-dialog
        v-model="dialog"  
        persistent 
        scrollable
        max-width="550px"
        transition="dialog-transition">
        <template v-slot:activator="{ on }">
            <v-tooltip bottom>
                <template v-slot:activator="{ on: tooltip }">
                <v-icon
                    small
                    exact-active-class="indigo"
                    class="ma-2"
                    v-on="{ ...tooltip, ...on }"
                    @click="getDataBuenasPrac()"
                >mdi-pencil</v-icon>
                </template>
                <span>Modificar</span>
            </v-tooltip>
        </template>
        <v-card :loading="loading">
            <v-card-title class="pa-0">
              <ModalTitle titulo="Modificar Actividad" @cancelar="cancelar"/>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="12" class="pb-0">
                        <v-select v-if="indicadorActual == 6"
                            :items="residuos"
                            v-model="actividad.opcionalTres"
                            item-text="residuo"
                            item-value="id"
                            :error-messages="resErrors"             
                            @change="$v.actividad.opcionalTres.$touch()"
                            @blur="$v.actividad.opcionalTres.$touch()"
                            required                            
                            label="Tipo de Residuo"
                        ></v-select>
                    </v-col>
                    <v-col cols="12" md="12" class="pb-0">
                        <v-select
                            :items="actividadesList"
                            v-model="actividad.tipoActividad"
                            :error-messages="tipoErrors"             
                            @change="$v.actividad.tipoActividad.$touch()"
                            @blur="$v.actividad.tipoActividad.$touch()"
                            required                            
                            :label="pracLabel"
                        ></v-select>
                    </v-col>
                    <v-col v-if="isOtro" cols="12" md="12" class="pt-0 pb-0">
                        <v-textarea
                            rows="3"
                            label="Nombre Tipo Práctica"
                            counter="100"
                            v-model="otro"
                            :error-messages="otroErrors"             
                            @change="$v.otro.$touch()"
                            @blur="$v.otro.$touch()"
                            required
                        >
                        </v-textarea>
                    </v-col>
                    <v-col cols="12" md="12" class="pt-0 pb-0">
                        <v-textarea
                            rows="3"
                            :label="labelDescr"
                            counter="500"
                            v-model="actividad.descripcion"
                            :error-messages="descErrors"             
                            @change="$v.actividad.descripcion.$touch()"
                            @blur="$v.actividad.descripcion.$touch()"
                            required
                        ></v-textarea>
                    </v-col>
                    <v-col cols="12" md="12" class="pt-0 pb-0">
                        <v-textarea
                            rows="3"
                            :label="labelDescrOpt"
                            counter="500"
                            v-model="actividad.temasTratados"
                            :error-messages="temasErrors"             
                            @change="$v.actividad.temasTratados.$touch()"
                            @blur="$v.actividad.temasTratados.$touch()"
                            required
                        ></v-textarea>
                    </v-col>
                    <v-col v-if="indicadorActual == 14" cols="12" md="12" class="pt-0 pb-0">
                        <v-text-field
                            label="Cantidad de participantes"
                            v-model="actividad.cantidadPersonas"
                            v-mask="mask"
                            hint="Ingresar solo números"
                            :error-messages="cantErrors"             
                            @change="$v.actividad.cantidadPersonas.$touch()"
                            @blur="$v.actividad.cantidadPersonas.$touch()"
                            required
                        ></v-text-field>
                    </v-col>
                    <v-col v-if="indicadorActual == 6" cols="12" md="12" class="pt-0 pb-0">
                        <v-textarea
                            rows="3"
                            label="Periodo de ejecución"
                            counter="500"
                            v-model="actividad.opcionalUno"
                            :error-messages="optUnoErrors"             
                            @change="$v.actividad.opcionalUno.$touch()"
                            @blur="$v.actividad.opcionalUno.$touch()"
                            required
                        ></v-textarea>
                    </v-col>
                    <v-col v-if="indicadorActual == 6" cols="12" md="12" class="pt-0 pb-0">
                        <v-text-field
                            label="Responsable"
                            counter="100"
                            v-model="actividad.opcionalDos"
                            :error-messages="optDosErrors"             
                            @change="$v.actividad.opcionalDos.$touch()"
                            @blur="$v.actividad.opcionalDos.$touch()"
                            required
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="pt-0 pb-0">
                        <v-menu
                            v-model="menu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="290px">
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="fechaMoment"
                                    label="Fecha de Implemetación"
                                    prepend-inner-icon="mdi-calendar-month"
                                    readonly
                                    v-on="on"
                                    :error-messages="fechaErrors"
                                    @change="$v.fechaActividad.$touch()"
                                    @blur="$v.fechaActividad.$touch()"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="fechaActividad" locale="es-CR" @input="menu = false" :min="fechaMinima" :max="fechaMaxima"></v-date-picker>
                        </v-menu>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" :disabled="loading" text @click.native="cancelar">Cancelar</v-btn>
                <v-btn color="#0B3F67" :loading="loading" text @click="modificarActividad">Modificar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>

import moment from "moment"
import { mask } from "vue-the-mask"
import ModalTitle from '@/components/utils/ModalTitleComponent'
import { required, maxLength, requiredIf } from "vuelidate/lib/validators"
import axios from "@/axios.js";

export default {
    directives: {
        mask,
    },
    components: {
      ModalTitle
    },
    props: [
        'id'
    ],
    computed: {
        fileSize() {
            return this.archivos != null ? this.archivos.length : 0;
        },
        fechaMoment () {
            return this.fechaActividad ? moment(this.fechaActividad).format('DD/MM/YYYY') : '';
        },
        tipoErrors () {
            const errors = []
            if (!this.$v.actividad.tipoActividad.$dirty) return errors
            !this.$v.actividad.tipoActividad.required && errors.push('Campo requerido')
            return errors
        },
        descErrors () {
            const errors = []
            if (!this.$v.actividad.descripcion.$dirty) return errors
            !this.$v.actividad.descripcion.required && errors.push('Campo requerido')
            !this.$v.actividad.descripcion.maxLength && errors.push('No debe exceder el tamaño permitido.')
            return errors
        },
        otroErrors(){
            const errors = []
            if(!this.$v.otro.$dirty) return errors
            !this.$v.otro.required && errors.push('Dato requerido')
            return errors
        },

        evidErrors() {
            const errors = []
            if (!this.$v.archivos.$dirty) return errors            
            !this.$v.archivos.required && errors.push('Por favor adjuntar evidencias.')
            !this.$v.archivos.maxFileSize && errors.push('El tamaño total debe ser menor a ' + this.fileSize + 'MB.')
            return errors
        },
        fechaErrors () {
            const errors = []
            if (!this.$v.fechaActividad.$dirty) return errors
            !this.$v.fechaActividad.required && errors.push('Campo requerido')
            return errors
        },
        indicadorActual() {
            return this.$store.getters.indicadorActual;
        },
        sucursal() {
            return this.$store.getters.sucursal;
        },
        usuario(){
            return this.$store.getters.usuario;
        },
        temasErrors () {
            const errors = []
            if (!this.$v.actividad.temasTratados.$dirty) return errors
            !this.$v.actividad.temasTratados.required && errors.push('Dato requerido')
            !this.$v.actividad.temasTratados.maxLength && errors.push('No debe exceder el tamaño permitido.')
            return errors
        },
        resErrors () {
            const errors = []
            if (!this.$v.actividad.opcionalTres.$dirty) return errors
            !this.$v.actividad.opcionalTres.required && errors.push('Dato requerido')
            return errors
        },
        optUnoErrors () {
            const errors = []
            if (!this.$v.actividad.opcionalUno.$dirty) return errors
            !this.$v.actividad.opcionalUno.required && errors.push('Dato requerido')
            !this.$v.actividad.opcionalUno.maxLength && errors.push('No debe exceder el tamaño permitido.')
            return errors
        },
        optDosErrors () {
            const errors = []
            if (!this.$v.actividad.opcionalDos.$dirty) return errors
            !this.$v.actividad.opcionalDos.required && errors.push('Dato requerido')
            !this.$v.actividad.opcionalDos.maxLength && errors.push('No debe exceder el tamaño permitido.')
            return errors
        },
        cantErrors () {
            const errors = []
            if (!this.$v.actividad.cantidadPersonas.$dirty) return errors
            !this.$v.actividad.cantidadPersonas.required && errors.push('Dato requerido')
            return errors
        },
        isOtro(){
            return this.actividad.tipoActividad === "Otro" ? true : false;
        },
        anioConsulta() {
            return this. $store.getters.anioConsulta;
        },
        labelEvid() {
            return this.anioConsulta > 2021 ? 'Evidencias de temas tratados' : 'Agregar Evidencias';
        },
        fechaMaxima() {
            return `${this.anioConsulta}-12-31`;
        },
        fechaMinima() {
            return `${this.anioConsulta}-01-01`;
        },
        actividadesList() {
            switch (this.indicadorActual) {
                case 1:
                    return this.actividadesEnergia
                case 2:
                    return this.actividadesAgua
                case 3:
                    return this.actividadesCombustibles
                case 6:
                    return this.actividadesResiduos
                case 12:
                    return this.actividadesSuelo
                case 13:
                    return this.actividadesInsumosAgr
                case 14:
                    if(this.actividad.tipo === "GESTIONA_COMUNIDAD") {
                        return this.actividadesComunidad
                    } else {
                        return this.actividadesColaboradores
                    }
                case 15:
                    return this.actividadesBienestarAnimal
                default:
                    break;
            }
        },
        labelDescr() {
            switch (this.indicadorActual) {
                case 6:
                    return 'Acciones a realizar'
                default:
                    return 'Descripción'
            }   
        },
        labelDescrOpt() {
            switch (this.indicadorActual) {
                case 6:
                    return 'Meta'
                case 14:
                    return 'Tema'
                default:
                    return 'Descripción adicional (opcional)'
            }
        },
        pracLabel() {
            switch (this.indicadorActual) {
                case 14:
                    return 'Tipo de Actividad'
                default:
                    return 'Tipo de Práctica'
            }
        }
    },
    data: () => ({
        loading: false,
        dialog: false,
        menu: false,
        mask: '####',
        residuos: [],
        actividad: {
            tipoActividad: '',
            descripcion: '',
            indicador: '',
            temasTratados: '',
            opcionalUno: '',
            opcionalDos: '',
            opcionalTres: '',
            cantidadPersonas: null
        }, 
        archivos:[],
        fechaActividad: null,
        actividadesAgua:[
            "Protección de orillas de quebradas, riachuelos y ríos del ingreso de animales mediante el uso de cercas vivas.",
            "Implementar el riego por goteo.",
            "Cosecha y utilización de agua de lluvia",
            "Reforestación",
            "Establecer zonas de protección de las fuentes de agua subterránea existentes en el sitio de producción agropecuaria",
            "Lavar los equipos utilizados para aplicaciones de plaguicidas y bio-insumos y descartar las aguas de lavado en un sitio seguro.",
            "Realizar prácticas para evitar la contaminación del agua con heces de animales o humanas",
            "Reducir el consumo de agua usando pistolas de cierre automático, reducir el diámetro de las mangueras o utilizar hidrolavadoras o máquinas a presión de bajo consumo",
            "Participar en talleres de buenas prácticas agropecuarias realizadas por el MAG",
            "Otro"
        ],
        actividadesSuelo:[
            "Incorporación de rastrojos",
            "Reutilización de residuos de chapias y podas",
            "Rotación de cultivos",
            "Cultivos asociados",
            "Sistemas agroforestales",
            "Laboreo del suelo sin volteo",
            "Siembra en contorno",
            "Barreras vivas para división de potreros, linderos, bordes de carreteras y caminos",
            "Canales de desviación de agua",
            "Canales de infiltración",
            "Terrazas",
            "Empalizadas",
            "Fertilización orgánica de los pastos y forrajes",
            "Aislamiento y estabilización de cárcavas",
            "Cultivo de especies arbóreas de forma integrada con la ganadería",
            "División y rotación de potreros para mejorar la calidad del pasto e incrementar la capacidad de carga",
            "Otro"
        ],
        actividadesInsumosAgr:[
            "Sustitución de productos tóxicos",
            "Sustitución de materiales o insumos por otros con mayor capacidad de biodegradación",
            "Instalaciones adecuadas para almacenar plaguicidas según la normativa",
            "Almacenamiento correcto de insumos",
            "Implementación de protocolos de seguridad para los trabajadores expuestos a plaguicidas",
            "Justificación técnica para el empleo de los diferentes insumos",
            "Otro"
        ],
        actividadesResiduos: [
            'abricación y uso de abonos orgánicos para la fertilización de los diferentes cultivos',
            'Implementación de biojardineras',
            'Tratamiento de excretas para la obtención de gas mediante biodigestores para utilizarlo como fuente de energía en los procesos productivos',
            'Generar energía a partir de biomasa',
            'Innovación en pro de la reutilización de los residuos',
            'Recoger los residuos en seco antes de lavado de las instalaciones',
            'Recoger del campo los envases de agroquímicos y demás insumos agropecuarios y colocarlos en contenedores apropiados, donde no representen peligro para la producción ni el medio ambiente'
        ],
        actividadesCombustibles: [
            'Uso de biocombustibles',
            'Mantenimiento adecuado a la flota vehicular',
            'Mantenimiento adecuado y uso eficiente de la maquinaria agrícola y flota vehicular',
            'Emplear técnicas de secado natural de los productos',
            'Proyectos de aprovechamiento de energía solar',
            'Aprovechamiento del gas generado por biodigestores',
        ],
        actividadesEnergia: [
            'Uso de calentadores solares',
            'Iluminación mediante sistemas fotovoltaicos',
            'Generación de energía a partir de biomasa',
            'En los espacios que requieren un mayor nivel de iluminación y donde los periodos con la luz encendida son más largos, instalar lámparas de bajo consumo como fluorescentes compactas',
            'En ganadería, realizar el mantenimiento preventivo correcto a la maquinaria de ordeño y la obra civil',
            'Implementar técnicas de climatización pasiva en los establos y granjas para reducir el consumo de energía por iluminación y ventilación',
        ],
        actividadesColaboradores: [
            'Capacitación a los colaboradores en buenas prácticas agrícolas y temas de sensibilización ambiental',
            'Actividades internas para fortalecer la cooperación de los colaboradores con los objetivos del Programa Bandera Azul Ecológica',
            'Implementación de huertas orgánicas para el beneficio de todos los colaboradores',
            'Oportunidad de capacitación externa en temas de agricultura orgánica, elaboración de bio-insumos, buenas prácticas agrícolas entre otros',
            "Otro"
        ],
        actividadesComunidad: [
            'Campañas de educación',
            'Campañas de recolección de residuos en la comunidad',
            'Participación en ferias ambientales',
            'Recorridos guiados por las instalaciones para mostrar el trabajo realizado en materia ambiental',
            'Campañas de reforestación',
            "Otro"
        ],
        actividadesBienestarAnimal: [
            'Árboles que brinden sombra para el resguardo del ganado en días calurosos',
            'Dotar de agua fresca a los animales',
            'Instalaciones acordes con el número de animales para evitar hacinamiento',
            'Instalaciones limpias',
            'Sistemas de pastoreo de aves',
            'Condiciones de transporte de los animales',
            'Prácticas empleadas para el sacrificio',
            "Otro"
        ],
        otro : ""
    }),
    validations: {
        actividad: {
            tipoActividad: {
                required
            },
            descripcion: {
                maxLength: maxLength(500),
                required
            },
            temasTratados:{
                required: requiredIf(function(){
                    return (this.indicadorActual == 6 || this.indicadorActual == 14)
                }),
                maxLength: maxLength(500)
            },
            opcionalUno:{
                required: requiredIf(function(){
                    return this.indicadorActual == 6
                }),
                maxLength: maxLength(500)
            },
            opcionalDos:{
                required: requiredIf(function(){
                    return this.indicadorActual == 6
                }),
                maxLength: maxLength(500)
            },
            opcionalTres:{
                required: requiredIf(function(){
                    return this.indicadorActual == 6
                })
            },
            cantidadPersonas: {
                required: requiredIf(function(){
                    return this.indicadorActual == 14
                })
            }
        },
        otro: {
            required: requiredIf(function(){
                return this.actividad.tipoActividad === "Otro" ? true : false;
            })
        },
        fechaActividad: {
            required
        }
    },
    methods: {
        getDataBuenasPrac() {
            this.loading = true
            axios.get("/educacionAmbiental/getDataModificar/" + this.id).then(response => {
                this.actividad = response.data.actividad;
                this.actividad.modalidad = this.actividad.modalidad.id;
                this.participantes = response.data.participantes;
                this.listParticipantes = response.data.participantesAct;
                this.modalidades = response.data.modalidades;
                this.residuos = response.data.residuosDisp;
                if(this.actividadesList.includes(response.data.actividad.tipoActividad)){
                    this.actividad.tipoActividad = response.data.actividad.tipoActividad;
                }else{
                    this.otro = response.data.actividad.tipoActividad;
                    this.actividad.tipoActividad = "Otro";
                }
                this.fechaActividad = this.dateFormat(response.data.actividad.fechaActividad);
                this.loading = false;
            })
            .catch(error => {
                console.log(error);
                this.loading = false;
            });
        },
        modificarActividad() {
            this.$v.$touch();
            if(!this.$v.$invalid) {
                this.loading = true;
                this.actividad.indicador = this.indicadorActual;
                delete this.actividad.docEvidencia
                axios.put("/educacionAmbiental/modificarActividad", this.actividad, { params: {
                    fechaActividad: this.fechaActividad,
                    idUsuario: this.usuario.id,
                    otro: this.otro
                }}).then(() => {
                    this.cancelar();
                    this.$store.dispatch('noti',{mensaje: 'Actividad de Buenas Prácticas agregada', color:'success'});   
                    this.$emit('reload');
                }).catch(error => {
                  if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                  } else {
                    if (error.request && error.request.status === 415) {
                      this.$store.dispatch('noti', {mensaje: error.response.data, color: 'error'});
                      this.loading = false;
                    } else {
                      this.$store.dispatch('noti', {mensaje: 'Ocurrió un error no se agregó la actividad de educación', color:'error'});   
                      this.loading = false;  
                    } 
                  }        
                })
            }
        },
        dateFormat(fechaActividad) {
            return new Date(fechaActividad).toISOString().substr(0, 10);
        },
        cancelar() {
            this.actividad = {};
            this.fechaActividad = null
            this.archivos=[];
            this.otro = "";
            this.$v.$reset();  
            this.dialog = false;
            this.loading = false;
        }
    },
}
</script>