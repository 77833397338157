<template>
    <v-dialog
        v-model="dialog"  
        persistent 
        max-width="500px"
        transition="dialog-transition">
        <template v-slot:activator="{ on:dialog }">
            <v-tooltip top>
                <template v-slot:activator="{ on:tooltip }">
                    <v-icon v-on="{ ...tooltip, ...dialog }" color="#0B3F67">mdi-cloud-upload</v-icon>                    
                </template>
                {{ tooltipText }}
            </v-tooltip>
        </template>
        <v-card :loading="loading">
            <v-card-title class="pa-0">
              <ModalTitle :titulo="titulo" @cancelar="cancelar"/>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-col cols="12" class="pt-0 pb-0" v-if="anioConsulta > 2021 && (origen === 'educacionAmbiental')">
                <v-file-input 
                    accept=".png, .jpg, .jpeg"
                    show-size 
                    counter  
                    v-model="archivoAct" 
                    label="Fotografias de la Actividad"
                    :error-messages="evidActErrors"
                    @change="$v.archivoAct.$touch()"
                    @blur="$v.archivoAct.$touch()">
                </v-file-input>
              </v-col>
              <v-col cols="12" class="pt-0 pb-0" v-if="anioConsulta > 2021 && origen === 'educacionAmbiental'">
                <v-file-input 
                    accept=".png, .jpg, .jpeg"
                    show-size 
                    counter  
                    v-model="archivoPart" 
                    label="Certificación de participación o Lista de asistencia"
                    :error-messages="evidPartErrors"
                    @change="$v.archivoPart.$touch()"
                    @blur="$v.archivoPart.$touch()">
                </v-file-input>
              </v-col>
              <v-col cols="12" class="pt-0 pb-0">
                <v-file-input 
                    accept=".png, .jpeg, .jpg"
                    show-size 
                    counter 
                    multiple 
                    v-model="archivos"
                    :error-messages="docErrors"
                    @change="$v.archivos.$touch()"
                    @blur="$v.archivos.$touch()"
                    :label="label">
                </v-file-input>  
              </v-col>
              <v-col cols="12" class="pt-0 pb-0" v-if="anioConsulta > 2023 && origen === 'actividadCompesacion'">
                <v-file-input 
                    accept=".png, .jpeg, .jpg"
                    show-size 
                    counter 
                    multiple 
                    v-model="archivoDisp"
                    :error-messages="archivoDispErrors"
                    @change="$v.archivoDisp.$touch()"
                    @blur="$v.archivoDisp.$touch()"
                    label="Evidencias Disposición Residuos">
                </v-file-input>  
              </v-col>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" :disabled="loading" @click="cancelar" text>Cancelar</v-btn>
                <v-btn color="#0B3F67" :loading="loading" @click="argregarEvidencias" text>Agregar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import ModalTitle from '@/components/utils/ModalTitleComponent'
import axios from "@/axios.js";
//Custom validations
const maxFileSize = (files) => {
    if(files != null) {
        let maximo = files.length * 1000000;
        let total = 0;
        files.forEach(file => {
            total += file.size
        });        
        return total <= maximo;
    }
    return true;
}
const maxSingleFileSize = (file) => {
    if(file != null) {
        let maximo = 1024 * 1024;
        return file.size <= maximo;
    }
    return true;
}
export default {
    created() {
        axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;
    },  
    components: {
      ModalTitle
    },
    props: [
        'titulo',
        'tooltipText',
        'tituloBtn',
        'anioActual',
        'origen',
        'icon',
        'data', //por si hace falta enviar datos adicionales
        'idActividad', //esto para el caso de educacion ambiental
        'tipo'
    ],
    computed: {
        fileSize() {
            return this.archivos != null ? this.archivos.length : 0;
        },
        fileActSize() {
            return this.archivoAct != null ? this.archivoAct.length : 0;
        },
        filePartSize() {
            return this.archivoPart != null ? this.archivoPart.length : 0;
        },
        fileDispSize() {
            return this.archivoDisp != null ? this.archivoDisp.length : 0;
        },
        sucursal() {
            return this.$store.getters.sucursal;
        },
        indicadorActual() {
            return this.$store.getters.indicadorActual;
        },
        usuario(){
            return this.$store.getters.usuario;
        },
        docErrors () {
            const errors = []
            if (!this.$v.archivos.$dirty) return errors            
            !this.$v.archivos.maxFileSize && errors.push('El tamaño total debe ser menor a ' + this.fileSize + ' MB.')        
            return errors
        },
        anioConsulta() {
            return this. $store.getters.anioConsulta;
        },
        evidActErrors() {
            const errors = []
            if (!this.$v.archivoAct.$dirty) return errors            
            !this.$v.archivoAct.maxSingleFileSize && errors.push('El tamaño total debe ser menor a ' + this.fileActSize + 'MB.')
            return errors
        },
        evidPartErrors() {
            const errors = []
            if (!this.$v.archivoPart.$dirty) return errors            
            !this.$v.archivoPart.maxSingleFileSize && errors.push('El tamaño total debe ser menor a ' + this.filePartSize + 'MB.')
            return errors
        },
        archivoDispErrors() {
            const errors = []
            if (!this.$v.archivoDisp.$dirty) return errors            
            !this.$v.archivoDisp.maxSingleFileSize && errors.push('El tamaño total debe ser menor a ' + this.fileDispSize + 'MB.')
            return errors
        },
        label() {
          return this.anioConsulta > 2021 && this.origen === 'educacionAmbiental' ? 'Evidencias de temas tratados' : 'Agregar Evidencias';
        }
    },
    data: () => ({
        dialog : false,
        loading: false,
        archivos: [],
        archivoAct: null,
        archivoPart: null,
        archivoDisp: []
    }),
    validations: {
        archivos: {
            maxFileSize
        },
        archivoDisp: {
            maxFileSize
        },
        archivoAct: {
            maxSingleFileSize
        },
        archivoPart: {
            maxSingleFileSize
        }
    },
    methods: {
        argregarEvidencias() {
            this.$v.$touch();
            this.$v.$ar;
            if(!this.$v.$invalid) {
                this.loading = true;
                let formData = new FormData();
                this.archivos.forEach(file => {
                    formData.append('archivos', file);
                });
                if(this.origen == "consumo"){
                    formData.append('anio', this.anioActual);
                    formData.append('indicador', this.indicadorActual);
                    formData.append('idInstalacion', this.sucursal.id);
                    formData.append('origen', this.origen);
                    formData.append('id', this.data.id);
                    formData.append('idUsuario', this.usuario.id);
                    axios.post('/instalaciones/agregarEvidencias', formData)
                    .then(response => {
                        this.efectivo('Evidencias agregadas');
                    }).catch(error => {
                      if (error.request && error.request.status === 401) {
                        this.$store.commit('showSessionDialog', true);
                      } else {
                        if (error.request && error.request.status === 415) {
                          this.$store.dispatch('noti', {mensaje: error.response.data, color: 'error'});
                          this.loading = false;
                        } else {  
                          this.erroneo(error, 'agregaron las evidencias');
                        }
                      }
                    })
                }else if(this.origen=="educacionAmbiental"){
                    formData.append('idActividad', this.idActividad);
                    formData.append('idUsuario', this.usuario.id);
                    formData.append('evidActividad', this.archivoAct);
                    formData.append('evidParticipacion', this.archivoPart);
                    axios.post('/educacionAmbiental/agregarDocumentos/', formData)
                    .then(response=>{                    
                        this.efectivo('Evidencias agregadas');
                    }).catch(error=> {        
                      if (error.request && error.request.status === 401) {
                        this.$store.commit('showSessionDialog', true);
                      } else {  
                        if (error.request && error.request.status === 415) {
                          this.$store.dispatch('noti', {mensaje: error.response.data, color: 'error'});
                          this.loading = false;
                        } else {     
                          this.erroneo(error, 'agregaron las evidencias');
                        }
                      }
                    })
                }else if(this.origen=="accionReduccion"){
                    formData.append('idAccion', this.$attrs.idAccion);
                    formData.append('idUsuario', this.usuario.id);
                    axios.post('/accionReduccion/agregarDocumentos/', formData)
                    .then(response=>{
                        this.efectivo('Evidencias agregadas');
                    }).catch(error=> {
                      if (error.request && error.request.status === 401) {
                        this.$store.commit('showSessionDialog', true);
                      } else {
                        if (error.request && error.request.status === 415) {
                          this.$store.dispatch('noti', {mensaje: error.response.data, color: 'error'});
                          this.loading = false;
                        } else {
                          thi.erroneo(error, 'agregarom las evidencias');
                        }
                      }
                    })
                }else if (this.origen=="accionesPoS"){
                    formData.append('idAccion', this.idActividad);
                    formData.append('idUsuario', this.usuario.id);
                    axios.post("/accionesPoS/agregarDocumentos", formData)
                    .then(response =>{
                        this.efectivo('Evidencias agregadas');       
                    }).catch(error=>{
                      if (error.request && error.request.status === 401) {
                        this.$store.commit('showSessionDialog', true);
                      } else {
                        if (error.request && error.request.status === 415) {
                          this.$store.dispatch('noti', {mensaje: error.response.data, color: 'error'});
                          this.loading = false;
                        } else {
                          this.erroneo(error, 'agregaron las evidencias');
                        }
                      }
                    })
                }else if (this.origen=="inscripcionLegal"){
                    formData.append('idInscripcion', this.$attrs.idInscripcion);
                    formData.append('idUsuario', this.usuario.id);
                    axios.post("/instalaciones/agregarDocumentosInscripcion", formData)
                    .then(response =>{
                        this.efectivo('Documento agregado');         
                    }).catch(error=>{
                      if (error.request && error.request.status === 401) {
                        this.$store.commit('showSessionDialog', true);
                      } else {
                        if (error.request && error.request.status === 415) {
                          this.$store.dispatch('noti', {mensaje: error.response.data, color: 'error'});
                          this.loading = false;
                        } else {
                          this.erroneo(error, 'agregó el documento');
                        }
                      }
                    })
                }else if (this.origen=="planAccion"){
                    formData.append('plan', this.data.idPlan);
                    formData.append('inst', this.sucursal.id);
                    formData.append('usuario', this.usuario.id);
                    axios.post("/adaptacion/agregarDocsPlanAccion", formData)
                    .then(response =>{
                        this.efectivo('Evidencias agregadas');              
                    }).catch(error=>{
                      if (error.request && error.request.status === 401) {
                        this.$store.commit('showSessionDialog', true);
                      } else {
                        if (error.request && error.request.status === 415) {
                          this.$store.dispatch('noti', {mensaje: error.response.data, color: 'error'});
                          this.loading = false;
                        } else {
                          this.erroneo(error, 'agregaron las evidencias');
                        }
                     }
					})
                }else if(this.origen=="actividadCompesacion"){
                    formData.append('idActividad', this.idActividad);
                    formData.append('idUsuario', this.usuario.id);
                    this.archivoDisp.forEach(file => {
                      formData.append('archivos', file);
                    });
                    axios.post('/actividadCompesacion/agregarDocumentos/', formData)
                    .then(response=>{
                        this.efectivo('Evidencia agregada');
                    }).catch(error=> {
                      if (error.request && error.request.status === 401) {
                        this.$store.commit('showSessionDialog', true);
                      } else {
                        if (error.request && error.request.status === 415) {
                          this.$store.dispatch('noti', {mensaje: error.response.data, color: 'error'});
                          this.loading = false;
                        } else {
                          this.erroneo(error, 'agregó la evidencia');
                        }
                       }
                    })
                }else if(this.origen=="EducacionExterna"){
                    formData.append('idEducacion', this.$attrs.idEducacion);
                    formData.append('idUsuario', this.usuario.id);
                    axios.post('/actividadCompesacion/agregarDocumentosEducacionExt/', formData)
                    .then(response=>{
                        this.efectivo('Evidencia agregada');
                    }).catch(error=> {
                      if (error.request && error.request.status === 401) {
                        this.$store.commit('showSessionDialog', true);
                      } else {
                        if (error.request && error.request.status === 415) {
                          this.$store.dispatch('noti', {mensaje: error.response.data, color: 'error'});
                          this.loading = false;
                        } else {  
                          this.erroneo(error, 'agregó la evidencia');
                        }
                      }
                    })
                } else if (this.origen === 'estrellas' && this.tipo != 9) {
                    formData.append("tipo", this.tipo);
                    formData.append("idUsuario", this.usuario.id);
                    formData.append("id", this.idActividad);
                    axios.post('/estrellas/agregarDocs', formData)
                    .then(response=>{
                        this.efectivo('Evidencia agregada');
                    }).catch(error=> {
                      if (error.request && error.request.status === 401) {
                        this.$store.commit('showSessionDialog', true);
                      } else {
                        if (error.request && error.request.status === 415) {
                          this.$store.dispatch('noti', {mensaje: error.response.data, color: 'error'});
                          this.loading = false;
                        } else {
                            this.erroneo(error, 'agregó la evidencia');
                        }
                      }
                    })
                }else if (this.origen == 'residuosEspeciales'){
                    formData.append('residuo', this.idActividad);
                    formData.append('idUsuario', this.usuario.id);
                    axios.post('/gestionResiduos/agregarDocumentos/', formData)
                        .then(response=>{
                            this.efectivo('Evidencia agregada');
                        }).catch(error=>{
                          if (error.request && error.request.status === 401) {
                            this.$store.commit('showSessionDialog', true);
                          } else {
                            if (error.request && error.request.status === 415) {
                              this.$store.dispatch('noti', {mensaje: error.response.data, color: 'error'});
                              this.loading = false;
                            } else {  
                              this.erroneo(eror, 'agrego la evidencia.')
                            }
                          }
                        })
                  } else if(this.origen=="accionAdaptacion"){
                    formData.append('idEvento', this.idActividad);
                    formData.append('idUsuario', this.usuario.id);
                    axios.post('/adaptacion/agregarEvidenciaAccionAdap', formData)
                    .then(response=>{                    
                        this.efectivo('Evidencias agregadas');
                    }).catch(error=> {        
                      if (error.request && error.request.status === 401) {
                        this.$store.commit('showSessionDialog', true);
                      } else {  
                        if (error.request && error.request.status === 415) {
                          this.$store.dispatch('noti', {mensaje: error.response.data, color: 'error'});
                          this.loading = false;
                        } else {     
                          this.erroneo(error, 'agregaron las evidencias');
                        }
                      }
                    })
                }else if(this.origen=="buenasPracticas"){
                    formData.append('idPractica', this.idActividad);
                    formData.append('idUsuario', this.usuario.id);
                    axios.post('/educacionAmbiental/agregarBuePrcDocumentos/', formData)
                    .then(response=>{                    
                        this.efectivo('Evidencias agregadas');
                    }).catch(error=> {        
                      if (error.request && error.request.status === 401) {
                        this.$store.commit('showSessionDialog', true);
                      } else {  
                        if (error.request && error.request.status === 415) {
                          this.$store.dispatch('noti', {mensaje: error.response.data, color: 'error'});
                          this.loading = false;
                        } else {     
                          this.erroneo(error, 'agregaron las evidencias');
                        }
                      }
                    })
                } else if(this.origen=="programaManejo"){
                    formData.append('programa', this.idActividad);
                    formData.append('usuario', this.usuario.id);
                    axios.post('/bienestarAnimal/evidenciaProgramaManejo/', formData)
                    .then(response=>{                    
                        this.efectivo('Evidencias agregadas');
                    }).catch(error=> {        
                      if (error.request && error.request.status === 401) {
                        this.$store.commit('showSessionDialog', true);
                      } else {  
                        if (error.request && error.request.status === 415) {
                          this.$store.dispatch('noti', {mensaje: error.response.data, color: 'error'});
                          this.loading = false;
                        } else {     
                          this.erroneo(error, 'agregaron las evidencias');
                        }
                      }
                    })
                } else if(this.origen=="disposicionResiduos") {
                    formData.append('idResiduo', this.idActividad);
                    formData.append('user', this.usuario.id);
                    formData.append('inst', this.sucursal.id);
                    formData.append('anio', this.anioActual);
                    axios.post('/indicadorResiduos/agregarEvidenciaDisposicion/', formData)
                    .then(response=>{                    
                        this.efectivo('Evidencias agregadas');
                    }).catch(error=> {        
                      if (error.request && error.request.status === 401) {
                        this.$store.commit('showSessionDialog', true);
                      } else {  
                        if (error.request && error.request.status === 415) {
                          this.$store.dispatch('noti', {mensaje: error.response.data, color: 'error'});
                          this.loading = false;
                        } else {     
                          this.erroneo(error, 'agregaron las evidencias');
                        }
                      }
                    })
                }
            }
        },
        efectivo(str) {
            this.$store.dispatch('noti',{mensaje: str, color:'success'});
            this.$emit('reload');
            this.cancelar();
        },
        erroneo(error, str){
            console.log("Error en la carga de documentos " + error);
            this.$store.dispatch('noti', {mensaje: 'Ocurrió un error no se ' + str, color:'error'}); 
            this.cancelar();
        },
        cancelar() {
            this.archivos = [];
            this.$v.archivos.$reset();
            this.dialog = false;
            this.loading = false;
            this.archivoAct = null;
            this.archivoPart = null;
            this.archivoDisp = [];
        }
    },
}
</script>