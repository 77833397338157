<template>
    <v-dialog
        v-model="dialog"  
        persistent 
        scrollable
        max-width="500px"
        transition="dialog-transition">
        <template v-slot:activator="{ on:dialog }">
            <v-tooltip top>
                <template v-slot:activator="{ on:tooltip }">
                    <v-btn large dark icon v-on="{ ...tooltip, ...dialog }" @click="getTipoDeResiduos()"><v-icon color="#0B3F67">mdi-cloud-upload</v-icon></v-btn>
                </template>
                Agregar Evidencias.
            </v-tooltip>
        </template>
        <v-card :loading="loading">
            <v-card-title class="pa-0">
              <ModalTitle :titulo="titulo" @cancelar="cancelar"/>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <v-select
                    :items="tiposDeResiduos"
                    item-text= "nombre"
                    item-value="valor"
                    v-model="tipoDeResiduo"
                    @change="cambiarSeleccion"
                    label="Tipo del Residuo"
                ></v-select> 
                <v-file-input
                    v-if="seleccionado"
                    accept=".png, .jpg, .jpeg" 
                    show-size 
                    counter 
                    multiple 
                    v-model="archivos"
                    :error-messages="docErrors"
                    @change="$v.archivos.$touch()"
                    @blur="$v.archivos.$touch()"
                    label="Agregar Evidencias">
                    label="Agregar Evidencias">
                </v-file-input> 
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" :disabled="loading" @click="cancelar" text>Cancelar</v-btn>
                <v-btn color="#0B3F67" :loading="loading" @click="agregarEvidencias" text>Agregar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>

import axios from "@/axios.js";
import { required } from "vuelidate/lib/validators";
import ModalTitle from '@/components/utils/ModalTitleComponent'
//Custom validations
const maxFileSize = (files) => {
    if(files != null) {
        let maximo = files.length * 1000000;
        let total = 0;
        files.forEach(file => {
            total += file.size
        });        
        return total < maximo;
    }
    return false;
}

export default {
    created() {
        axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;
    },  
    components: {
      ModalTitle
    },
    props: [
        'anioActual',
        'icon',
        'tipo',
        'data', //por si hace falta enviar datos adicionales
    ],
    computed: {
        fileSize() {
            return this.archivos != null ? this.archivos.length : 0;
        },
        sucursal() {
            return this.$store.getters.sucursal;
        },
        usuario(){
            return this.$store.getters.usuario;
        },
        titulo(){
            return "Agregar Evidencias de Residuos."
        },
        docErrors () {
            const errors = []
            if (!this.$v.archivos.$dirty) return errors            
            !this.$v.archivos.required && errors.push('La evidencia es requerida.')
            !this.$v.archivos.maxFileSize && errors.push('El tamaño total debe ser menor a ' + this.fileSize + ' MB.')        
            return errors
        },
        anioConsulta() {
            return this. $store.getters.anioConsulta;
        },
    },
    data: () => ({
        dialog : false,
        archivos: null,
        seleccionado: false,
        loading: false,
        tiposDeResiduos: [],
        tipoDeResiduo: '',
    }),
    validations: {
        archivos: {
            required,
            maxFileSize
        }
    },
    methods: {
        agregarEvidencias() {
            if(!this.$v.$invalid) {
                this.loading = true;
                let formData = new FormData();
                this.archivos.forEach(file => {
                    formData.append('documentos', file);
                });
                formData.append("tipoDeResiduo", this.tipoDeResiduo);
                formData.append("anio", this.anioActual);
                formData.append("instalacion", this.sucursal.id);
                formData.append("iduser", this.usuario.id);
                axios.post("/instalaciones/agregarEvidenciaResiduos/", formData).then(response =>{
                    this.$store.dispatch('noti',{mensaje: 'Evidencias agregadas', color:'success'});
                    this.cancelar();
                }).catch(error=>{
                  if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                  } else {
                    if (error.request && error.request.status === 415) {
                      this.$store.dispatch('noti', {mensaje: error.response.data, color: 'error'});
                      this.loading = false;
                    } else {
                      this.$store.dispatch('noti',{mensaje: 'Ocurrió un error no se agregaron las evidencias', color:'error'});
                      this.cancelar();
                    }
                  }
                });
            }
        },
        cancelar() {
            this.archivos = null;
            this.dialog = false;
            this.tipoDeResiduo = '';
            this.seleccionado = false;
            this.loading = false;
        },
        cambiarSeleccion(){
            this.seleccionado = true;
        },
        getTipoDeResiduos(){
            let valorizable = false;
            if (this.tipo != 1){
                valorizable = true;
            }
            axios.get("/instalaciones/getTiposResiduos/"+ this.sucursal.id, {params:{
                esValorizable : valorizable,
                anio: this.anioConsulta
            }}).then(response=>{
                this.tiposDeResiduos = response.data.tipos;
            }).catch(error=>{
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                console.log(error);
              }
            })
        },
    },
}
</script>