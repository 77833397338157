<template>
    <v-dialog
        v-model="dialog"  
        persistent 
        scrollable
        max-width="550px"
        transition="dialog-transition">
        <template v-slot:activator="{ on }">
            <v-tooltip bottom>
                <template v-slot:activator="{ on: tooltip }">
                    <v-icon
                        small
                        exact-active-class="indigo"
                        class="ma-2"
                        v-on="{ ...tooltip, ...on }"
                        @click="openModal()"
                    >mdi-pencil</v-icon>
                </template>
                <span>Modificar</span>
            </v-tooltip>
        </template>
        <v-card :loading="loading">
            <v-card-title class="pa-0">
              <ModalTitle titulo="Agregar" @cancelar="cancelar"/>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="12" class="pt-4 pb-0">
                        <v-textarea
                            rows="3"
                            label="Descripción Croquis"
                            counter="500"
                            v-model="actividad.descripCroquis"
                            :error-messages="descErrors"             
                            @change="$v.actividad.descripCroquis.$touch()"
                            @blur="$v.actividad.descripCroquis.$touch()"
                            required
                        ></v-textarea>
                    </v-col>
                    <v-col cols="12" md="12" class="pt-0 pb-0">
                        <v-textarea
                            rows="3"
                            label="Descripción Uso de Suelo"
                            counter="500"
                            v-model="actividad.descripUsoSuelo"
                            :error-messages="descUsoErrors"             
                            @change="$v.actividad.descripUsoSuelo.$touch()"
                            @blur="$v.actividad.descripUsoSuelo.$touch()"
                            required
                        ></v-textarea>
                    </v-col>
                    <v-col cols="12" md="12" class="pt-0 pb-0">
                        <v-textarea
                            rows="3"
                            label="Descripción Topografia"
                            counter="500"
                            v-model="actividad.descripTopografia"
                            :error-messages="descTopErrors"             
                            @change="$v.actividad.descripTopografia.$touch()"
                            @blur="$v.actividad.descripTopografia.$touch()"
                            required
                        ></v-textarea>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" :disabled="loading" text @click.native="cancelar">Cancelar</v-btn>
                <v-btn color="#0B3F67" :loading="loading" text @click="modificar">Agregar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>

import axios from "@/axios.js"
import ModalTitle from '@/components/utils/ModalTitleComponent'
import { required, maxLength, requiredIf } from "vuelidate/lib/validators"

export default {
    props: [
        'id'
    ],
    components: {
      ModalTitle
    },
    computed: {
        descErrors () {
            const errors = []
            if (!this.$v.actividad.descripCroquis.$dirty) return errors
            !this.$v.actividad.descripCroquis.required && errors.push('Campo requerido')
            !this.$v.actividad.descripCroquis.maxLength && errors.push('No debe exceder el tamaño permitido.')
            return errors
        },
        descUsoErrors () {
            const errors = []
            if (!this.$v.actividad.descripUsoSuelo.$dirty) return errors
            !this.$v.actividad.descripUsoSuelo.required && errors.push('Campo requerido')
            !this.$v.actividad.descripUsoSuelo.maxLength && errors.push('No debe exceder el tamaño permitido.')
            return errors
        },
        descTopErrors () {
            const errors = []
            if (!this.$v.actividad.descripTopografia.$dirty) return errors
            !this.$v.actividad.descripTopografia.required && errors.push('Campo requerido')
            !this.$v.actividad.descripTopografia.maxLength && errors.push('No debe exceder el tamaño permitido.')
            return errors
        },
        indicadorActual() {
            return this.$store.getters.indicadorActual;
        },
        sucursal() {
            return this.$store.getters.sucursal;
        },
        usuario(){
            return this.$store.getters.usuario;
        },
        anioConsulta() {
            return this. $store.getters.anioConsulta;
        },
    },
    data: () => ({
        loading: false,
        dialog: false,
        actividad: {
            anio: null,
            descripCroquis: '',
            descripUsoSuelo: '',
            descripTopografia: '',
        }
    }),
    validations: {
        actividad: {
            descripCroquis: {
                maxLength: maxLength(500),
                required
            },
            descripUsoSuelo:{
                maxLength: maxLength(500),
                required
            },
            descripTopografia:{
                maxLength: maxLength(500),
                required
            }
        }
    },
    methods: {
        openModal() {
            this.dialog = true
            this.getData()
        },
        getData() {
            axios.get(`/manejoSuelo/getDataCaractUsoSuelo/${this.id}`).then(r => {
                this.actividad = r.data
            }).catch(error => {
                if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                }      
            })
        },
        modificar() {
            this.$v.$touch();
            if(!this.$v.$invalid) {
                this.loading = true;
                axios.post("/manejoSuelo/modificarCaractUsoSuelo", this.actividad, { params: {
                    id: this.id,
                    idUsuario: this.usuario.id
                }}).then(response => {
                    this.$store.dispatch('noti',{mensaje: 'Actividad de Buenas Prácticas modificado', color:'success'});   
                    this.$emit('reload');
                    this.cancelar();
                }).catch(error => {
                  if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                  } else {
                    if (error.request && error.request.status === 415) {
                      this.$store.dispatch('noti', {mensaje: error.response.data, color: 'error'});
                      this.loading = false;
                    } else {
                      this.$store.dispatch('noti', {mensaje: 'Ocurrió un error no se modificar la actividad de Buenas Prácticas', color:'error'});   
                      this.loading = false;  
                    } 
                  }        
                })
            }
        },
        cancelar() {
            this.actividad = {};
            this.$v.$reset();  
            this.dialog = false;
            this.loading = false;
        }
    },
}
</script>