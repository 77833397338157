<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12">
                <tituloPrincipal titulo="Manual de Usuario"/>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <Upload />
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" >
                <v-card>        
                    <v-card-text>
                        <v-data-table
                            :headers="headers"
                            :items="manuales"
                            item-key="id"
                            :search="search">
                            <template v-slot:item.action="{ item }">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" color="#0B3F67" @click="descargarDocumento(item.documentoId)">mdi-cloud-download-outline</v-icon>
                                    </template>
                                    Descargar
                                </v-tooltip>  
                            </template>
                            <template v-slot:item.fechaRegistro="{ item }">
                                {{ dateFormat(item.fechaRegistro) }}
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import moment from 'moment'
import axios from "@/axios";
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
import Upload from '@/components/documentos/AgregarManualUsuario'
import Download from '@/components/documentos/DescargarManualUsuario'
import TituloPrincipal from '@/components/utils/TituloPrincipalComponent'
export default {
    components: {
        TituloPrincipal,
        VuePdfEmbed,
        Download,
        Upload
    },
    created() {
        axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;        
    },
    mounted() {
        this.getManual();
    },
    computed: {
        rolUsuario(){
            return this.$store.getters.usuario.rol;
        },
    },
    data: () => ({
        search: null,
        manuales: [],
        headers: [
            {
                text: 'Acciones',
                value: 'action',
                align: 'center',
                width: '10%',
                sortable: false
            },
            {
                text: 'Tipo de Manual',
                value: 'origen',
                align: 'left',
                width: '30%',
                sortable: false
            },
            {
                text: 'Ultima Actualizacion',
                value: 'fechaRegistro',
                align: 'center',
                width: '20%',
                sortable: false
            },
        ],
    }),
    methods: {
        getManual() {
            this.loadingModal(true);
            axios.get(`/documento/getManualUsuario/${this.rolUsuario}`).then(response => {
                this.manuales = response.data
                this.loadingModal(false);
            }).catch(error => {
                if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
                } else {
                console.log(error);
                }
                this.loadingModal(false);
            })
        },
        loadingModal(modal) {                        
            this.$store.dispatch('loadingPage', modal);
        },
        dateFormat(fecha) {
            return fecha ? moment(fecha).format('DD/MM/YYYY') : '';
        },
        descargarDocumento(documentoId) {
            axios({
                url: '/documento/descargar/' + documentoId,
                method: 'GET',
                responseType: 'blob',
            }).then((response) => {
                const nombre = response.headers.filename;
                
                const fileURL = URL.createObjectURL(new Blob([response.data], {
                    type: response.data.type
                }))
                
                let fileLink = document.createElement('a');            
                fileLink.href = fileURL;
                fileLink.setAttribute('download', nombre);
                document.body.appendChild(fileLink);            
                fileLink.click();
            });
        },
    },
}
</script>