<template>
    <v-dialog
        v-model="dialog"  
        persistent 
        scrollable
        max-width="550px"
        transition="dialog-transition">
        <template v-slot:activator="{ on }">
            <v-btn class="white--text" color="#0B3F67" v-on="on">Agregar<v-icon right>mdi-plus-box-outline</v-icon></v-btn>
        </template>
        <v-card :loading="loading">
            <v-card-title class="pa-0">
              <ModalTitle titulo="Agregar" @cancelar="cancelar"/>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="12" class="pt-4 pb-0">
                        <v-textarea
                            rows="3"
                            label="Descripción Análisis"
                            counter="1000"
                            v-model="analisis.descripcion"
                            :error-messages="descErrors"             
                            @change="$v.analisis.descripcion.$touch()"
                            @blur="$v.analisis.descripcion.$touch()"
                            required
                        ></v-textarea>
                    </v-col>
                    <v-col cols="12" class="pt-0 pb-0">
                        <v-file-input 
                            accept=".png, .jpg, .jpeg"
                            show-size 
                            counter 
                            multiple 
                            v-model="archivos" 
                            label="Evidencias"
                            :error-messages="evidErrors"
                            @change="$v.archivos.$touch()"
                            @blur="$v.archivos.$touch()">
                        </v-file-input>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" :disabled="loading" text @click.native="cancelar">Cancelar</v-btn>
                <v-btn color="#0B3F67" :loading="loading" text @click="agregarAanalisis">Agregar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>

import ModalTitle from '@/components/utils/ModalTitleComponent'
import { required, maxLength, requiredIf } from "vuelidate/lib/validators"
import axios from "@/axios.js";
//Custom validations
const maxFileSize = (files) => {
    if(files != null) {
        let maximo = files.length * 1000000;
        let total = 0;
        files.forEach(file => {
            total += file.size
        });        
        return total < maximo;
    }
    return false;
}
export default {
    components: {
      ModalTitle
    },
    computed: {
        fileSize() {
            return this.archivos != null ? this.archivos.length : 0;
        },
        descErrors () {
            const errors = []
            if (!this.$v.analisis.descripcion.$dirty) return errors
            !this.$v.analisis.descripcion.required && errors.push('Campo requerido')
            !this.$v.analisis.descripcion.maxLength && errors.push('No debe exceder el tamaño permitido.')
            return errors
        },
        evidErrors() {
            const errors = []
            if (!this.$v.archivos.$dirty) return errors            
            !this.$v.archivos.required && errors.push('Por favor adjuntar evidencias.')
            !this.$v.archivos.maxFileSize && errors.push('El tamaño total debe ser menor a ' + this.fileSize + 'MB.')
            return errors
        },
        indicadorActual() {
            return this.$store.getters.indicadorActual;
        },
        sucursal() {
            return this.$store.getters.sucursal;
        },
        usuario(){
            return this.$store.getters.usuario;
        },
        anioConsulta() {
            return this. $store.getters.anioConsulta;
        },
    },
    data: () => ({
        loading: false,
        dialog: false,
        analisis: {
            anio: null,
            descripcion: '',
        }, 
        archivos:[],
    }),
    validations: {
        analisis: {
            descripcion: {
                maxLength: maxLength(1000),
                required
            },
        },
        archivos: {
            required,
            maxFileSize
        }
    },
    methods: {
        agregarAanalisis() {
            this.$v.$touch();
            if(!this.$v.$invalid) {
                this.loading = true;
                this.analisis.anio = this.anioConsulta
                var formData = new FormData();
                this.archivos.forEach(file => {
                    formData.append('archivos', file);
                });
                formData.append("caractQuimica", JSON.stringify(this.analisis));
                formData.append('instal',this.sucursal.id);
                formData.append("usuario", this.usuario.id);
                axios.post("/manejoSuelo/agregarCaractQuimica", formData).then(response => {
                    this.$store.dispatch('noti',{mensaje: 'Actividad de Buenas Prácticas agregada', color:'success'});   
                    this.$emit('reload');
                    this.cancelar();
                }).catch(error => {
                  if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                  } else {
                    if (error.request && error.request.status === 415) {
                      this.$store.dispatch('noti', {mensaje: error.response.data, color: 'error'});
                      this.loading = false;
                    } else {
                      this.$store.dispatch('noti', {mensaje: 'Ocurrió un error no se agregó la actividad de Buenas Prácticas', color:'error'});   
                      this.loading = false;  
                    } 
                  }        
                })
            }
        },
        cancelar() {
            this.actividad = {};
            this.archivos=[];
            this.$v.$reset();  
            this.dialog = false;
            this.loading = false;
        }
    },
}
</script>