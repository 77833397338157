<template>
  <v-dialog v-model="dialog" persistent max-width="1000px" transition="dialog-transition">
    <template v-slot:activator="{ on:dialog }">
      <v-tooltip top>
        <template v-slot:activator="{ on:tooltip }">
          <v-btn @click="getDocumentos" large dark icon v-on="{ ...tooltip, ...dialog }">
            <v-icon color="#0B3F67">mdi-file-multiple</v-icon>
          </v-btn>
        </template>
        Ver Archivos Adjuntos
      </v-tooltip>
    </template>
    <v-card>
      <v-card-title class="pa-0">
        <ModalTitle :titulo="titulo" @cancelar="cerrar"/>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <DocumentosTable
          :idMedidor="medidorId"
          :headers="headers"
          :data="documentos"
          :idActividad="idActividad"
          :origen="origen"
          sinDatos="No hay documentos adjuntos"
          :anioConsulta="anioConsulta"
          :footerProps="footer"
          :id="id"
          @reload="getDocumentos"/>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="cerrar" color="error">Cerrar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import DocumentosTable from "@/components/utils/DataTableComponent";
import ModalTitle from '@/components/utils/ModalTitleComponent'
import axios from "@/axios.js";

export default {
  components: {
    DocumentosTable,
    ModalTitle
  },
  created() {
      axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;
      axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
  },  
  computed: {
    indicadorActual() {
      return this.$store.getters.indicadorActual;
    }, 
    sucursal() {
      return this.$store.getters.sucursal;
    },
  },
  props: [
        "medidorId", 
        "anioConsulta", 
        "origen",
        "idActividad",
        "titulo",
        "id",
        "idEducacion"
    ],
  data: () => ({
    dialog: false,
    documentos: [],
    headers: [
      { 
        text: "Acciones",
        value: "action",
        align: "center",
        align: 'center',
        width: '10%',
        sortable: false
      },
      {
        text: "Nombre",
        value: "nombre",
        align: "center",
        sortable: true
      },
      {
        text: "Origen",
        value: "origen",
        align: "center",
        sortable: true
      },
      {
        text: "Fecha Registro",
        value: "fechaRegistro",
        align: "center",
        sortable: true
      },
      {
        text: "Archivo",
        align: "center",
        sortable: false,
        value: "documentoId"
      }
    ],
    footer: {
      disableItemsPerPage: true,
      showFirstLastPage: true,
      firstIcon: "mdi-page-first",
      lastIcon: "mdi-page-last",
      prevIcon: "mdi-chevron-left",
      nextIcon: "mdi-chevron-right",
      itemsPerPageText: "Registros por página",
      itemsPerPageAllText: "Todos",
      showCurrentPage: true
    }
  }),
  methods: {
    getDocumentos() {
      this.$store.dispatch("loadingPage", true);
      if (this.origen == "consumo") {
        axios.get("/documento/getPorMedidor/" +this.medidorId +"/" +this.indicadorActual +"/" +this.anioConsulta).then(response => {
            this.documentos = response.data.documentos;
            this.$store.dispatch("loadingPage", false);
          }).catch(error => {
            if (error.request && error.request.status === 401) {
              this.$store.commit('showSessionDialog', true);
            } else {
              console.log("Error en la carga de documentos " + error);
              this.$store.dispatch("loadingPage", false);
            }
          });
      }else if (this.origen == "educacionAmbiental"){
          axios.get("/educacionAmbiental/getDocumentos/" + this.idActividad).then(response=>{
            this.documentos = response.data.documentos;
            this.$store.dispatch("loadingPage", false);
          }).catch(error=>{
            if (error.request && error.request.status === 401) {
              this.$store.commit('showSessionDialog', true);
            } else {
              console.log("Error en la carga de documentos " + error);  
              this.$store.dispatch("loadingPage", false);
            }
          });
      }else if (this.origen == "accionReduccion"){
          let idAccion = this.$attrs.idAccion;
          axios.get("/accionReduccion/getDocumentos/" + idAccion).then(response=>{
            this.documentos = response.data.documentos;
            this.$store.dispatch("loadingPage", false);
          }).catch(error=>{
            if (error.request && error.request.status === 401) {
              this.$store.commit('showSessionDialog', true);
            } else {
              console.log("Error en la carga de documentos " + error);
              this.$store.dispatch("loadingPage", false);
            }
          });
      }else if(this.origen == "accionesPoS"){
        axios.get("/accionesPoS/getDocumentos/"+this.idActividad).then(response=>{
            this.documentos = response.data.documentos;
            this.$store.dispatch("loadingPage", false);
        }).catch(error=>{
          if (error.request && error.request.status === 401) {
            this.$store.commit('showSessionDialog', true);
          } else {
            console.log("Error en la carga de documentos " + error);
            this.$store.dispatch("loadingPage", false); 
          }         
        })
      }else if(this.origen == "tratamientoParametro"){
        axios.get("/documento/listarTratamientoParametro/"+this.medidorId).then(response=>{                    
          this.documentos = response.data.evidencias;
          this.$store.dispatch("loadingPage", false);
        }).catch(error=>{
          if (error.request && error.request.status === 401) {
            this.$store.commit('showSessionDialog', true);
          } else {
            console.log("Error en la carga de documentos " + error);
            this.$store.dispatch("loadingPage", false);          
          }
        })
      }else if(this.origen == "vertidoSustancias") {
        axios.get("/documento/getDocVertidoAg/"+this.id).then(response=>{                    
          this.documentos = response.data;
          this.$store.dispatch("loadingPage", false);
        }).catch(error=>{
          if (error.request && error.request.status === 401) {
            this.$store.commit('showSessionDialog', true);
          } else {
            console.log("Error en la carga de documentos " + error);
            this.$store.dispatch("loadingPage", false);   
          }       
        })
      } else if(this.origen == "contaminanteAtmosferico"){
        axios.get(`/documento/getPorProducto/${this.medidorId}/${this.anioConsulta}`).then(response=>{                    
            this.documentos = response.data.documentos;
            this.$store.dispatch("loadingPage", false);
        }).catch(error=>{
          if (error.request && error.request.status === 401) {
            this.$store.commit('showSessionDialog', true);
          } else {
            console.log("Error en la carga de documentos " + error);
            this.$store.dispatch("loadingPage", false);
          }
        })
      }else if(this.origen == "residuos"){
        let valorizable = false;
        if (this.consumos != 1){
          valorizable = true;
        }
        axios.get("documento/getDocsResiduos/",{params:{
          instalacion: this.sucursal.id,
          anio:this.anioConsulta,
          valorizable : valorizable
        }}).then(response=>{
          this.documentos = response.data.Documentos;
          this.$store.dispatch("loadingPage", false);
        }).catch(error=>{
          if (error.request && error.request.status === 401) {
            this.$store.commit('showSessionDialog', true);
          } else {
            console.log("Error en la carga de documentos " + error);
            this.$store.dispatch("loadingPage", false);   
          }
        })
      }else if(this.origen == "planAccion") {
        axios.get("/adaptacion/getDocsplanAccion/"+this.id).then(response=>{                    
          this.documentos = response.data;
          this.$store.dispatch("loadingPage", false);
        }).catch(error=>{
          if (error.request && error.request.status === 401) {
            this.$store.commit('showSessionDialog', true);
          } else {
            console.log("Error en la carga de documentos " + error);
            this.$store.dispatch("loadingPage", false);   
          }       
        })
      }else if (this.origen == "actividadCompesacion"){
          let idActividad = this.idActividad;
          axios.get("/actividadCompesacion/getDocumentos/" + idActividad).then(response=>{
            this.documentos = response.data.documentos;
            this.$store.dispatch("loadingPage", false);
          }).catch(error=>{
            if (error.request && error.request.status === 401) {
              this.$store.commit('showSessionDialog', true);
            } else {
              console.log("Error en la carga de documentos " + error);
              this.$store.dispatch("loadingPage", false);
            }
          });
      }else if (this.origen == "EducacionExterna"){
          let idEducacion = this.idEducacion;
          axios.get("/actividadCompesacion/getDocumentosEducacion/" + idEducacion).then(response=>{
            this.documentos = response.data.documentos;
            this.$store.dispatch("loadingPage", false);
          }).catch(error=>{
            if (error.request && error.request.status === 401) {
              this.$store.commit('showSessionDialog', true);
            } else {
              console.log("Error en la carga de documentos " + error);
              this.$store.dispatch("loadingPage", false);
            }
          });
      } else if (this.origen === 'inscripcionLegal') {
          axios.get("/documento/getDocsInscripcion/" + this.idActividad)
          .then(response=>{
            this.documentos = response.data.documentos;
            this.documentos.forEach(d => {
                d.instalacion = this.medidorId;
            });
            this.$store.dispatch("loadingPage", false);
          }).catch(error=>{
            if (error.request && error.request.status === 401) {
              this.$store.commit('showSessionDialog', true);
            } else {
              console.log("Error en la carga de documentos " + error);
              this.$store.dispatch("loadingPage", false);
            }
          });
      } else if (this.origen === 'estrellas') {
          let anio = new Date().getFullYear();
          axios.get("/estrellas/getDocsEstrellas/" + this.idActividad + '/' + this.medidorId + '/' + anio)
          .then(response=>{
            this.documentos = response.data.documentos;
            this.$store.dispatch("loadingPage", false);
          }).catch(error=>{
            if (error.request && error.request.status === 401) {
              this.$store.commit('showSessionDialog', true);
            } else {
              console.log("Error en la carga de documentos " + error);
              this.$store.dispatch("loadingPage", false);
            }
          });
      }else if (this.origen == 'residuosEspeciales'){
        axios.get("/gestionResiduos/getDocumentos/"+this.idActividad).then(response=>{
          this.documentos = response.data.documentos;
          this.$store.dispatch("loadingPage", false);
        }).catch(error=>{
          if (error.request && error.request.status === 401) {
            this.$store.commit('showSessionDialog', true);
          } else {
            console.log("Error en la carga de documentos " + error);
            this.$store.dispatch("loadingPage", false);
          }
        })
      }else if (this.origen == 'prevencion'){
        axios.get("/prevencion/getEvidenciaAccionPrev/"+this.id).then(response=>{
          this.documentos = response.data.documentos;
          this.$store.dispatch("loadingPage", false);
        }).catch(error=>{
          if (error.request && error.request.status === 401) {
            this.$store.commit('showSessionDialog', true);
          } else {
            console.log("Error en la carga de documentos " + error);
            this.$store.dispatch("loadingPage", false);
          }
        })
      }else if (this.origen == 'limpieza'){
        axios.get("/limpieza/getEvidenciaLimpieza/"+this.id).then(response=>{
          this.documentos = response.data.documentos;
          this.$store.dispatch("loadingPage", false);
        }).catch(error=>{
          if (error.request && error.request.status === 401) {
            this.$store.commit('showSessionDialog', true);
          } else {
            console.log("Error en la carga de documentos " + error);
            this.$store.dispatch("loadingPage", false);
          }
        })
      } else if (this.origen == 'programaCompras'){
        axios.get(`/comprasSostenibles/getEvidenciasPrograma/${this.id}`).then(response=>{
          this.documentos = response.data.documentos;
          this.$store.dispatch("loadingPage", false);
        }).catch(error=>{
          if (error.request && error.request.status === 401) {
            this.$store.commit('showSessionDialog', true);
          } else {
            console.log("Error en la carga de documentos " + error);
            this.$store.dispatch("loadingPage", false);
          }
        })
      } else if (this.origen == 'accionAdaptacion'){
        axios.get(`/adaptacion/getEvidenciasAcciones/${this.id}`).then(response=>{
          this.documentos = response.data.documentos;
          this.$store.dispatch("loadingPage", false);
        }).catch(error=>{
          if (error.request && error.request.status === 401) {
            this.$store.commit('showSessionDialog', true);
          } else {
            console.log("Error en la carga de documentos " + error);
            this.$store.dispatch("loadingPage", false);
          }
        })
      } else if (this.origen == 'usoSuelo'){
        axios.get(`/manejoSuelo/getEvidenciasCaracUso/${this.id}`).then(response=>{
          this.documentos = response.data;
          this.$store.dispatch("loadingPage", false);
        }).catch(error=>{
          if (error.request && error.request.status === 401) {
            this.$store.commit('showSessionDialog', true);
          } else {
            console.log("Error en la carga de documentos " + error);
            this.$store.dispatch("loadingPage", false);
          }
          
        })
      } else if (this.origen == 'caractQuimicas'){
        axios.get(`/manejoSuelo/getEvidCaractQuimicas/${this.id}`).then(response=>{
          this.documentos = response.data;
          this.$store.dispatch("loadingPage", false);
        }).catch(error=>{
          if (error.request && error.request.status === 401) {
            this.$store.commit('showSessionDialog', true);
          } else {
            console.log("Error en la carga de documentos " + error);
            this.$store.dispatch("loadingPage", false);
          }
        })
      } else if(this.origen == 'fuentesAgua') {
        axios.get("/indicadorAgua/getDocsUsoFuente/", {params:{
            id: this.id,
          }}).then(response => {
            this.documentos = response.data;
          }).catch(error =>{
            if (error.request && error.request.status === 401) {
              this.$store.commit('showSessionDialog', true);
            } else {
              this.$store.dispatch("loadingPage", false);
            }
          })
      } else if(this.origen === 'disposicionResiduos') {
        axios.get("/indicadorResiduos/getEvidenciasDisposicion/", {params:{
            id: this.id,
          }}).then(response => {
            console.log(response.data);
            
            this.documentos = response.data;
          }).catch(error =>{
            if (error.request && error.request.status === 401) {
              this.$store.commit('showSessionDialog', true);
            } else {
              this.$store.dispatch("loadingPage", false);
            }
          })
      }
    },
    erroneo(error){
        
    },
    cerrar() {
      this.documentos = [];
      this.dialog = false;
      this.$store.dispatch("loadingPage", false);
    }
  }
};
</script>
