<template>
    <v-container fluid class="pr-0 pl-0">
        <logo @cambiaAnio="listarAccionReduccion" v-sticky="{ zIndex: 4, stickyTop: 65}" />
        <navParameters :navParams="navParams"/>
        <v-row>
            <v-col cols="12">
                <tituloPrincipal titulo="Acciones de Reducción y Control"/>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="6" md="3" align-self="center">
                <agregarAccion v-if="rolUsuario != 'CEO' && rolUsuario != 'GERENTE' && rolUsuario != 'SUPERVISOR'" 
                    @reload="listarAccionReduccion" 
                    @agregarAccion="agregarAccion" 
                    v-bind:sucursal="sucursal"
                    :cantAcciones="data.length"
                />
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="6" md="2">
                <v-text-field
                    prepend-inner-icon="mdi-magnify"
                    label="Buscar"
                    v-model="search"
                ></v-text-field>
            </v-col>
        </v-row>
        <v-card>
            <v-card-title primary-title class="center--text">
                Acciones de Reducción y Control
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <AccionesTabla :buscar="search" v-bind:headers="headers" v-bind:acciones="data" @reload="listarAccionReduccion"/>
            </v-card-text>
        </v-card>
    </v-container>
</template>
<script>

import AccionesTabla from "@/components/parametros/AccionReduccion/AccionesReducTablaComponent"
import AgregarAccion from "@/components/parametros/AccionReduccion/AgregarAccionReducComponent"
import TituloPrincipal from '@/components/utils/TituloPrincipalComponent'
import NavParameters from '@/components/utils/NavParameterComponent'
import Logo from '@/components/utils/CompanyComponent'
import VueSticky from 'vue-sticky'
import axios from "@/axios.js";

export default {

    created() {
        axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;
    },  
    computed: {
        sucursal() {
            return this.$store.getters.sucursal;
        },
        indicadorActual() {
            return this.$store.getters.indicadorActual;
        },
        instalacion() {
            return this.$store.getters.sucursal;
        },
        rolUsuario(){
            return this.$store.getters.usuario.rol;
        },
        anioConsulta() {
            return this. $store.getters.anioConsulta;
        },
        casoUno() {
            let constantes = this.$store.getters.constantes;
            return constantes.TIPO_ACTIVIDAD.ACTIVIDAD_CASO_UNO
        },
    },
    directives: {
        'sticky': VueSticky,
    },
    props: [
        'navParams'
    ],
    components: {
        AccionesTabla,
        AgregarAccion,
        NavParameters,
        TituloPrincipal,
        Logo
    },
    data: () => ({
        search: null,
        data: [],
        headers: [
            {
                text: "Acciones",
                value: "action",
                align: "center",
            },
            {
                text: "Tipo de Actividad",
                value: "tipoActividad",
                align: "start",
            },
            {
                text: "Descripción de la Actividad",
                value: "descripcion",
                align: "start",
            },
            {
                text: "Cantidad de Actividades",
                value: "cantidadActividades",
                align: "center",
            },
            {
                text: "Fecha de la Actividad",
                value: "fechaActividad",
                align: "center",
            },
            {
                text: "Evidencia",
                value: "evidencia",
                align: "center",
            },
        ]
    }),
    mounted () {
        this.listarAccionReduccion();
    },
    methods: {
        agregarAccion(accion) {
            this.data.push(accion);
        },
        listarAccionReduccion(){
            let idInstalacion = this.sucursal.id;
            let indicador = this.indicadorActual;
            let year = this.anioConsulta;
            axios.get(`/accionReduccion/listarAccionesReduccion/${idInstalacion}/${indicador}`,{params:{year: year, tipo: this.casoUno}}).then(response => {
                this.data = response.data.acciones;
                this.paginaCargada(false);
            }).catch(error => {
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                console.log(error);
                this.paginaCargada(false);
              }
            })
        },
        paginaCargada() {
            this.$store.dispatch('loadingPage', false);
        },
        
    },
}
</script>