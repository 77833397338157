/* eslint-disable */
import Vue from "vue";
import Router from "vue-router";
import Home from "./views/NewHome.vue";
//layouts
import MainLayout from "./views/layouts/main";
import BanderaLayout from "./views/layouts/banderaAzul";
//dashboards
import Dashboard from "./views/dashboards/Dashboard";
import CarbonoNeutral from "./views/dashboards/DashboardCarbono";
import BanderaAzulDashboard from "./views/dashboards/DashboardBandera";
import ParametrosDashboard from './views/dashboards/DashboardParametros';
//Parametros de indicadores
import EducacionAmbiental from "./views/parametros/EducacionAmbiental";
import AccionesReduccion from "./views/parametros/AccionesReduccion";
import ReduccionConsumo from "./views/parametros/ReduccionConsumo";
import ConsumoMensual from "./views/parametros/ConsumoMensual";
import VerificarAgua from "./views/parametros/VerificarAguaPotable";
import InventarioConsumidores from "./views/parametros/InventariosConsumidores";
import TratamientoAguaResidual from "./views/parametros/TratamientoAguaResidual";
import AccionPrimerSegundo from "./views/parametros/AccionPrimerSegundo";
import SistemaTratamiento from "./views/parametros/SistemaDeTratamiento";
import VertidoSustancias from './views/parametros/VertidoSustancias';
import InventarioComprasSos from './views/parametros/InventarioComprasSos';
import ContaminantesAtmosfericos from './views/parametros/InventarioContaminantesAtmosfericos';
import ConsumoContaAtmosferico from './views/parametros/ConsumoContaAtmosfericos';
import ResiduosEspeciales from './views/parametros/ResiduosManejoEspecial';
import ResiduosPeligroso from './views/parametros/ResiduosManejoPeligroso';
import ConsumoMensualResiduos from "./views/parametros/ConsumoResiduosValorizables";
import ReduccionResiduos from "./views/parametros/ReduccionResiduosValorizables";
import ProgramaComprasSos from './views/parametros/ProgramaComprasSostenibles';
import SustitucionProductos from './views/parametros/SustitucionProductos';
import EventosClimaticos from './views/parametros/EventosClimaticos';
import AccionesEventosClimaticos from './views/parametros/AccionesCambioClimatico';
import PlanAccionEventosClimaticos from './views/parametros/PlanAccionEventosClimaticos';
import SeguimientoPlanAccion from './views/parametros/SeguimientoPlanAccion';
import ActividadesCompesacion from './views/parametros/ActividadesCompesacion';
import EducacionCompesacion from './views/parametros/EducacionCompesacion';
import Cuestionario from './views/parametros/CuestionarioLegal'; 
import PrevencionEnfermedades from './views/parametros/PrevencionEnfermedades';
import LimpiezaDesinfeccion from './views/parametros/LimpiezaDesinfeccion';
import EducacionReduccion from './views/parametros/EducacionAccionAmbiental';
import IdentificacionAmenazas from './views/parametros/IdentificacionAmenazas.vue';
import ImpactoInstalacion from './views/parametros/ImpactoInstalacion.vue';
import IdentificarFuentesAgua from './views/parametrosAgropecuario/IdentificarFuentesAguaView.vue'
import ImplementarBuenasPracticas from './views/parametrosAgropecuario/BuenasPractivasView.vue'
import CaracterizacionUsoSuelos from './views/parametrosAgropecuario/CaracterizacionSuelosView.vue'
import CaracterizacionQuimicaSuelos from './views/parametrosAgropecuario/CaracterizacionQuimicaSueloView.vue'
import InventInsumoAgropecuario from './views/parametrosAgropecuario/InventInsumoAgropecuarioView.vue'
import PracticasAmbientales from './views/parametrosAgropecuario/PracticasAmbientalesView.vue'
import DisposicionResiduos from './views/parametrosAgropecuario/DisposicionResiduosView.vue'
import GestionEmpresaComunidad from './views/parametrosAgropecuario/GestionEmpresaComunidadView.vue'
import ParticipacionColaboradores from './views/parametrosAgropecuario/ParticipacionColaboradoresView.vue'
import BienestarAnimalView from './views/parametrosAgropecuario/BienestarAnimalView.vue'
import ManejoAnimalView from './views/parametrosAgropecuario/ProgramaReproductivoView.vue'
import CalidadFuentesAguaView from'./views/parametrosAgropecuario/CalidadFuentesAguaView.vue'
//configuraciones
import indicadoresDesempenio from "./views/configuracion/IndicadoresDesempenio";
import InscripcionLegal from "./views/parametros/InscripcionLegal"
import AsignacionPuntos from './views/configuracion/AsignacionPuntos'
//catalogos
import Usuarios from "./views/catalogos/Usuarios";
import Organizaciones from "./views/catalogos/Organizaciones";
import SectorProductivo from "./views/catalogos/SectorProductivo";
import TipoInstalacion from "./views/catalogos/TipoInstalacion";
import EstructuraLegal from "./views/catalogos/EstructuraLegal";
import TipoEnfoque from "./views/catalogos/TipoEnfoque";
import Suministros from "./views/catalogos/Suministros";
import Etiquetas from "./views/catalogos/Etiquetas";
import CadenaSuministros from "./views/catalogos/CadenaSuministros";
import Productos from "./views/catalogos/Productos";
import PoliticaOperativa from "./views/catalogos/PoliticaOperativa";
import Instalaciones from "./views/catalogos/Instalaciones";
import Eficiencia from "./views/catalogos/Eficiencias";
import Categoria from "./views/catalogos/Categoria";
//Informativos
import Ayuda from './views/catalogos/Ayuda';
import Legislaciones from "./views/catalogos/Legislaciones";
import ManualDeUsuario from './views/informacion/ManualUsuario'
//error pages
import Unathorized from './views/error/Unathorized';
import NotFound from './views/error/NotFound';
//Informe Final
import InformeFinal from './views/informe/GenerarInforme';
//Bitacoras
import Bitacora from './views/bitacora/Bitacora'
//Vuex
import store from './store.js';

Vue.use(Router);

const router = new Router({  
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "home",
      component: Home
    },
    {
      path: "/main",
      name: "main",
      component: MainLayout,
      children: [
        {
          path: "dashboard",
          name: "dashboard",
          component: Dashboard,
          meta: { roles: ['ADMIN', 'CEO', 'GERENTE', 'SUPERVISOR', 'COORDINADOR_AMBIENTAL', 'COMITE_AMBIENTAL'], categoria: 'noname'}
        },
        {
          path: "carbonoNeutral",
          name: "CarbonoNeutral",
          component: CarbonoNeutral,
          meta: { roles: ['ADMIN', 'CEO', 'GERENTE', 'SUPERVISOR', 'COORDINADOR_AMBIENTAL', 'COMITE_AMBIENTAL'], categoria: 'noname'}
        },
        {
          path: "banderaAzul",
          name: "banderaAzul",
          component: BanderaLayout,
          meta: { roles: ['ADMIN', 'CEO', 'GERENTE', 'SUPERVISOR', 'COORDINADOR_AMBIENTAL', 'COMITE_AMBIENTAL'], categoria: 'noname'}
        },
        {
          path: 'banderaAzulDashboard',
          name: 'BanderaAzulDashboard',          
          component: BanderaAzulDashboard,
          meta: { roles: ['ADMIN', 'CEO', 'GERENTE', 'SUPERVISOR', 'COORDINADOR_AMBIENTAL', 'COMITE_AMBIENTAL'], categoria: ''}
        },
        {
          path: 'dashboardIndicadores',
          name: 'DashboardIndicadores',          
          component: ParametrosDashboard,
          meta: { roles: ['ADMIN', 'CEO', 'GERENTE', 'SUPERVISOR', 'COORDINADOR_AMBIENTAL', 'COMITE_AMBIENTAL'], categoria: ''}
        },
        {
          path: 'consumoMensual',
          name: 'consumoMensual',          
          props: true,
          component: ConsumoMensual,
          meta: { roles: ['ADMIN', 'CEO', 'GERENTE', 'SUPERVISOR', 'COORDINADOR_AMBIENTAL', 'COMITE_AMBIENTAL'], categoria: ''}
        },
        {
          path: "usuarios",
          name: "catalogoUsuarios",
          component: Usuarios,
          meta: { roles: ['ADMIN','COORDINADOR_AMBIENTAL'], categoria: 'noname'}
        },
        {
          path: "organizaciones",
          name: "catalogoOrganizaciones",
          component: Organizaciones,
          meta: { roles: ['ADMIN'], categoria: 'noname'}
        },
        {
          path: "productivo",
          name: "catalogoProductivo",
          component: SectorProductivo,
          meta: { roles: ['ADMIN'], categoria: 'noname'}
		    },
		    {
          path: "tipoInstalacion",
          name: "tipoInstalacion",
          component: TipoInstalacion,
          meta: { roles: ['ADMIN'], categoria: 'noname'}
        },
        {

          path: "estructuraLegal",
          name: "estructuraLegal",
          component: EstructuraLegal,
          meta: { roles: ['ADMIN'], categoria: 'noname'}
        },
        {
          path: "TipoEnfoque",
          name: "TipoEnfoque",
          component: TipoEnfoque,
          meta: { roles: ['ADMIN'], categoria: 'noname'}
	      },
	      {
	        path: "productos",
	        name: "productos",
	        component: Productos,
          meta: { roles: ['ADMIN'], categoria: 'noname'}
        },
        {
          path: "Suministros",
          name: "suministros",
          component: Suministros,
          meta: { roles: ['ADMIN'], categoria: 'noname'}
	      },
	      {
	        path: "Etiquetas",
	        name: "Etiquetas",
	        component: Etiquetas,
          meta: { roles: ['ADMIN'], categoria: 'noname'}
        },
        {
	        path: "CadenaSuministros",
	        name: "CadenaSuministros",
	        component: CadenaSuministros,
          meta: { roles: ['ADMIN'], categoria: 'noname'}
        },
        {
	        path: "PoliticaOperativa",
	        name: "PoliticaOperativa",
	        component: PoliticaOperativa,
          meta: { roles: ['ADMIN'], categoria: 'noname'}
        },
        {
	        path: "instalaciones",
	        name: "instalaciones",
	        component: Instalaciones,
          meta: { roles: ['ADMIN', 'CEO', 'COORDINADOR_AMBIENTAL'], categoria: 'noname'}
        },
        {
	        path: "educacionAmbiental",
          name: "educacionAmbiental",
          props: true,
	        component: EducacionAmbiental,
          meta: { roles: ['ADMIN', 'CEO', 'GERENTE', 'SUPERVISOR', 'COORDINADOR_AMBIENTAL', 'COMITE_AMBIENTAL'], categoria: ''}
        },
        {
	        path: "accionesReduccion",
	        name: "accionesReduccion",
          props: true,
	        component: AccionesReduccion,
          meta: { roles: ['ADMIN', 'CEO', 'GERENTE', 'SUPERVISOR', 'COORDINADOR_AMBIENTAL', 'COMITE_AMBIENTAL'], categoria: ''}
        },
        {
	        path: "reduccionConsumo",
          name: "reduccionConsumo",
          props: true,
	        component: ReduccionConsumo,
          meta: { roles: ['ADMIN', 'CEO', 'GERENTE', 'SUPERVISOR', 'COORDINADOR_AMBIENTAL', 'COMITE_AMBIENTAL'], categoria: ''}
        },
        {
	        path: "verificarAgua",
	        name: "verificarAgua",
          props: true,
	        component: VerificarAgua,
          meta: { roles: ['ADMIN', 'CEO', 'GERENTE', 'SUPERVISOR', 'COORDINADOR_AMBIENTAL', 'COMITE_AMBIENTAL'], categoria: ''}
        },
        {
          path: "ayuda",
          name: "ayuda",
          component: Ayuda,
          meta: { roles: ['ADMIN', 'CEO', 'GERENTE', 'SUPERVISOR', 'COORDINADOR_AMBIENTAL', 'COMITE_AMBIENTAL'], categoria: 'noname'}
        },
        {
            path: "legislaciones",
            name: "legislaciones",
            component: Legislaciones,
            meta: { roles: ['ADMIN', 'CEO', 'GERENTE', 'SUPERVISOR', 'COORDINADOR_AMBIENTAL', 'COMITE_AMBIENTAL'], categoria: 'noname'}
        },
        {
          path: "tratamientoAguaRes",
          name: "tratamientoAguaRes",
          props: true,
          component: TratamientoAguaResidual,
          meta: { roles: ['ADMIN', 'CEO', 'GERENTE', 'SUPERVISOR', 'COORDINADOR_AMBIENTAL', 'COMITE_AMBIENTAL'], categoria: ''}
        },
		{
          path: "inventarioConsumidores",
          name: "inventarioConsumidores",
          props: true,
          component: InventarioConsumidores,
          meta: { roles: ['ADMIN', 'CEO', 'GERENTE', 'SUPERVISOR', 'COORDINADOR_AMBIENTAL', 'COMITE_AMBIENTAL'], categoria: ''}
        },
        {
          path:"accionPrimerSegundo",
          name:"accionPrimerSegundo",
          props: true,
          component: AccionPrimerSegundo,
          meta: { roles: ['ADMIN', 'CEO', 'GERENTE', 'SUPERVISOR', 'COORDINADOR_AMBIENTAL', 'COMITE_AMBIENTAL'], categoria: ''}
        },
        {
          path:"sistemaTratamiento",
          name:"sistemaTratamiento",
          props: true,
          component: SistemaTratamiento,
          meta: { roles: ['ADMIN', 'CEO', 'GERENTE', 'SUPERVISOR', 'COORDINADOR_AMBIENTAL', 'COMITE_AMBIENTAL'], categoria: ''}
        },
        {
          path:"vertidoSustancias",
          name:"vertidoSustancias",
          props: true,
          component: VertidoSustancias,
          meta: { roles: ['ADMIN', 'CEO', 'GERENTE', 'SUPERVISOR', 'COORDINADOR_AMBIENTAL', 'COMITE_AMBIENTAL'], categoria: ''}
		    },
        {
          path:"eficiencia",
          name:"eficiencia",
          component: Eficiencia,
          meta: { roles: ['ADMIN'], categoria: 'noname'}
        },
        {
	        path: "indicadoresDesempenio",
	        name: "indicadoresDesempenio",
	        component: indicadoresDesempenio,
	        meta: {roles: ['CEO', 'COORDINADOR_AMBIENTAL'], categoria: 'noname'}
		    },
        {
          path:"inscripcionLegal",
          name:"inscripcionLegal",
          props: true,
          component: InscripcionLegal,
          meta: { roles: ['ADMIN', 'CEO', 'COORDINADOR_AMBIENTAL'], categoria: 'noname'}
        },
        {
          path:"contaminantesAtmosfericos",
          name:"contaminantesAtmosfericos",
          props: true,
          component: ContaminantesAtmosfericos,
          meta: { roles: ['ADMIN', 'CEO', 'GERENTE', 'SUPERVISOR', 'COORDINADOR_AMBIENTAL', 'COMITE_AMBIENTAL'], categoria: ''}  
        },
        {
          path:"consumoContaminante",
          name:"consumoContaminante",
          props: true,
          component: ConsumoContaAtmosferico,
          meta: { roles: ['ADMIN', 'CEO', 'GERENTE', 'SUPERVISOR', 'COORDINADOR_AMBIENTAL', 'COMITE_AMBIENTAL'], categoria: ''}  
        },
        {
          path:"residuosEspecial",
          name:"residuosEspecial",
          props: true,
          component: ResiduosEspeciales,
          meta: { roles: ['ADMIN', 'CEO', 'GERENTE', 'SUPERVISOR', 'COORDINADOR_AMBIENTAL', 'COMITE_AMBIENTAL'], categoria: ''}
        },
        {
          path:"residuosPeligroso",
          name:"residuosPeligroso",
          props: true,
          component: ResiduosPeligroso,
          meta: { roles: ['ADMIN', 'CEO', 'GERENTE', 'SUPERVISOR', 'COORDINADOR_AMBIENTAL', 'COMITE_AMBIENTAL'], categoria: ''}
        },
        {
          path:"consumoResiduos",
          name:"consumoResiduos",
          props: true,
          component: ConsumoMensualResiduos,
          meta: { roles: ['ADMIN', 'CEO', 'GERENTE', 'SUPERVISOR', 'COORDINADOR_AMBIENTAL', 'COMITE_AMBIENTAL'], categoria: ''}
        },
        {
          path:"reduccionResiduos",
          name:"reduccionResiduos",
          props: true,
          component: ReduccionResiduos,
          meta: { roles: ['ADMIN', 'CEO', 'GERENTE', 'SUPERVISOR', 'COORDINADOR_AMBIENTAL', 'COMITE_AMBIENTAL'], categoria: ''}
        },
        {
          path: "programaComprasSos",
          name: "programaComprasSos",
          props: true,
          component: ProgramaComprasSos,
          meta: { roles: ['ADMIN', 'CEO', 'GERENTE', 'SUPERVISOR', 'COORDINADOR_AMBIENTAL', 'COMITE_AMBIENTAL'], categoria: ''}
        },
        {
          path: "inventarioComprasSos",
          name: "inventarioComprasSos",
          props: true,
          component: InventarioComprasSos,
          meta: { roles: ['ADMIN', 'CEO', 'GERENTE', 'SUPERVISOR', 'COORDINADOR_AMBIENTAL', 'COMITE_AMBIENTAL'], categoria: ''}
        },
        {
          path: "sustitucionProductos",
          name: "sustitucionProductos",
          props: true,
          component: SustitucionProductos,
          meta: { roles: ['ADMIN', 'CEO', 'GERENTE', 'SUPERVISOR', 'COORDINADOR_AMBIENTAL', 'COMITE_AMBIENTAL'], categoria: ''}
        },        
        {
          path: "eventosClimaticos",
          name: "eventosClimaticos",
          props: true,
          component: EventosClimaticos,
          meta: { roles: ['ADMIN', 'CEO', 'GERENTE', 'SUPERVISOR', 'COORDINADOR_AMBIENTAL', 'COMITE_AMBIENTAL'], categoria: ''}
        },
        {
          path: "accionesEventosClimaticos",
          name: "accionesEventosClimaticos",
          props: true,
          component: AccionesEventosClimaticos,
          meta: { roles: ['ADMIN', 'CEO', 'GERENTE', 'SUPERVISOR', 'COORDINADOR_AMBIENTAL', 'COMITE_AMBIENTAL'], categoria: ''}
        },
        {
          path: "planAccionEventosClimaticos",
          name: "planAccionEventosClimaticos",
          props: true,
          component: PlanAccionEventosClimaticos,
          meta: { roles: ['ADMIN', 'CEO', 'GERENTE', 'SUPERVISOR', 'COORDINADOR_AMBIENTAL', 'COMITE_AMBIENTAL'], categoria: ''}
        },
        {
          path: "seguimientoPlanAccion",
          name: "seguimientoPlanAccion",
          props: true,
          component: SeguimientoPlanAccion,
          meta: { roles: ['ADMIN', 'CEO', 'GERENTE', 'SUPERVISOR', 'COORDINADOR_AMBIENTAL', 'COMITE_AMBIENTAL'], categoria: ''}
        },    
        {
	        path: "actividadesCompesacion",
	        name: "actividadesCompesacion",
          props: true,
	        component: ActividadesCompesacion,
          	meta: { roles: ['ADMIN', 'CEO', 'GERENTE', 'SUPERVISOR', 'COORDINADOR_AMBIENTAL', 'COMITE_AMBIENTAL'], categoria: ''}
        },
        {
	        path: "educacionCompesacion",
	        name: "educacionCompesacion",
          props: true,
	        component: EducacionCompesacion,
          	meta: { roles: ['ADMIN', 'CEO', 'GERENTE', 'SUPERVISOR', 'COORDINADOR_AMBIENTAL', 'COMITE_AMBIENTAL'], categoria: ''}
        },
        {
	        path: "informeFinal",
	        name: "informeFinal",
	        component: InformeFinal,
          	meta: { roles: ['ADMIN', 'CEO', 'GERENTE', 'SUPERVISOR', 'COORDINADOR_AMBIENTAL', 'COMITE_AMBIENTAL'], categoria: ''}
        },
        {
	        path: "cuestionarioLegal",
	        name: "cuestionarioLegal",
          props: true,
	        component: Cuestionario,
          	meta: { roles: ['ADMIN', 'CEO', 'GERENTE', 'SUPERVISOR', 'COORDINADOR_AMBIENTAL', 'COMITE_AMBIENTAL'], categoria: ''}
        },
        {
          path: "categorias",
          name: "catalogoCategorias",
          component: Categoria,
          meta: { roles: ['ADMIN'], categoria: 'noname'}
        },
        {
	        path: "evitarEnfermedades",
	        name: "evitarEnfermedades",
          props: true,
	        component: PrevencionEnfermedades,
          	meta: { roles: ['ADMIN', 'CEO', 'GERENTE', 'SUPERVISOR', 'COORDINADOR_AMBIENTAL', 'COMITE_AMBIENTAL'], categoria: ''}
        },
        {
	        path: "limpiezaDesinfeccion",
	        name: "limpiezaDesinfeccion",
          props: true,
	        component: LimpiezaDesinfeccion,
          	meta: { roles: ['ADMIN', 'CEO', 'GERENTE', 'SUPERVISOR', 'COORDINADOR_AMBIENTAL', 'COMITE_AMBIENTAL'], categoria: ''}
        },
        {
	        path: "bitacora",
	        name: "bitacora",
	        component: Bitacora,
          meta: { roles: ['ADMIN', 'CEO', 'GERENTE', 'SUPERVISOR', 'COORDINADOR_AMBIENTAL', 'COMITE_AMBIENTAL'], categoria: 'noname'}
        },
        {
	        path: "asignacionPuntos",
	        name: "asignacionPuntos",
	        component: AsignacionPuntos,
          meta: { roles: ['ADMIN'], categoria: 'noname'}
        },
        {
	        path: "educacionAccionAmbiental",
	        name: "educacionAccionAmbiental",
          props: true,
	        component: EducacionReduccion,
          meta: { roles: ['ADMIN', 'CEO', 'GERENTE', 'SUPERVISOR', 'COORDINADOR_AMBIENTAL', 'COMITE_AMBIENTAL'], categoria: ''}
        },
        {
	        path: "manualDeUsuario",
	        name: "manualDeUsuario",
	        component: ManualDeUsuario,
          meta: { roles: ['ADMIN', 'CEO', 'GERENTE', 'SUPERVISOR', 'COORDINADOR_AMBIENTAL', 'COMITE_AMBIENTAL'], categoria: 'noname'}
        },
        {
	        path: "amenazasClimaticas",
	        name: "amenazasClimaticas",
          props: true,
	        component: IdentificacionAmenazas,
          meta: { roles: ['ADMIN', 'CEO', 'GERENTE', 'SUPERVISOR', 'COORDINADOR_AMBIENTAL', 'COMITE_AMBIENTAL'], categoria: ''}
        },
        {
	        path: "impactoOrganizacion",
	        name: "impactoOrganizacion",
          props: true,
	        component: ImpactoInstalacion,
          meta: { roles: ['ADMIN', 'CEO', 'GERENTE', 'SUPERVISOR', 'COORDINADOR_AMBIENTAL', 'COMITE_AMBIENTAL'], categoria: ''}
        },
        {
	        path: "indentificarFuestesAgua",
	        name: "indentificarFuestesAgua",
          props: true,
	        component: IdentificarFuentesAgua,
          meta: { roles: ['ADMIN', 'CEO', 'GERENTE', 'SUPERVISOR', 'COORDINADOR_AMBIENTAL', 'COMITE_AMBIENTAL'], categoria: ''}
        },
        {
	        path: "implementarBuenasPracticas",
	        name: "implementarBuenasPracticas",
          props: true,
	        component: ImplementarBuenasPracticas,
          meta: { roles: ['ADMIN', 'CEO', 'GERENTE', 'SUPERVISOR', 'COORDINADOR_AMBIENTAL', 'COMITE_AMBIENTAL'], categoria: ''}
        },
        {
	        path: "caracterizacionUsoSuelos",
	        name: "caracterizacionUsoSuelos",
          props: true,
	        component: CaracterizacionUsoSuelos,
          meta: { roles: ['ADMIN', 'CEO', 'GERENTE', 'SUPERVISOR', 'COORDINADOR_AMBIENTAL', 'COMITE_AMBIENTAL'], categoria: ''}
        },
        {
	        path: "caracterizacionQuimicaSuelos",
	        name: "caracterizacionQuimicaSuelos",
          props: true,
	        component: CaracterizacionQuimicaSuelos,
          meta: { roles: ['ADMIN', 'CEO', 'GERENTE', 'SUPERVISOR', 'COORDINADOR_AMBIENTAL', 'COMITE_AMBIENTAL'], categoria: ''}
        },
        {
	        path: "insumosAgropecuarios",
	        name: "insumosAgropecuarios",
          props: true,
	        component: InventInsumoAgropecuario,
          meta: { roles: ['ADMIN', 'CEO', 'GERENTE', 'SUPERVISOR', 'COORDINADOR_AMBIENTAL', 'COMITE_AMBIENTAL'], categoria: ''}
        },
        {
	        path: "practicasAmbientales",
	        name: "practicasAmbientales",
          props: true,
	        component: PracticasAmbientales,
          meta: { roles: ['ADMIN', 'CEO', 'GERENTE', 'SUPERVISOR', 'COORDINADOR_AMBIENTAL', 'COMITE_AMBIENTAL'], categoria: ''}
        },
        {
	        path: "disposicionResiduos",
	        name: "disposicionResiduos",
          props: true,
	        component: DisposicionResiduos,
          meta: { roles: ['ADMIN', 'CEO', 'GERENTE', 'SUPERVISOR', 'COORDINADOR_AMBIENTAL', 'COMITE_AMBIENTAL'], categoria: ''}
        },
        {
	        path: "gestionEmpresaComunidad",
	        name: "gestionEmpresaComunidad",
          props: true,
	        component: GestionEmpresaComunidad,
          meta: { roles: ['ADMIN', 'CEO', 'GERENTE', 'SUPERVISOR', 'COORDINADOR_AMBIENTAL', 'COMITE_AMBIENTAL'], categoria: ''}
        },
        {
	        path: "participacionColaboradores",
	        name: "participacionColaboradores",
          props: true,
	        component: ParticipacionColaboradores,
          meta: { roles: ['ADMIN', 'CEO', 'GERENTE', 'SUPERVISOR', 'COORDINADOR_AMBIENTAL', 'COMITE_AMBIENTAL'], categoria: ''}
        },
        {
	        path: "bienestarAnimal",
	        name: "bienestarAnimal",
          props: true,
	        component: BienestarAnimalView,
          meta: { roles: ['ADMIN', 'CEO', 'GERENTE', 'SUPERVISOR', 'COORDINADOR_AMBIENTAL', 'COMITE_AMBIENTAL'], categoria: ''}
        },
        {
	        path: "programaManejoAnimal",
	        name: "programaManejoAnimal",
          props: true,
	        component: ManejoAnimalView,
          meta: { roles: ['ADMIN', 'CEO', 'GERENTE', 'SUPERVISOR', 'COORDINADOR_AMBIENTAL', 'COMITE_AMBIENTAL'], categoria: ''}
        },
        {
	        path: "calidadFuenteAgua",
	        name: "calidadFuenteAgua",
          props: true,
	        component: CalidadFuentesAguaView,
          meta: { roles: ['ADMIN', 'CEO', 'GERENTE', 'SUPERVISOR', 'COORDINADOR_AMBIENTAL', 'COMITE_AMBIENTAL'], categoria: ''}
        },
        //Estas rutas deben ir siempre al final
         //error pages are public
        {
          path: '/404',
          component: NotFound
        },
        {
          path: '/401',
          component: Unathorized
        },        
        {
          path: '*', 
          redirect: '/404'          
        },
      ]
    }
  ]
});

router.beforeEach((to, from, next) => {
  const categoria = to.meta.categoria;
  const roles = to.meta.roles;
  const loguedUser = store.getters.usuario;
  const token = store.getters.token;

  if(categoria === 'noname'){
    store.commit('changeCategoria', '');
  }

  if (roles) {
    if (!token) {            
      return next({ path: '/' });
    }
    if (roles.length && !roles.includes(loguedUser.rol)) {            
      return next({ path: '/401' });
    }
  } 
  next();
});
export default router;