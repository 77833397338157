<template>
    <v-container fluid class="pr-0 pl-0">
        <logo @cambiaAnio="listarAccionReduccion" v-sticky="{ zIndex: 4, stickyTop: 65}" />
        <navParameters :navParams="navParams"/>
        <v-row>
            <v-col cols="12">
                <tituloPrincipal titulo=" Programa de manejo sanitario, alimenticio y reproductivo de los animales"/>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="6" md="3" align-self="center">
                <Agregar @reload="listarAccionReduccion" />
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="6" md="2">
                <v-text-field
                    prepend-inner-icon="mdi-magnify"
                    label="Buscar"
                    v-model="search"
                ></v-text-field>
            </v-col>
        </v-row>
        <v-card>
            <tituloSimple titulo=" Programa de manejo sanitario, alimenticio y reproductivo de los animales"/>
            <v-divider></v-divider>
            <v-card-text>
                <v-data-table
                    :headers="headers"
                    :items="data"
                    item-key="id"
                    :search="search">
                    <template v-slot:item.action="{ item }">
                        <Modificar 
                            v-if="rolUsuario != 'CEO' && rolUsuario != 'GERENTE' && rolUsuario != 'SUPERVISOR'" 
                            :programa="item.id"
                            @reload="listarAccionReduccion" 
                            v-bind:accion1="item" 
                        />
                        <AgregarEvidencia
                            v-if="rolUsuario != 'CEO' && rolUsuario != 'GERENTE' && rolUsuario != 'SUPERVISOR'"
                            @reload="listarAccionReduccion"
                            :idActividad="item.id"
                            origen="programaManejo"
                            tooltipText="Agregar Evidencias"
                            titulo="Evidencias"
                        ></AgregarEvidencia>
                        <v-tooltip top>
                            <template v-slot:activator="{ on }" v-if="rolUsuario != 'CEO' && rolUsuario != 'GERENTE' && rolUsuario != 'SUPERVISOR'">                                        
                                <v-icon v-on="on" color="#0B3F67" @click="borrarAccion(item.id)">mdi-trash-can-outline</v-icon>
                            </template>
                            Eliminar
                        </v-tooltip>  
                    </template>
                    <template v-slot:item.fechaActividad="{ item }">
                        {{ dateFormat(item.fechaActividad) }}
                    </template>
                    <template v-slot:item.evidencia="{ item }">
                        <ListadosDocs :idActividad="item.id" :idAccion="item.id"  origen="accionReduccion" titulo="Evidencias"></ListadosDocs>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
    </v-container>
</template>
<script>

import Modificar from "@/components/parametros/bienestarAnimal/ModProgramaManejo.vue"
import Agregar from '@/components/parametros/bienestarAnimal/AgrProgramaManejo.vue'
import ListadosDocs from "@/components/documentos/ListadoDocumentosComponent"
import AgregarEvidencia from "@/components/utils/AgregarEvidenciaComponent"
import TituloPrincipal from '@/components/utils/TituloPrincipalComponent'
import NavParameters from '@/components/utils/NavParameterComponent'
import TituloSimple from '@/components/utils/TituloSimpleComponent'
import Logo from '@/components/utils/CompanyComponent'
import VueSticky from 'vue-sticky'
import axios from "@/axios.js"
import moment from "moment"

export default {

    created() {
        axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;
    },  
    computed: {
        sucursal() {
            return this.$store.getters.sucursal;
        },
        indicadorActual() {
            return this.$store.getters.indicadorActual;
        },
        instalacion() {
            return this.$store.getters.sucursal;
        },
        rolUsuario(){
            return this.$store.getters.usuario.rol;
        },
        anioConsulta() {
            return this. $store.getters.anioConsulta;
        },
        tipoActividad() {
            let constantes = this.$store.getters.constantes;
            return constantes.TIPO_ACTIVIDAD.PROGRAMA_REPRODUCTIVO
        },
        usuario(){
            return this.$store.getters.usuario;
        },
    },
    directives: {
        'sticky': VueSticky,
    },
    props: [
        'navParams'
    ],
    components: {
        Agregar,
        Modificar,
        ListadosDocs,
        AgregarEvidencia,
        TituloSimple,
        NavParameters,
        TituloPrincipal,
        Logo
    },
    data: () => ({
        search: null,
        data: [],
        headers: [
            {
                text: "Acciones",
                value: "action",
                align: "center",
            },
            {
                text: "Programa",
                value: "tipoActividad",
                align: "start",
            },
            {
                text: "Descripción Programa",
                value: "descripcion",
                align: "start",
            },
            {
                text: "Edad del animal",
                value: "descripcionOptUno",
                align: "start",
            },
            {
                text: "Etapa Reproductiva",
                value: "descripcionOptDos",
                align: "start",
            },
            {
                text: "Fecha de inplementación",
                value: "fechaActividad",
                align: "center",
            },
            {
                text: "Evidencia",
                value: "evidencia",
                align: "center",
            },
        ]
    }),
    mounted () {
        this.listarAccionReduccion();
    },
    methods: {
        listarAccionReduccion(){
            let idInstalacion = this.sucursal.id;
            let indicador = this.indicadorActual;
            let year = this.anioConsulta;
            axios.get(`/accionReduccion/listarAccionesReduccion/${idInstalacion}/${indicador}`,{ params:{year: year, tipo: this.tipoActividad} }).then(response => {
                this.data = response.data.acciones;
                this.paginaCargada(false);
            }).catch(error => {
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                console.log(error);
                this.paginaCargada(false);
              }
            })
        },
        borrarAccion(id) {
            this.$store.dispatch('confirma', {mensaje: 'Desea eliminar este registro?', show: true})
            .then((confirm) => {
                if (confirm) {
                    this.$store.dispatch('loadingPage', true);
                    this.cargando = true;
                    axios.delete(`/accionReduccion/borrarAccionNuevo/${id}`, {params: {idUsuario: this.usuario.id}}).then(() => {
                        this.$store.dispatch('loadingPage', false);
                        this.$store.dispatch("noti", {
                            mensaje: "Registro eliminado",
                            color: "success"
                        });
                        this.cargando = false;
                        this.listarAccionReduccion()
                    }).catch(error => {
                        this.$store.dispatch('loadingPage', false);
                        this.$store.dispatch("noti", {
                            mensaje: "Ocurrió un error no se eliminó el registro",
                            color: "error"
                        });
                        console.log(error);
                        this.cargando = false;
                    });
                }
            })
        },
        paginaCargada() {
            this.$store.dispatch('loadingPage', false);
        },
        dateFormat(fecha) {
            return fecha ? moment(fecha).format('DD/MM/YYYY') : '';
        },
    },
}
</script>