<template>
    <v-dialog
        v-model="dialog"  
        persistent 
        scrollable
        max-width="550px"
        transition="dialog-transition">
        <template v-slot:activator="{ on:dialog }">
            <v-tooltip top>
                <template v-slot:activator="{ on:tooltip }">
                    <v-icon v-on="{ ...tooltip, ...dialog }" color="#0B3F67">mdi-cloud-upload</v-icon>                    
                </template>
                Cargar Evidencias
            </v-tooltip>
        </template>
        <v-card :loading="loading">
            <v-card-title class="pa-0">
              <ModalTitle titulo="Agregar Evidencias" @cancelar="cancelar"/>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <v-row>
                    <v-col cols="12" class="pt-0 pb-0">
                        <v-file-input 
                            accept=".png, .jpg, .jpeg"
                            show-size 
                            counter 
                            multiple 
                            v-model="archivos" 
                            :label="label"
                            :error-messages="evidErrors"
                            @change="$v.archivos.$touch()"
                            @blur="$v.archivos.$touch()">
                        </v-file-input>
                    </v-col>
                    <v-col cols="12" class="pt-0 pb-0" v-if="origen === 'usoSuelo'">
                        <v-file-input 
                            accept=".png, .jpg, .jpeg"
                            show-size 
                            counter 
                            multiple 
                            v-model="archivosUso" 
                            label="Evidencias Uso Suelo"
                            :error-messages="evidErrors"
                            @change="$v.archivosUso.$touch()"
                            @blur="$v.archivosUso.$touch()">
                        </v-file-input>
                    </v-col>
                    <v-col cols="12" class="pt-0 pb-0" v-if="origen === 'usoSuelo'">
                        <v-file-input 
                            accept=".png, .jpg, .jpeg"
                            show-size 
                            counter 
                            multiple 
                            v-model="archivosTopo" 
                            label="Evidencias Topografia"
                            :error-messages="evidErrors"
                            @change="$v.archivosTopo.$touch()"
                            @blur="$v.archivosTopo.$touch()">
                        </v-file-input>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" :disabled="loading" text @click.native="cancelar">Cancelar</v-btn>
                <v-btn color="#0B3F67" :loading="loading" text @click="agregar">Agregar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>

import ModalTitle from '@/components/utils/ModalTitleComponent'
import axios from "@/axios.js";
//Custom validations
const maxFileSize = (files) => {
    if(files != null && files.length > 0) {
        let maximo = files.length * 1000000;
        let total = 0;
        files.forEach(file => {
            total += file.size
        });        
        return total < maximo;
    }
    return true;
}
export default {
    components: {
      ModalTitle
    },
    props: [
        'id',
        'origen'
    ],
    computed: {
        fileSize() {
            return this.archivos != null ? this.archivos.length : 1;
        },
        fileSizeDos() {
            return this.archivosUso != null ? this.archivosUso.length : 1;
        },
        fileSizeTres() {
            return this.archivosTopo != null ? this.archivosTopo.length : 1;
        },
        evidErrors() {
            const errors = []
            if (!this.$v.archivos.$dirty) return errors            
            !this.$v.archivos.maxFileSize && errors.push('El tamaño total debe ser menor a ' + this.fileSize + 'MB.')
            return errors
        },
        evidUsoErrors() {
            const errors = []
            if (!this.$v.archivosUso.$dirty) return errors            
            !this.$v.archivosUso.maxFileSize && errors.push('El tamaño total debe ser menor a ' + this.fileSizeDos + 'MB.')
            return errors
        },
        evidTopErrors() {
            const errors = []
            if (!this.$v.archivosTopo.$dirty) return errors            
            !this.$v.archivosTopo.maxFileSize && errors.push('El tamaño total debe ser menor a ' + this.fileSizeTres + 'MB.')
            return errors
        },
        indicadorActual() {
            return this.$store.getters.indicadorActual;
        },
        sucursal() {
            return this.$store.getters.sucursal;
        },
        usuario(){
            return this.$store.getters.usuario;
        },
        anioConsulta() {
            return this. $store.getters.anioConsulta;
        },
        label() {
            switch (this.origen) {
                case 'usoSuelo':
                    return 'Evidencias Croquis';
                default:
                    return 'Evidencias'
            }
        },
        url() {
            switch (this.origen) {
                case 'usoSuelo':
                    return 'agregarEvidCaractUsoSuelo';
                default:
                    return 'agregarEvidCaractQuimicas'
            }
        }
    },
    data: () => ({
        loading: false,
        dialog: false,
        archivos:[],
        archivosUso:[],
        archivosTopo:[],
    }),
    validations: {
        archivos: {
            maxFileSize
        },
        archivosUso: {
            maxFileSize
        },
        archivosTopo: {
            maxFileSize
        },
    },
    methods: {
        agregar() {
            this.$v.$touch();
            if(!this.$v.$invalid && (this.archivos.length || this.archivosUso.length || this.archivosTopo.length)) {
                this.loading = true;
                var formData = new FormData();
                this.archivos.forEach(file => {
                    formData.append('archivos', file);
                });
                this.archivosUso.forEach(file => {
                    formData.append('archivosUso', file);
                });
                this.archivosTopo.forEach(file => {
                    formData.append('archivosTopo', file);
                });
                formData.append("caractUsoSuelo", this.id);
                formData.append("caractQuimica", this.id);
                formData.append("idUsuario", this.usuario.id);
                axios.post(`/manejoSuelo/${this.url}`, formData).then(response => {
                    this.$store.dispatch('noti',{mensaje: 'Evidencia agregada', color:'success'});   
                    this.$emit('reload');
                    this.cancelar();
                }).catch(error => {
                  if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                  } else {
                    if (error.request && error.request.status === 415) {
                      this.$store.dispatch('noti', {mensaje: error.response.data, color: 'error'});
                      this.loading = false;
                    } else {
                      this.$store.dispatch('noti', {mensaje: 'Ocurrió un error no se agregó la evidencia', color:'error'});   
                      this.loading = false;  
                    } 
                  }        
                })
            }
        },
        cancelar() {
            this.archivos=[];
            this.archivosUso=[];
            this.archivosTopo=[];
            this.$v.$reset();  
            this.dialog = false;
            this.loading = false;
        }
    },
}
</script>