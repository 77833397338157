<template>
    <v-dialog
        v-model="dialog" 
        persistent
        scrollable
        max-width="750px"
        transition="dialog-transition">
        <template v-slot:activator="{ on }">
            <v-tooltip bottom>
                <template v-slot:activator="{ on: tooltip }">
                <v-icon
                    exact-active-class="indigo"
                    class="ma-2"
                    v-on="{ ...tooltip, ...on }"
                    @click="openModal()"
                >mdi-pencil</v-icon>
                </template>
                <span>Disposici&oacute;n</span>
            </v-tooltip>
        </template>
        <v-card>
            <v-card-title class="pa-0">
              <ModalTitle titulo="Agregar Constancia" @cancelar="limpiar"/>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>                            
                <v-row>
                    <v-col cols="12" md="12" class="pt-3 pb-0">
                        <v-text-field
                            disabled
                            label="Residuo"
                            v-model="residuo"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="12" class="pt-3 pb-0">
                        <v-textarea
                            label="Fase de proceso"
                            v-model="faseProceso"
                            :error-messages="faseErrors"             
                            @change="$v.faseProceso.$touch()"
                            @blur="$v.faseProceso.$touch()"
                            required
                            counter="250"
                            hint="Ingresar fase donde se origino el residuo"
                        ></v-textarea>
                    </v-col>
                    <v-col cols="12" md="12" class="pt-0 pb-0">
                        <v-textarea
                            rows="3"
                            label="Disposición"
                            counter="500"
                            v-model="disposicion"
                            :error-messages="disposicionErrors"             
                            @change="$v.disposicion.$touch()"
                            @blur="$v.disposicion.$touch()"
                            required
                        ></v-textarea>
                    </v-col>
                </v-row>                                                        
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" @click="limpiar()" text>Cancelar</v-btn>
                <v-btn color="#0B3F67" @click="agregarDisposicion()" text>Agregar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import ModalTitle from '@/components/utils/ModalTitleComponent'
import { required } from "vuelidate/lib/validators"
import axios from "@/axios.js";
//Custom validations
const maxFileSize = (files) => {
    if(files != null) {
        let maximo = files.length * 1000000;
        let total = 0;
        files.forEach(file => {
            total += file.size
        });        
        return total < maximo;
    }
    return false;
}

export default {
    components: {
      ModalTitle
    },
    computed: {
        disposicionErrors () {
            const errors = []
            if (!this.$v.disposicion.$dirty) return errors            
            !this.$v.disposicion.required && errors.push('Dato requerido.')
            return errors
        },
        faseErrors () {
            const errors = []
            if (!this.$v.faseProceso.$dirty) return errors            
            !this.$v.faseProceso.required && errors.push('Dato requerido.')
            return errors
        },
        usuario(){
            return this.$store.getters.usuario;
        },
        fileSize() {
            return this.archivos != null ? this.archivos.length : 0;
        },
    },
    props: [
        'idResiduo',
        'anioConsulta',
        'residuo'
    ],
    data: () => ({
        dialog: false,
        archivos: [],
        alert: false,
        disposicion: '',
        faseProceso: ''
    }),
    validations: {        
        disposicion: {    
            required
        },
        faseProceso: {    
            required
        },
    },
    methods: {
        openModal() {
            this.dialog = true
            this.getData()
        },
        getData() {
            this.$store.dispatch('loadingPage', true);
            axios.get(`/indicadorResiduos/getDisposicion/${this.idResiduo}/${this.anioConsulta}`)
            .then(r => {
                console.log(r.data);
                
                this.disposicion = r.data.disposicion
                this.faseProceso = r.data.faseProceso
                this.$store.dispatch('loadingPage', false);  
            }).catch(error => {
                if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
                } else {
                if (error.request && error.request.status === 415) {
                    this.$store.dispatch('noti', {mensaje: error.response.data, color: 'error'});
                    this.$store.dispatch('loadingPage', false);
                } else {
                    this.$store.dispatch('loadingPage', false);
                    this.$store.dispatch('noti', {mensaje: 'Ocurrió un error no se agregó la Disposición', color:'error'});              
                }
                }
            })
        },
        agregarDisposicion() {
            this.$v.$touch();
            if(!this.$v.$invalid) {
                axios.post("/indicadorResiduos/agregarDisposicion", null, { params: {
                    idResiduo: this.idResiduo,
                    anioConsulta: this. anioConsulta,
                    disposicion: this.disposicion,
                    faseProceso: this.faseProceso
                }})
                .then(response => {
                    this.$store.dispatch('loadingPage', false);
                    this.$store.dispatch('noti',{mensaje: 'Disposición agregada', color:'success'});
                    this.$emit('reloadTable');
                    this.limpiar();             
                }).catch(error => {
                  if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                  } else {
                    if (error.request && error.request.status === 415) {
                      this.$store.dispatch('noti', {mensaje: error.response.data, color: 'error'});
                      this.$store.dispatch('loadingPage', false);
                    } else {
                      this.$store.dispatch('loadingPage', false);
                      this.$store.dispatch('noti', {mensaje: 'Ocurrió un error no se agregó la Disposición', color:'error'});              
                    }
                  }
                })
            }
        },
        limpiar() {
            this.$v.$reset();
            this.disposicion = '';
            this.faseProceso = ''
            this.dialog = false;
        },
    },
}
</script>