<template>
    <v-dialog
        v-model="dialog"  
        persistent 
        max-width="500px"
        transition="dialog-transition">
        <template v-slot:activator="{ on:dialog }">
            <v-tooltip top>
                <template v-slot:activator="{ on:tooltip }">
                    <v-icon v-on="{ ...tooltip, ...dialog }" color="#0B3F67">mdi-cloud-upload</v-icon>                    
                </template>
                {{ tooltipText }}
            </v-tooltip>
        </template>
        <v-card :loading="loading">
            <v-card-title class="pa-0">
              <ModalTitle :titulo="titulo" @cancelar="cancelar"/>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-col cols="12" class="pt-0 pb-0">
                <v-file-input 
                    accept=".png, .jpg, .jpeg"
                    show-size 
                    counter  
                    multiple
                    v-model="archivoAct" 
                    label="Premiso de concesión"
                    :error-messages="evidActErrors"
                    @change="$v.archivoAct.$touch()"
                    @blur="$v.archivoAct.$touch()">
                </v-file-input>
              </v-col>
              <v-col cols="12" class="pt-0 pb-0">
                <v-file-input 
                    accept=".png, .jpg, .jpeg"
                    show-size 
                    counter  
                    multiple
                    v-model="archivoPart" 
                    label="Certificación de análisis microbiológico"
                    :error-messages="evidPartErrors"
                    @change="$v.archivoPart.$touch()"
                    @blur="$v.archivoPart.$touch()">
                </v-file-input>
              </v-col>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" :disabled="loading" @click="cancelar" text>Cancelar</v-btn>
                <v-btn color="#0B3F67" :loading="loading" @click="argregarEvidencias" text>Agregar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>

import axios from "@/axios.js";
import ModalTitle from '@/components/utils/ModalTitleComponent'

//Custom validations
const maxFileSize = (files) => {
    if(files != null && files.length) {
        let maximo = files.length * 1000000;
        let total = 0;
        files.forEach(file => {
            total += file.size
        });        
        return total < maximo;
    }
    return true;
}

export default {
    created() {
        axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;
    },  
    components: {
      ModalTitle
    },
    props: [
        'titulo',
        'tooltipText',
        'tituloBtn',
        'request',
        'id'
    ],
    computed: {
        fileSize() {
            return this.archivoAct != null ? this.archivoAct.length : 0;
        },
        fileSizeCert() {
            return this.archivoPart != null ? this.archivoPart.length : 0;
        },
        sucursal() {
            return this.$store.getters.sucursal;
        },
        indicadorActual() {
            return this.$store.getters.indicadorActual;
        },
        usuario(){
            return this.$store.getters.usuario;
        },
        anioConsulta() {
            return this.$store.getters.anioConsulta;
        },
        evidActErrors() {
            const errors = []
            if (!this.$v.archivoAct.$dirty) return errors            
            !this.$v.archivoAct.maxFileSize && errors.push('El tamaño total debe ser menor a ' + this.fileSize + 'MB.')
            return errors
        },
        evidPartErrors() {
            const errors = []
            if (!this.$v.archivoPart.$dirty) return errors            
            !this.$v.archivoPart.maxFileSize && errors.push('El tamaño total debe ser menor a ' + this.fileSizeCert + 'MB.')
            return errors
        },
    },
    data: () => ({
        dialog : false,
        loading: false,
        archivoAct: [],
        archivoPart: [],
    }),
    validations: {
        archivoAct: {
            maxFileSize
        },
        archivoPart: {
            maxFileSize
        }
    },
    methods: {
        argregarEvidencias() {
            this.$v.$touch();
            if(!this.$v.$invalid) {
                let formData = new FormData();
                formData.append('id', this.id);
                formData.append('anio', this.anioConsulta);
                this.archivoAct.forEach(file => {
                    formData.append('archivosConc', file);
                });
                this.archivoPart.forEach(file => {
                    formData.append('archivosCert', file);
                });
                axios.post(this.request, formData).then(()=> {                    
                    this.efectivo('Evidencias agregadas');
                }).catch(error=> {        
                    if (error.request && error.request.status === 401) {
                        this.$store.commit('showSessionDialog', true);
                    } else {  
                        if (error.request && error.request.status === 415) {
                            this.$store.dispatch('noti', {mensaje: error.response.data, color: 'error'});
                            this.loading = false;
                        } else {     
                            this.erroneo(error, 'agregaron las evidencias');
                        }
                    }
                })
            }
        },
        efectivo(str) {
            this.$store.dispatch('noti',{mensaje: str, color:'success'});
            this.$emit('reload');
            this.cancelar();
        },
        erroneo(error, str){
            console.log("Error en la carga de documentos " + error);
            this.$store.dispatch('noti', {mensaje: 'Ocurrió un error no se ' + str, color:'error'}); 
            this.cancelar();
        },
        cancelar() {
            this.archivoAct = [];
            this.archivoPart = [];
            this.$v.$reset();
            this.dialog = false;
            this.loading = false;
        }
    },
}
</script>