<template>
    <v-dialog
        v-model="dialog"  
        persistent 
        scrollable
        max-width="550px"
        transition="dialog-transition">
        <template v-slot:activator="{ on }">
            <v-btn class="white--text" color="#0B3F67" v-on="on">Agregar<v-icon right>mdi-plus-box-outline</v-icon></v-btn>
        </template>
        <v-card :loading="loading">
            <v-card-title class="pa-0">
              <ModalTitle titulo="Agregar Gestión Aguas Residuales" @cancelar="cancelar"/>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="12" class="pt-6 pb-0">
                        <v-textarea
                            rows="3"
                            label="Tipo de tratamiento "
                            counter="200"
                            v-model="sistema.tipo"
                            :error-messages="tipoErrors"             
                            @change="$v.sistema.tipo.$touch()"
                            @blur="$v.sistema.tipo.$touch()"
                            required
                        >
                        </v-textarea>
                    </v-col>
                    <v-col cols="12" md="12" class="pb-0">
                        <v-select
                            :items="aplicaList"
                            v-model="sistema.propia"
                            item-text="label"
                            item-value="id"
                            :error-messages="aplicaErrors"             
                            @change="$v.sistema.propia.$touch()"
                            @blur="$v.sistema.propia.$touch()"
                            required                            
                            label="Aplica Sí/No"
                        ></v-select>
                    </v-col>
                    <v-col cols="12" md="12" class="pt-0 pb-0">
                        <v-textarea
                            rows="3"
                            label="Medidas de operación y mantenimiento"
                            counter="500"
                            v-model="sistema.descripcion"
                            :error-messages="descErrors"             
                            @change="$v.sistema.descripcion.$touch()"
                            @blur="$v.sistema.descripcion.$touch()"
                            required
                        ></v-textarea>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" :disabled="loading" text @click.native="cancelar">Cancelar</v-btn>
                <v-btn color="#0B3F67" :loading="loading" text @click="agregarSistema">Agregar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>

import { mask } from "vue-the-mask"
import ModalTitle from '@/components/utils/ModalTitleComponent'
import { required, maxLength } from "vuelidate/lib/validators"
import axios from "@/axios.js";
//Custom validations
const maxFileSize = (files) => {
    if(files != null) {
        let maximo = files.length * 1000000;
        let total = 0;
        files.forEach(file => {
            total += file.size
        });        
        return total < maximo;
    }
    return false;
}

export default {
    directives: {
        mask,
    },
    components: {
      ModalTitle
    },
    computed: {
        fileSize() {
            return this.archivos != null ? this.archivos.length : 0;
        },
        tipoErrors () {
            const errors = []
            if (!this.$v.sistema.tipo.$dirty) return errors
            !this.$v.sistema.tipo.required && errors.push('Campo requerido')
            !this.$v.sistema.descripcion.maxLength && errors.push('No debe exceder el tamaño permitido.')
            return errors
        },
        descErrors () {
            const errors = []
            if (!this.$v.sistema.descripcion.$dirty) return errors
            !this.$v.sistema.descripcion.required && errors.push('Campo requerido')
            !this.$v.sistema.descripcion.maxLength && errors.push('No debe exceder el tamaño permitido.')
            return errors
        },
        aplicaErrors(){
            const errors = []
            if(!this.$v.sistema.propia.$dirty) return errors
            !this.$v.sistema.propia.required && errors.push('Dato requerido')
            return errors
        },
        indicadorActual() {
            return this.$store.getters.indicadorActual;
        },
        sucursal() {
            return this.$store.getters.sucursal;
        },
        usuario(){
            return this.$store.getters.usuario;
        },
        anioConsulta() {
            return this. $store.getters.anioConsulta;
        }
    },
    data: () => ({
        loading: false,
        dialog: false,
        menu: false,
        mask: '####',
        sistema: {
            tipo: '',
            descripcion: '',
            propia: null
        }, 
        archivos:[],
        aplicaList: [
            {
                id: 0,
                label: 'NO'
            },
            {
                id: 1,
                label: 'SI'
            }
        ]
        
    }),
    validations: {
        sistema: {
            tipo: {
                required,
                maxLength: maxLength(200)
            },
            descripcion: {
                maxLength: maxLength(500),
                required
            },
            propia: {
                required
            }
        },
    },
    methods: {
        agregarSistema() {
            this.$v.$touch();
            if(!this.$v.$invalid) {
                this.loading = true;                
                axios.post("/indicadorResiduos/agregarGestionAguasResiduales", this.sistema, { params: {
                    idInstalacion: this.sucursal.id,
                    anioConsulta: this.anioConsulta
                }}).then(() => {
                    this.$store.dispatch('noti',{mensaje: 'Gestión de Aguas Residuales agregada', color:'success'});   
                    this.$emit('reload');
                    this.cancelar();
                }).catch(error => {
                  if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                  } else {
                    if (error.request && error.request.status === 415) {
                      this.$store.dispatch('noti', {mensaje: error.response.data, color: 'error'});
                      this.loading = false;
                    } else {
                      this.$store.dispatch('noti', {mensaje: 'Ocurrió un error no se agregó la Gestión de Aguas Residuales', color:'error'});   
                      this.loading = false;  
                    } 
                  }        
                })
            }
        },
        cancelar() {
            this.sistema = {};
            this.fechaActividad = null
            this.archivos=[];
            this.otro = "";
            this.$v.$reset();  
            this.dialog = false;
            this.loading = false;
        }
    },
}
</script>