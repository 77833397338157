<template>    
    <v-dialog
        v-model="dialog"
        persistent
        scrollable
        max-width="400px"
        transition="dialog-transition">
        <template v-slot:activator="{ on:dialog }">        
            <v-tooltip top>
                <template v-slot:activator="{ on:tooltip }">                      
                    <v-btn class="white--text" color="#0B3F67" v-on="{ ...tooltip, ...dialog }">{{ tituloBtn }}</v-btn>
                </template>
                {{ tituloBtn }}
            </v-tooltip>  
        </template>
        <v-card :loading="loading">
            <v-card-title class="pa-0">
              <ModalTitle :titulo="tituloBtn" @cancelar="cancelar"/>
            </v-card-title>          
            <v-divider></v-divider>
            <v-card-text >
                <v-text-field         
                    label="Nombre Fuente"
                    :disabled="disabled"
                    v-model="medidor.medidor"
                    hint="Nombre de la fuente"
                    :error-messages="medidorErrors"
                    @change="$v.medidor.medidor.$touch()"
                    @blur="$v.medidor.medidor.$touch()"
                    required               
                ></v-text-field>
                <v-select
                    :items="tiposFuente"
                    v-model="medidor.tipoFuente"
                    item-value="id"
                    item-text="nombre"
                    label="Tipo de Fuente"
                    :error-messages="tipoFuenteErrors"             
                    @change="$v.medidor.tipoFuente.$touch()"
                    @blur="$v.medidor.tipoFuente.$touch()"
                    required
                ></v-select>
                <v-select
                    :items="proveedores"
                    v-model="medidor.proveedor"
                    label="Proveedor del servicio"
                    item-text="label"
                    item-value="id"
                    :error-messages="proveedorErrors"             
                    @change="$v.medidor.proveedor.$touch()"
                    @blur="$v.medidor.proveedor.$touch()"
                    required
                ></v-select>  
                <v-textarea
                    label="Ubicación"
                    v-model="medidor.localizacion"
                    :error-messages="localizacionErrors"
                    @change="$v.medidor.localizacion.$touch()"
                    @blur="$v.medidor.localizacion.$touch()"
                    required
                    no-resize
                    counter="500"
                ></v-textarea>
                <v-select
                    :items="unidadMedida"
                    item-text="nombre"
                    item-value="valor"
                    v-model="medidor.unidadConsumo"
                    label="Unidad de Consumo"
                    :error-messages="unidadErrors"             
                    @change="$v.medidor.unidadConsumo.$touch()"
                    @blur="$v.medidor.unidadConsumo.$touch()"
                    required
                ></v-select>
                <v-checkbox 
                    label="Consumo Humano"                     
                    v-model="medidor.consumoHumano"
                    color="#0B3F67"
                    @click.native="aguaConsumoHumano(medidor.consumoHumano)">                    
                </v-checkbox>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" :disabled="loading" text @click="cancelar">Cancelar</v-btn>
                <v-btn color="#0B3F67" :loading="loading" text @click="agregarMedidor">Agregar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>    
</template>
<script>
import ModalTitle from '@/components/utils/ModalTitleComponent'
import { required } from "vuelidate/lib/validators"
import axios from "@/axios.js"

export default {
    props: [
        'unidadMedida',
        'anioConsulta'
    ],
    components: {
      ModalTitle
    },
    created() {
        axios.defaults.headers.common['Authorization'] = "Bearer " + this.$store.getters.token;
    },     
    computed: {
        indicadorActual() {
            return parseInt(this.$store.getters.indicadorActual);
        },
        usuario() {
            return this.$store.getters.usuario;
        },
        constantes() {
            return this.$store.getters.constantes;
        },
        tituloBtn() {
            return "Agregar Fuente";
        },
        medidorErrors () {
            const errors = []
            if (!this.$v.medidor.medidor.$dirty) return errors            
            !this.$v.medidor.medidor.required && errors.push('El nombre es requerido.')                   
            return errors
        },
        localizacionErrors() {
            const errors = []
            if (!this.$v.medidor.localizacion.$dirty) return errors                         
            !this.$v.medidor.localizacion.required && errors.push('La Ubicación es requerida.')
            return errors
        },
        unidadErrors () {
            const errors = []
            if (!this.$v.medidor.unidadConsumo.$dirty) return errors
            !this.$v.medidor.unidadConsumo.required && errors.push('La unidad de consumo es requerida')
            return errors
        },
        tipoFuenteErrors () {
            const errors = []
            if (!this.$v.medidor.tipoFuente.$dirty) return errors
            !this.$v.medidor.tipoFuente.required && errors.push('Ingrese el Tipo de Fuente.')
            return errors
        },
        proveedorErrors () {
            const errors = []
            if (!this.$v.medidor.proveedor.$dirty) return errors                 
            !this.$v.medidor.proveedor.required && errors.push('Por favor agregue un proveedor.')                 
            return errors
        },
    },
    data: () => ({
        proveedores: [
            {
                id: 'OTRO',
                label: "Otro / No Aplica"
            },
            {
                id: 'AYA',
                label: "Acueductos y Alcantarillados"
            },
            {
                id: 'ESPH',
                label: "Servicios Públicos de Heredia"
            }
        ],
        medidor: {
            medidor: '',            
            unidadConsumo: '',
            consumoHumano: '',
            localizacion: '',
            tipoFuente: null,
            proveedor: null
        },
        tiposFuente: [
            {nombre: 'Superficial', id: 0}, 
            {nombre: 'Subterránea', id: 1}
        ],
        unidadesCosto: [
            {nombre: 'COLONES', valor: 1}, 
            {nombre: 'DOLARES', valor: 2}
        ],
        dialog: false,
        disabled: false,
        loading: false,
    }),
    validations: {
        medidor: {
            medidor: {
                required
            },
            unidadConsumo: {
                required
            },
            localizacion: { 
                required
            },
            tipoFuente: { 
                required
            },
            proveedor: {
                required
            }
        },
    },
    methods: {
        agregarMedidor() {
            this.$v.$touch();
            if(!this.$v.$invalid) {
                this.loading = true;
                var formData = new FormData();
                formData.append("medidor", JSON.stringify(this.medidor));
                formData.append('instalacion', this.$store.getters.sucursal.id);
                formData.append("anioConsulta", this.anioConsulta);
                formData.append("usuario", this.usuario.id);  
                axios.post("/indicadorAgua/agregarFuenteAgua", formData).then(() => {
                    this.$store.dispatch('noti',{
                        mensaje: 'Medidor agregado', 
                        color:'success'
                    });
                    this.cancelar();
                    this.$emit('agregarMedidor');
                }).catch(error => {
                  if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                  } else {
                    console.log("Error al agreagar el medidor " + error);
                    this.cancelar();
                    this.$store.dispatch('noti',{mensaje: 'Ocurrió un error no se agregó el medidor', color:'error'});
                  }
                })
            }
        },
        aguaConsumoHumano(consumoHumano) {
            if(consumoHumano) {
                this.disabled = true;
                this.medidor.proveedor = 'Proveedor Generico';
                this.medidor.nis = 'No Aplica';
            } else {
                this.disabled = false;
                this.medidor.medidor = '';
                this.medidor.proveedor = '';
                this.medidor.nis = '';
            }
        },
        cancelar() {
            this.medidor = {};
            this.$v.medidor.$reset();  
            this.disabled = false;    
            this.dialog = false;
            this.loading = false;
        }
    }
}
</script>